var apiConfigDev = {
  // baseUrl: 'https://cors-anywhere.herokuapp.com/https://api.abdi.net/crossplatform/',
  baseUrl: 'http://localhost:50570/',
  vendorBaseUrl: 'http://localhost:50580/api/v3/'
};

var apiConfigProd = {
  // baseUrl: 'https://cors-anywhere.herokuapp.com/https://api.abdi.net/crossplatform/',
  baseUrl: 'https://api.abdi.net/crossplatform/',
  vendorBaseUrl: 'https://api.abdi.net/GateAccess2/api/v3/'
};

// export default apiConfigDev;
export default apiConfigProd;
