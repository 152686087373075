export const GET_LOGIN_INFO_REQUEST = 'GET_LOGIN_INFO_REQUEST';
export const GET_LOGIN_INFO_SUCCESS = 'GET_LOGIN_INFO_SUCCESS';
export const GET_LOGIN_INFO_FAILURE = 'GET_LOGIN_INFO_FAILURE';

export const PUT_LOGIN_INFO_REQUEST = 'PUT_LOGIN_INFO_REQUEST';
export const PUT_LOGIN_INFO_SUCCESS = 'PUT_LOGIN_INFO_SUCCESS';
export const PUT_LOGIN_INFO_FAILURE = 'PUT_LOGIN_INFO_FAILURE';

export const PUT_PASS_REQUEST = 'PUT_PASS_REQUEST';
export const PUT_PASS_SUCCESS = 'PUT_PASS_SUCCESS';
export const PUT_PASS_FAILURE = 'PUT_PASS_FAILURE';
