import {createAction} from 'redux-actions';
import {get, del, post, put} from '../../api/api';

import {
  GET_ALL_PET_REQUEST,
  GET_ALL_PET_SUCCESS,
  GET_ALL_PET_FAILURE,
  DELETE_PET_REQUEST,
  DELETE_PET_SUCCESS,
  DELETE_PET_FAILURE,
  ADD_PET_REQUEST,
  ADD_PET_SUCCESS,
  ADD_PET_FAILURE,
  EDIT_PET_REQUEST,
  EDIT_PET_SUCCESS,
  EDIT_PET_FAILURE,
} from './ManagePet.action.constant';

const getAllPetRequest = createAction(GET_ALL_PET_REQUEST);
const getAllPetSuccess = createAction(GET_ALL_PET_SUCCESS);
const getAllPetFailure = createAction(GET_ALL_PET_FAILURE);

const deletePetRequest = createAction(DELETE_PET_REQUEST);
const deletePetSuccess = createAction(DELETE_PET_SUCCESS);
const deletePetFailure = createAction(DELETE_PET_FAILURE);

const addPetRequest = createAction(ADD_PET_REQUEST);
const addPetSuccess = createAction(ADD_PET_SUCCESS);
const addPetFailure = createAction(ADD_PET_FAILURE);

const editPetRequest = createAction(EDIT_PET_REQUEST);
const editPetSuccess = createAction(EDIT_PET_SUCCESS);
const editPetFailure = createAction(EDIT_PET_FAILURE);

export function getAllPet() {
  return async dispatch => {
    dispatch(getAllPetRequest());
    const {error, response} = await get(`${'Pet/all'}`, {});
    if (response) {
      if (response) {
        dispatch(getAllPetSuccess(response));
      } else {
        dispatch(getAllPetFailure(response));
      }
    }
    if (error) {
      dispatch(getAllPetFailure(error));
    }
  };
}

export function deletePet(gid) {
  return async dispatch => {
    dispatch(deletePetRequest());
    const {error, response} = await del(`${'Pet/'}${gid}`, {});
    if (response) {
      if (response) {
        dispatch(deletePetSuccess(response));
      } else {
        dispatch(deletePetFailure(response));
      }
    }
    if (error) {
      dispatch(deletePetFailure(error));
    }
  };
}

export function addPetCall(data) {
  return async dispatch => {
    dispatch(addPetRequest());
    const {error, response} = await post(`${'Pet'}`, data);
    if (response) {
      if (response.data.result) {
        dispatch(addPetSuccess(response));
        return {
          success: true,
          data: response.data.result,
        };
      } else {
        dispatch(addPetFailure(response));
        return {
          success: false,
          data: response.data,
        };
      }
    }
    if (error) {
      dispatch(addPetFailure(error));
      return {
        success: false,
        data: error,
      };
    }
  };
}

export function editPetCall(data) {
  return async dispatch => {
    dispatch(editPetRequest());
    const {error, response} = await put(`${'Pet'}`, data);
    if (response) {
      if (response.data.result) {
        dispatch(editPetSuccess(response));
        return {
          success: true,
          data: response.data.result,
        };
      } else {
        dispatch(editPetFailure(response));
        return {
          success: false,
          data: response.data,
        };
      }
    }
    if (error) {
      dispatch(editPetFailure(error));
      return {
        success: false,
        data: error,
      };
    }
  };
}
