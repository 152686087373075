import { connect } from 'react-redux';
// import _get from 'lodash/get';
import AddEditPet from '../../screens/AddEditPet';

import { addPetCall, editPetCall, getAllPet } from './ManagePet.action';

const mapStateToProps = state => ({
  allPets: state.managePet.allPet,
});

export default connect(
  mapStateToProps,
  {
    addPetCall,
    editPetCall,
    getAllPet,
  },
)(AddEditPet);
