import {createAction} from 'redux-actions';
import {get, del, post, put, postBulk} from '../../api/vendorApi';

import {
  GET_ALL_GUEST_REQUEST,
  GET_ALL_GUEST_SUCCESS,
  GET_ALL_GUEST_FAILURE,
  DELETE_GUEST_REQUEST,
  DELETE_GUEST_SUCCESS,
  DELETE_GUEST_FAILURE,
  ADD_GUEST_REQUEST,
  ADD_GUEST_SUCCESS,
  ADD_GUEST_FAILURE,
  EDIT_GUEST_REQUEST,
  EDIT_GUEST_SUCCESS,
  EDIT_GUEST_FAILURE,
  GET_GUEST_REQUEST,
  GET_GUEST_SUCCESS,
  GET_GUEST_FAILURE,
  ADD_BULK_GUEST_REQUEST,
  ADD_BULK_GUEST_SUCCESS,
  ADD_BULK_GUEST_FAILURE,
  // FINALIZE_AGREEMENT_REQUEST,
  // FINALIZE_AGREEMENT_SUCCESS,
  // FINALIZE_AGREEMENT_FAILURE,
} from './ManageGuest.action.constant';

const getAllGuestRequest = createAction(GET_ALL_GUEST_REQUEST);
const getAllGuestSuccess = createAction(GET_ALL_GUEST_SUCCESS);
const getAllGuestFailure = createAction(GET_ALL_GUEST_FAILURE);

const deleteGuestRequest = createAction(DELETE_GUEST_REQUEST);
const deleteGuestSuccess = createAction(DELETE_GUEST_SUCCESS);
const deleteGuestFailure = createAction(DELETE_GUEST_FAILURE);

const addGuestRequest = createAction(ADD_GUEST_REQUEST);
const addGuestSuccess = createAction(ADD_GUEST_SUCCESS);
const addGuestFailure = createAction(ADD_GUEST_FAILURE);

const editGuestRequest = createAction(EDIT_GUEST_REQUEST);
const editGuestSuccess = createAction(EDIT_GUEST_SUCCESS);
const editGuestFailure = createAction(EDIT_GUEST_FAILURE);

const getGuestRequest = createAction(GET_GUEST_REQUEST);
const getGuestSuccess = createAction(GET_GUEST_SUCCESS);
const getGuestFailure = createAction(GET_GUEST_FAILURE);

const addBulkGuestRequest = createAction(ADD_BULK_GUEST_REQUEST);
const addBulkGuestSuccess = createAction(ADD_BULK_GUEST_SUCCESS);
const addBulkGuestFailure = createAction(ADD_BULK_GUEST_FAILURE);
//
// const finalizeAgreementRequest = createAction(FINALIZE_AGREEMENT_REQUEST);
// const finalizeAgreementSuccess = createAction(FINALIZE_AGREEMENT_SUCCESS);
// const finalizeAgreementFailure = createAction(FINALIZE_AGREEMENT_FAILURE);

export function getAllGuest(eid) {
  return async dispatch => {
    dispatch(getAllGuestRequest());
    const {error, response} = await get(`${'FastAccessEventGuest/all/'}${eid}`, {});
    if (response) {
      if (response) {
        dispatch(getAllGuestSuccess(response));
      } else {
        dispatch(getAllGuestFailure(response));
      }
    }
    if (error) {
      dispatch(getAllGuestFailure(error));
    }
  };
}

export function deleteGuest(gid) {
  return async dispatch => {
    dispatch(deleteGuestRequest());
    const {error, response} = await del(`${'FastAccessEventGuest/'}${gid}`, {});
    if (response) {
      if (response) {
        dispatch(deleteGuestSuccess(response));
      } else {
        dispatch(deleteGuestFailure(response));
      }
    }
    if (error) {
      dispatch(deleteGuestFailure(error));
    }
  };
}

export function addGuestCall(data) {
  return async dispatch => {
    dispatch(addGuestRequest());
    const {error, response} = await post(`${'FastAccessEventGuest'}`, data);
    if (response) {
      if (response.data.results) {
        dispatch(addGuestSuccess(response));
        return {
          success: true,
          data: response.data,
        };
      } else {
        dispatch(addGuestFailure(response));
        return {
          success: false,
          data: response.data,
        };
      }
    }
    if (error) {
      dispatch(addGuestFailure(error));
      return {
        success: false,
        data: error,
      };
    }
  };
}

export function editGuestCall(gid, data) {
  return async dispatch => {
    dispatch(editGuestRequest());
    const {error, response} = await put(`${'FastAccessEventGuest/'}${gid}`, data);
    if (response) {
      if (response.data.results) {
        dispatch(editGuestSuccess(response));
        return {
          success: true,
          data: response.data,
        };
      } else {
        dispatch(editGuestFailure(response));
        return {
          success: false,
          data: response.data,
        };
      }
    }
    if (error) {
      dispatch(editGuestFailure(error));
      return {
        success: false,
        data: error,
      };
    }
  };
}

export function getSpecificGuest(gid) {
  return async dispatch => {
    dispatch(getGuestRequest());
    const {error, response} = await get(`${'FastAccessGuest/'}${gid}`, {});
    if (response) {
      if (response.data.result) {
        dispatch(getGuestSuccess(response));
        return {
          success: true,
          data: response.data.result,
        };
      } else {
        dispatch(getGuestFailure(response));
        return {
          success: true,
          data: response.data,
        };
      }
    }
    if (error) {
      dispatch(getGuestFailure(error));
      return {
        success: false,
        data: error,
      };
    }
  };
}

export function addBulkGuestsCall(eid, data) {
  return async dispatch => {
    dispatch(addBulkGuestRequest());
    const {error, response} = await postBulk(`${'FastAccessEventGuest/Guests/'}${eid}`, data);
    if (response) {
      if (response.data.results) {
        dispatch(addBulkGuestSuccess(response));
        return {
          success: true,
          data: response.data,
        };
      } else {
        dispatch(addBulkGuestFailure(response));
        return {
          success: false,
          data: response.data,
        };
      }
    }
    if (error) {
      dispatch(addBulkGuestFailure(error));
      return {
        success: false,
        data: error,
      };
    }
  };
}

// export function finalizeAgreementCall(eid) {
//   return async dispatch => {
//     dispatch(finalizeAgreementRequest());
//     const {error, response} = await put(`${'FastAccessGuest/Agreement/'}${eid}`, {});
//     if (response) {
//       if (response.data.result) {
//         dispatch(finalizeAgreementSuccess(response));
//         return {
//           success: true,
//           data: response.data.result,
//         };
//       } else {
//         dispatch(finalizeAgreementFailure(response));
//         return {
//           success: false,
//           data: response.data,
//         };
//       }
//     }
//     if (error) {
//       dispatch(finalizeAgreementFailure(error));
//       return {
//         success: false,
//         data: error,
//       };
//     }
//   };
// }
