import ip, {chain} from 'icepick';
import {
    VENDOR_LOGIN_REQUEST,
    VENDOR_LOGIN_SUCCESS,
    VENDOR_LOGIN_FAILURE,
    VENDOR_LOGOUT_REQUEST,
    GET_VENDOR_COMMUNITY_CODES_REQUEST,
    GET_VENDOR_COMMUNITY_CODES_SUCCESS,
    GET_VENDOR_COMMUNITY_CODES_FAILURE
} from './VendorLogin.action.constant';

const initialState = ip.freeze({
  vendorLoginStatus: null,
  vendorLoginError: null,
  vendorLoginData: null,
  vendorGlobalData: null,
  getVendorComunityCodesStatus: null,
  getVendorComunityCodesError: null,
  vendorComunityCodes: null,
});

export default function vendorLoginAPI(state = initialState, action) {
  switch (action.type) {
    case VENDOR_LOGOUT_REQUEST: {
      return chain(state)
        .setIn(['vendorLoginError'], null)
        .setIn(['vendorLoginStatus'], null)
        .setIn(['vendorLoginData'], null)
        .setIn(['vendorGlobalData'], null)
        .value();
    }

    case VENDOR_LOGIN_REQUEST: {
      return chain(state)
        .setIn(['vendorLoginError'], null)
        .setIn(['vendorLoginStatus'], 'started')
        .setIn(['vendorLoginData'], null)
        .setIn(['vendorGlobalData'], null)
        .value();
    }

    case VENDOR_LOGIN_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['vendorLoginError'], null)
        .setIn(['vendorLoginStatus'], 'finished')
        .setIn(['vendorLoginData'], data.result)
        .setIn(['vendorGlobalData'], data)
        .value();
    }

    case VENDOR_LOGIN_FAILURE: {
      return chain(state)
        .setIn(['vendorLoginError'], 'error')
        .setIn(['vendorLoginStatus'], 'failled')
        .setIn(['vendorLoginData'], null)
        .setIn(['vendorGlobalData'], null)
        .value();
    }

    case GET_VENDOR_COMMUNITY_CODES_REQUEST: {
      return chain(state)
        .setIn(['getVendorComunityCodesError'], null)
        .setIn(['getVendorComunityCodesStatus'], 'started')
        .setIn(['vendorComunityCodes'], [])
        .value();
    }

    case GET_VENDOR_COMMUNITY_CODES_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['getVendorComunityCodesError'], null)
        .setIn(['getVendorComunityCodesStatus'], 'finished')
        .setIn(['vendorComunityCodes'], data.result)
        .value();
    }

    case GET_VENDOR_COMMUNITY_CODES_FAILURE: {
      return chain(state)
        .setIn(['getVendorComunityCodesError'], 'error')
        .setIn(['getVendorComunityCodesStatus'], 'failled')
        .setIn(['vendorComunityCodes'], [])
        .value();
    }

    default:
      return state;
  }
}
