import ip, {chain} from 'icepick';
import {
    GET_COMMUNITY_INFO_REQUEST,
    GET_COMMUNITY_INFO_SUCCESS,
    GET_COMMUNITY_INFO_FAILURE,
} from './CommunityInfo.action.constant'

const initialState = ip.freeze({
  getCommunityInfoStatus: null,
  getCommunityInfoError: null,
  communityInfo: [],
});

export default function getCommunityInfo(state = initialState, action) {
  switch (action.type) {
    case GET_COMMUNITY_INFO_REQUEST: {
      return chain(state)
        .setIn(['getCommunityInfoError'], null)
        .setIn(['getCommunityInfoStatus'], 'started')
        // .setIn(['recentVisitors'], [])
        .value();
    }

    case GET_COMMUNITY_INFO_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['getCommunityInfoError'], null)
        .setIn(['getCommunityInfoStatus'], 'finished')
        .setIn(['communityInfo'], data)
        .value();
    }

    case GET_COMMUNITY_INFO_FAILURE: {
      return chain(state)
        .setIn(['getCommunityInfoError'], 'error')
        .setIn(['getCommunityInfoStatus'], 'failled')
        // .setIn(['recentVisitors'], [])
        .value();
    }

    default:
      return state;
  }
}
