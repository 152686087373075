export const GET_NOTIFICATION_SETTING_REQUEST = 'GET_NOTIFICATION_SETTING_REQUEST';
export const GET_NOTIFICATION_SETTING_SUCCESS = 'GET_NOTIFICATION_SETTING_SUCCESS';
export const GET_NOTIFICATION_SETTING_FAILURE = 'GET_NOTIFICATION_SETTING_FAILURE';

export const EDIT_NOTIFICATION_SETTING_REQUEST = 'EDIT_NOTIFICATION_SETTING_REQUEST';
export const EDIT_NOTIFICATION_SETTING_SUCCESS = 'EDIT_NOTIFICATION_SETTING_SUCCESS';
export const EDIT_NOTIFICATION_SETTING_FAILURE = 'EDIT_NOTIFICATION_SETTING_FAILURE';

export const DELETE_NOTIFICATION_SETTING_REQUEST = 'DELETE_NOTIFICATION_SETTING_REQUEST';
export const DELETE_NOTIFICATION_SETTING_SUCCESS = 'DELETE_NOTIFICATION_SETTING_SUCCESS';
export const DELETE_NOTIFICATION_SETTING_FAILURE = 'DELETE_NOTIFICATION_SETTING_FAILURE';
