import {createAction} from 'redux-actions';
import {put, post, get} from '../../api/api';

import {
  EDIT_REQUEST,
  EDIT_SUCCESS,
  EDIT_FAILURE,
  ADD_REQUEST,
  ADD_SUCCESS,
  ADD_FAILURE,

  GET_GUEST_REQUEST,
  GET_GUEST_SUCCESS,
  GET_GUEST_FAILURE,

  GET_QR_REQUEST,
  GET_QR_SUCCESS,
  GET_QR_FAILURE,
} from './AddEditGuest.action.constant';

const editRequest = createAction(EDIT_REQUEST);
const editSuccess = createAction(EDIT_SUCCESS);
const editFailure = createAction(EDIT_FAILURE);

const addRequest = createAction(ADD_REQUEST);
const addSuccess = createAction(ADD_SUCCESS);
const addFailure = createAction(ADD_FAILURE);

const getGuestRequest = createAction(GET_GUEST_REQUEST);
const getGuestSuccess = createAction(GET_GUEST_SUCCESS);
const getGuestFailure = createAction(GET_GUEST_FAILURE);

const getQrRequest = createAction(GET_QR_REQUEST);
const getQrSuccess = createAction(GET_QR_SUCCESS);
const getQrFailure = createAction(GET_QR_FAILURE);

export function editGuestCall(data) {
  return async dispatch => {
    dispatch(editRequest());
    const {error, response} = await put(`${'Guest'}`, data);
    if (response) {
      // console.log('res: ',response);
      if (response.data.result) {
        dispatch(editSuccess(response));
        return {
          success: true,
          data: response.data.result,
        };
      } else {
        dispatch(editFailure(response));
        return {
          success: true,
          data: response.data,
        };
      }
    }
    if (error) {
      dispatch(editFailure(error));
      return {
        success: false,
        data: error,
      };
    }
  };
}

export function addGuestCall(data) {
  return async dispatch => {
    dispatch(addRequest());
    const {error, response} = await post(`${'Guest'}`, data);
    if (response) {
      if (response.data.result) {
        dispatch(addSuccess(response));
        return {
          success: true,
          data: response.data.result,
        };
      } else {
        dispatch(addFailure(response));
        return {
          success: true,
          data: response.data,
        };
      }
    }
    if (error) {
      dispatch(addFailure(error));
      return {
        success: false,
        data: error,
      };
    }
  };
}

export function getSpecificGuest(gid) {
  return async dispatch => {
    dispatch(getGuestRequest());
    const {error, response} = await get(`${'Guest/'}${gid}`, {});
    if (response) {
      if (response.data.result) {
        dispatch(getGuestSuccess(response));
        return {
          success: true,
          data: response.data.result,
        };
      } else {
        dispatch(getGuestFailure(response));
        return {
          success: true,
          data: response.data,
        };
      }
    }
    if (error) {
      dispatch(getGuestFailure(error));
      return {
        success: false,
        data: error,
      };
    }
  };
}

export function getGuestQr(gid) {
  return async dispatch => {
    dispatch(getQrRequest());
    const {error, response} = await get(`${'Guest/'}${gid}${'/barcode'}`, {});
    if (response) {
      if (response) {
        dispatch(getQrSuccess(response));
      } else {
        dispatch(getQrFailure(response));
      }
    }
    if (error) {
      dispatch(getQrFailure(error));
    }
    return {error, response};
  };
}

export function sendGuestQr(gid, email) {
  return async dispatch => {
    dispatch(getQrRequest());
    const {error, response} = await get(`${'Guest/'}${gid}${'/barcode?email='}${email}`, {});
    if (response) {
      if (response) {
        dispatch(getQrSuccess(response));
      } else {
        dispatch(getQrFailure(response));
      }
    }
    if (error) {
      dispatch(getQrFailure(error));
    }
    return {error, response};
  };
}
