import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Spinner } from "native-base";

const styles = {
  infoViewStyle: {
    display: 'flex', height: '100vh', alignItems: 'center', justifyContent: 'center'
  },
  loading: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    display:'flex'
  },
};

class SplashScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.callGetCommunityCodeApi();
  }

  callGetCommunityCodeApi = async () => {
    const {getCommunityCodes} = this.props;
    this.setState({loading: true});
    const {response} = await getCommunityCodes();
    this.setState({loading: false});
    if (response) {
      this.navigateAsNeeded();
    } else {
      this.callGetCommunityCodeApi();
    }
  };

  navigateAsNeeded = () => {
    const {loginData, communityCodes, loginPref} = this.props;
    const {rememberMe, autoLogin} = loginPref;
    //console.log('loginData: ',loginData);
    //console.log('loginPref: ',loginPref);
    if (loginData && autoLogin) {
      //navigation.navigate('UserStack');
      this.props.history.push({pathname: '/dashboard'});
    }
    if (rememberMe && !autoLogin) {
      this.props.history.push({pathname: '/login', state:{logout: false}});
      //navigation.navigate('LoginScreen', {logout: false});
    }
    if (!rememberMe && !autoLogin) {
      this.props.history.push({pathname: '/login', state:{logout: false}});
      //navigation.navigate('LoginScreen', {logout: false});
    }
    if (communityCodes && !loginData) {
      //navigation.navigate('Onboarding');
      this.props.history.push({pathname: '/login', state:{logout: false}});
      //navigation.navigate('LoginScreen', {logout: false});
    }
  };

  render() {
    let {classes} = this.props;
    return (
      <div className={classes.infoViewStyle}>
        {this.state.loading && (
            <div className={classes.loading}>
              <Spinner size="lg" />
            </div>
          )}
      </div>
    );
  }
}

SplashScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(SplashScreen);
