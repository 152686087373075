import ip, {chain} from 'icepick';
import {
  GET_DOCUMENTS_REQUEST,
  GET_DOCUMENTS_SUCCESS,
  GET_DOCUMENTS_FAILURE,
  GET_PDF_REQUEST,
  GET_PDF_SUCCESS,
  GET_PDF_FAILURE,
} from './Documents.action.constant';

const initialState = ip.freeze({
  getAllDocumentsStatus: null,
  getAllDocumentsError: null,
  allDocuments: null,
  getPdfStatus: null,
  getPdfError: null,
  getPdf: null,
});

export default function getAllDocuments(state = initialState, action) {
  switch (action.type) {
    case GET_DOCUMENTS_REQUEST: {
      return chain(state)
        .setIn(['getAllDocumentsError'], null)
        .setIn(['getAllDocumentsStatus'], 'started')
        .setIn(['allDocuments'], [])
        .value();
    }

    case GET_DOCUMENTS_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['getAllDocumentsError'], null)
        .setIn(['getAllDocumentsStatus'], 'finished')
        .setIn(['allDocuments'], data.employees)
        .value();
    }

    case GET_DOCUMENTS_FAILURE: {
      return chain(state)
        .setIn(['getAllDocumentsError'], 'error')
        .setIn(['getAllDocumentsStatus'], 'failled')
        .setIn(['allDocuments'], [])
        .value();
    }

    case GET_PDF_REQUEST: {
      return chain(state)
        .setIn(['getPdfError'], null)
        .setIn(['getPdfStatus'], 'started')
        .setIn(['getPdf'], [])
        .value();
    }

    case GET_PDF_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['getPdfError'], null)
        .setIn(['getPdfStatus'], 'finished')
        .setIn(['getPdf'], data.result)
        .value();
    }

    case GET_PDF_FAILURE: {
      return chain(state)
        .setIn(['getPdfError'], 'error')
        .setIn(['getPdfStatus'], 'failled')
        .setIn(['getPdf'], [])
        .value();
    }

    default:
      return state;
  }
}
