export const GET_NOTIFICATION_SETTINGB_REQUEST = 'GET_NOTIFICATION_SETTINGB_REQUEST';
export const GET_NOTIFICATION_SETTINGB_SUCCESS = 'GET_NOTIFICATION_SETTINGB_SUCCESS';
export const GET_NOTIFICATION_SETTINGB_FAILURE = 'GET_NOTIFICATION_SETTINGB_FAILURE';

export const EDIT_NOTIFICATION_SETTINGB_REQUEST = 'EDIT_NOTIFICATION_SETTINGB_REQUEST';
export const EDIT_NOTIFICATION_SETTINGB_SUCCESS = 'EDIT_NOTIFICATION_SETTINGB_SUCCESS';
export const EDIT_NOTIFICATION_SETTINGB_FAILURE = 'EDIT_NOTIFICATION_SETTINGB_FAILURE';

export const DELETE_NOTIFICATION_SETTINGB_REQUEST = 'DELETE_NOTIFICATION_SETTINGB_REQUEST';
export const DELETE_NOTIFICATION_SETTINGB_SUCCESS = 'DELETE_NOTIFICATION_SETTINGB_SUCCESS';
export const DELETE_NOTIFICATION_SETTINGB_FAILURE = 'DELETE_NOTIFICATION_SETTINGB_FAILURE';
