import { connect } from 'react-redux';
// import _get from 'lodash/get';
import MainMenu from '../../screens/MainMenu';
import { getLoginInfo, putLoginInfo, putPassInfo } from './MainMenu.action';
import { logOut } from '../Login/Login.action'

const mapStateToProps = state => ({
  loginInfo: state.loginInfo.loginInfo,
  loginData: state.loginData.loginData,
});

export default connect(
  mapStateToProps,
  {
    logOut,
    getLoginInfo,
    putLoginInfo,
    putPassInfo,
  },
)(MainMenu);
