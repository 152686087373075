export const ALL_TRAVELS = 'ALL_TRAVELS';

export const GET_ALL_TRAVEL_REQUEST = 'GET_ALL_TRAVEL_REQUEST';
export const GET_ALL_TRAVEL_SUCCESS = 'GET_ALL_TRAVEL_SUCCESS';
export const GET_ALL_TRAVEL_FAILURE = 'GET_ALL_TRAVEL_FAILURE';

export const DELETE_TRAVEL_REQUEST = 'DELETE_TRAVEL_REQUEST';
export const DELETE_TRAVEL_SUCCESS = 'DELETE_TRAVEL_SUCCESS';
export const DELETE_TRAVEL_FAILURE = 'DELETE_TRAVEL_FAILURE';

export const ADD_TRAVEL_REQUEST = 'ADD_TRAVEL_REQUEST';
export const ADD_TRAVEL_SUCCESS = 'ADD_TRAVEL_SUCCESS';
export const ADD_TRAVEL_FAILURE = 'ADD_TRAVEL_FAILURE';

export const EDIT_TRAVEL_REQUEST = 'EDIT_TRAVEL_REQUEST';
export const EDIT_TRAVEL_SUCCESS = 'EDIT_TRAVEL_SUCCESS';
export const EDIT_TRAVEL_FAILURE = 'EDIT_TRAVEL_FAILURE';