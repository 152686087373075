import ip, {chain} from 'icepick';
import {
    GET_NOTIFICATION_SETTING_REQUEST,
    GET_NOTIFICATION_SETTING_SUCCESS,
    GET_NOTIFICATION_SETTING_FAILURE,
    EDIT_NOTIFICATION_SETTING_REQUEST,
    EDIT_NOTIFICATION_SETTING_SUCCESS,
    EDIT_NOTIFICATION_SETTING_FAILURE,
  } from './NotificationSettings.action.constant';

const initialState = ip.freeze({
  getNotificationSettingStatus: null,
  getNotificationSettingError: null,
  notificationSetting: null,
  editNotificationSettingStatus: null,
  editNotificationSettingError: null,
  editNotificationSetting: null,
});

export default function getNotificationSetting(state = initialState, action) {
  switch (action.type) {
    case GET_NOTIFICATION_SETTING_REQUEST: {
      return chain(state)
        .setIn(['getNotificationSettingError'], null)
        .setIn(['getNotificationSettingStatus'], 'started')
        .setIn(['notificationSetting'], [])
        .value();
    }

    case GET_NOTIFICATION_SETTING_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['getNotificationSettingError'], null)
        .setIn(['getNotificationSettingStatus'], 'finished')
        .setIn(['notificationSetting'], data)
        .value();
    }

    case GET_NOTIFICATION_SETTING_FAILURE: {
      return chain(state)
        .setIn(['getNotificationSettingError'], 'error')
        .setIn(['getNotificationSettingStatus'], 'failled')
        .setIn(['notificationSetting'], [])
        .value();
    }

    case EDIT_NOTIFICATION_SETTING_REQUEST: {
      return chain(state)
        .setIn(['editNotificationSettingError'], null)
        .setIn(['editNotificationSettingStatus'], 'started')
        .setIn(['editNotificationSetting'], [])
        .value();
    }

    case EDIT_NOTIFICATION_SETTING_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['editNotificationSettingError'], null)
        .setIn(['editNotificationSettingStatus'], 'finished')
        .setIn(['editNotificationSetting'], data)
        .value();
    }

    case EDIT_NOTIFICATION_SETTING_FAILURE: {
      return chain(state)
        .setIn(['editNotificationSettingError'], 'error')
        .setIn(['editNotificationSettingStatus'], 'failled')
        .setIn(['editNotificationSetting'], [])
        .value();
    }

    default:
      return state;
  }
}
