import {createAction} from 'redux-actions';
import {get, put, del} from '../../api/api';

import {
  GET_NOTIFICATION_SETTING_REQUEST,
  GET_NOTIFICATION_SETTING_SUCCESS,
  GET_NOTIFICATION_SETTING_FAILURE,
  EDIT_NOTIFICATION_SETTING_REQUEST,
  EDIT_NOTIFICATION_SETTING_SUCCESS,
  EDIT_NOTIFICATION_SETTING_FAILURE,
  DELETE_NOTIFICATION_SETTING_REQUEST,
  DELETE_NOTIFICATION_SETTING_SUCCESS,
  DELETE_NOTIFICATION_SETTING_FAILURE,
} from './NotificationSettings.action.constant';

const getNotificationSettingRequest = createAction(
  GET_NOTIFICATION_SETTING_REQUEST,
);
const getNotificationSettingSuccess = createAction(
  GET_NOTIFICATION_SETTING_SUCCESS,
);
const getNotificationSettingFailure = createAction(
  GET_NOTIFICATION_SETTING_FAILURE,
);

const editNotificationSettingRequest = createAction(
  EDIT_NOTIFICATION_SETTING_REQUEST,
);
const editNotificationSettingSuccess = createAction(
  EDIT_NOTIFICATION_SETTING_SUCCESS,
);
const editNotificationSettingFailure = createAction(
  EDIT_NOTIFICATION_SETTING_FAILURE,
);

const deleteNotificationSettingRequest = createAction(
  DELETE_NOTIFICATION_SETTING_REQUEST,
);
const deleteNotificationSettingSuccess = createAction(
  DELETE_NOTIFICATION_SETTING_SUCCESS,
);
const deleteNotificationSettingFailure = createAction(
  DELETE_NOTIFICATION_SETTING_FAILURE,
);

export function getNotificationSetting() {
  return async dispatch => {
    dispatch(getNotificationSettingRequest());
    const {error, response} = await get(`${'NotificationSettings'}`, {});
    if (response) {
      if (response) {
        dispatch(getNotificationSettingSuccess(response));
      } else {
        dispatch(getNotificationSettingFailure(response));
      }
    }
    if (error) {
      dispatch(getNotificationSettingFailure(error));
    }
    return {error, response};
  };
}

export function editNotificationSetting(data) {
  return async dispatch => {
    dispatch(editNotificationSettingRequest());
    const {error, response} = await put(`${'NotificationSettings'}`, data);
    if (response) {
      if (response) {
        dispatch(editNotificationSettingSuccess(response));
      } else {
        dispatch(editNotificationSettingFailure(response));
      }
    }
    if (error) {
      dispatch(editNotificationSettingFailure(error));
    }
    return {error, response};
  };
}

export function deleteNotificationSetting(nid) {
  return async dispatch => {
    dispatch(deleteNotificationSettingRequest());
    const {error, response} = await del(`${'NotificationSettings/'}${nid}`, {});
    if (response) {
      if (response) {
        dispatch(deleteNotificationSettingSuccess(response));
      } else {
        dispatch(deleteNotificationSettingFailure(response));
      }
    }
    if (error) {
      dispatch(deleteNotificationSettingFailure(error));
    }
  };
}