import React from 'react';
import TextField from '@material-ui/core/TextField';
import { IconButton as MUIconButton } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { Box, Heading, VStack, FormControl, Input, Button, HStack, Icon, Checkbox, Spinner, Flex, IconButton, Pressable, Modal, FlatList, Text } from "native-base";
import { withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import {materialColor, listMainTextColor, underlayColor, borderBottomColor, switchButtonColor, brandColor, brandColorBelow, borderColor, loginButtonColor, headerColor, deleteColor, titleTextColor} from '../constants/colors';
import {loginTitleFont} from '../constants/font';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import packageJson from '../../package.json';
import {GA3, BG, LOGO} from '../assets/images';

import moment from "moment";

const styles = {
  loading: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    //backgroundColor: '#0000ff47',
    display:'flex'
  },
  option: {
    '&[data-focus="true"]': {
      backgroundColor: materialColor
    }
  },
  dropdownIcon: {
    color: borderColor, // Change this to your desired arrow color
  },
  textField: {
    // '& .MuiInputBase-input': {
    //   borderColor: 'red', // Change this to your desired border color
    //   borderWidth: '2px', // Optional: You can also specify the border width
    //   borderStyle: 'solid', // Optional: Specify the border style if needed
    //   borderRadius: '4px', // Optional: Specify the border radius
    //   padding: '8px', // Optional: Specify the padding
    // },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: borderColor, // Change this to your desired border color
      },
      '&:hover fieldset': {
        borderColor: 'green', // Change this to your desired hover border color
      },
    },
  },
};


class LoginScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      community: '',
      username: '',
      password: '',
      rememberCheck: false,
      autoLoginCheck: false,
      items: [],
      newItems: [],
      communityCode: null,
      uniqueId: '',
      loading: false,
      age: '',
      show: false,
      loginAccounts: [],
    };
  }

  getAccontItems = async () => {
  try {
    const value = await localStorage.getItem('@MultipleAccount');
    if (value !== null) {
      // We have data!!
      console.log("Array Value: ",JSON.parse(value));
      this.setState({loginAccounts: JSON.parse(value)});
    }
  } catch (error) {
    // Error retrieving data
  }
};

// hide show modal
  displayModal = (show) => {
    this.setState({isVisible: show})
  }

OnPressAccount = async(item) => {
  // console.log('click open: ',item);
  let {rememberCheck, autoLoginCheck} = this.state;
  this.setState({
    username:item.username,
    password:item.password,
    communityCode:item.communityCode,
    rememberCheck,
    autoLoginCheck,
    isVisible: false
  });
};

deleteAccount = async(itemToBeRemoved) => {
  // console.log('click delete: ',itemToBeRemoved);
  let savedLoginArray = this.state.loginAccounts;
  savedLoginArray.splice(savedLoginArray.findIndex(a => (a.communityCode === itemToBeRemoved.communityCode) && (a.username === itemToBeRemoved.username)), 1);
  // console.log('array: ',JSON.stringify(savedLoginArray));
  this.setState({loginAccounts: savedLoginArray});
};

  performLogin = async (data) => {
    //console.log('data: ',data);
    const {loginCall} = this.props;
    this.setState({loading: true});
    let loginSuccess = await loginCall(data);
    this.setState({loading: false});
    if (loginSuccess.success) {
      //console.log('success');
      localStorage.setItem('@AbdiStore:loginTime', String(moment(new Date())));
      let savedArray = this.state.loginAccounts;
      if (savedArray.length>0) {
        let thisA = this;
        var __FOUND = savedArray.find(function(post, index) {
	        if(post.communityCode === thisA.state.communityCode)
		        return true;
          else {
            return false;
          }
        });
        if (__FOUND !== undefined) {
          var found = savedArray.find(x => x.username === thisA.state.username);
          if (found !== undefined) {
            savedArray.find(x => x.username === thisA.state.username).password = this.state.password;
          } else {
            let other = {communityCode: this.state.communityCode, username: this.state.username, password: this.state.password};
            savedArray.push(other);
          }
        } else {
          let otherItem = {communityCode: this.state.communityCode, username: this.state.username, password: this.state.password};
          savedArray.push(otherItem);
        }
      } else {
        let firstItem = {communityCode: this.state.communityCode, username: this.state.username, password: this.state.password};
        savedArray.push(firstItem);
      }

      localStorage.setItem('@MultipleAccount',JSON.stringify(savedArray));
      this.props.history.push({pathname: '/dashboard'});
      this.showAlert('Login success!', 'success');
    } else {
      //console.log('error');
      this.showAlert('Error! Please check and try again.', 'error');
    }
  };

  loginPress = async (e) => {
    e.preventDefault();
    var {saveLoginPreference} = this.props;
    const {
      username,
      password,
      communityCode,
      uniqueId,
      rememberCheck,
      autoLoginCheck,
    } = this.state;

    if (username && password && communityCode) {
      // if (username && password) {
      //this.refs.toast.show('Logging in ...');
      //let thisThat = this;
      let data = {
        platformType: 'web',
        communityCode: communityCode,
        // communityCode: 'Demo',
        username: username,
        password: password,
        deviceID: uniqueId,
      };
      saveLoginPreference(rememberCheck, autoLoginCheck, data);
      await this.performLogin(data);
    } else {
      this.showAlert('Please fill the required fields.', 'error');
    }
  };

  checkHistory = () => {
    var {saveLoginPreference} = this.props;
    const {loginPref} = this.props;
    const logOut = this.props.history.location.state?.logout;
    const {rememberMe, autoLogin, lastUserCred} = loginPref;
    //console.log('loginPref: ',loginPref);
    if (rememberMe) {
      const {username, password, communityCode} = lastUserCred;
      this.setState({
        username,
        password,
        communityCode,
        rememberCheck: rememberMe,
        autoLoginCheck: autoLogin,
      });
    }
    if(autoLogin){
      this.setState({
        autoLoginCheck: autoLogin,
      })
    }
    if (logOut) {
      this.setState({
        autoLoginCheck: false,
      })
      saveLoginPreference(rememberMe, false, lastUserCred);
    }
    if(rememberMe && autoLogin && !logOut){
      //console.log("here: "+autoLogin);
      this.performLogin(lastUserCred);
    }
  };

  showAlert = (message, severity) => {
    return(
      <Alert severity={severity}>{message}</Alert>
    )
  }

  componentDidMount() {
    const {communityCodes} = this.props;
    let newArr = [];
    let newItems = [];
    //console.log('bavsgd: ',this.props.history.location.state.logout);
    for (let x in communityCodes) {
      let newItem = {
        label: communityCodes[x].communityCode,
        value: communityCodes[x].communityCode,
        msg: communityCodes[x].customMessage
      };
      newArr.push(newItem);
      newItems.push(communityCodes[x].communityCode);
    }
    this.setState({items: newArr, newItems: newItems});
    this.getAccontItems();
    this.checkHistory();
  }

  handleChange = (e) => {
    //console.log(e.target.name);
    //console.log(e.target.value);
    this.setState(Object.assign(this.state, { [e.target.name]: e.target.value }))
  }

  handleChangePicker = name => event => {
    //console.log(name);
    //console.log(event.target.value);
    this.setState({
      ...this.state,
      [name]: event.target.value,
    });
  };

  Copyright = () => {
    return (
      <Heading _dark={{
          color: "warmGray.200"
      }} color="coolGray.600" fontSize={13} fontWeight='small' size="xs" style={{position: 'absolute', bottom: 10, left: 0, right: 0, textAlign:"center"}}>
        {"© Copyright "}
        {" "}
        {new Date().getFullYear()}
        {" APPLICATIONS BY DESIGN, INC.-All Rights Reserved / v"}
        {packageJson.version}
        {"."}
      </Heading>
    );
  }

  render() {
    const {
      // items,
      newItems,
      //loading,
      rememberCheck,
      // autoLoginCheck,
      communityCode,
      loading,
      // show
    } = this.state;
    const { classes } = this.props;

    return (
      <Flex>

        <Modal isOpen={this.state.isVisible} onClose={() => this.displayModal(!this.state.isVisible)} size={'xl'}>
          <Modal.Content minH='300'>
            <Modal.CloseButton />
            <Modal.Header _text={{color: "#FFFFFF"}} style={{backgroundColor: brandColor}}>Saved Accounts</Modal.Header>
            <Modal.Body>
              <FlatList
                style={{width:"100%", borderColor: borderColor, borderWidth: 1, borderRadius: 5}}
                data={this.state.loginAccounts}
                renderItem={({item}) => (
                  <Pressable
                    style={{borderBottomColor: borderColor, borderBottomWidth: 1, paddingLeft: 5, paddingRight: 5}}
                    onPress={() => {this.OnPressAccount(item)}}
                    underlayColor={underlayColor}>
                    <Box flex="1">
                      <HStack space={3} justifyContent="space-between" mr={5} alignItems="center">

                        <Text
                          style={{
                              color: titleTextColor,
                              fontSize: 16,
                              textTransform: 'capitalize',
                          }}>
                          {item.communityCode}{"/"}{item.username}
                        </Text>

                        <IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'trash']} size="lg" color={deleteColor}/>}/>} onPress={() => {this.deleteAccount(item)}} />

                      </HStack>
                    </Box>
                  </Pressable>
                )}
              />
            </Modal.Body>
          </Modal.Content>
        </Modal>

        <HStack space={2}>
          <VStack h={window.innerHeight} w='60%' bg="white">
            <img
              style={{width: '100%', height: '100%', resizeMode: 'contain', margin: 'auto', alignSelf: 'center'}}
              src={BG}
              alt={'login_right'}
            />
            <img
              style={{width: window.innerWidth * (30 / 100), resizeMode: 'contain', alignSelf: 'center', position:'absolute', bottom: 40}}
              src={LOGO}
              alt={'login'}
            />
          </VStack>

          <Box safeArea p="2" w="40%" h={window.innerHeight} alignItems="center" justifyContent="center">

            {/* <img
              style={{width: window.innerWidth * (30 / 100), resizeMode: 'contain', alignSelf: 'center'}}
              src={GA3}
              alt={'login'}
            /> */}

            <Heading mt="3" _dark={{
              color: "warmGray.200"
            }} color="coolGray.600" fontWeight="medium" size="md" fontFamily={loginTitleFont}>
              Please log in to continue
            </Heading>

            <VStack space={3} mt="5">
              <FormControl>
                <FormControl.Label>
                  Community Code (Tap to search or Scroll list)
                </FormControl.Label>
                <Autocomplete
                  id="combo-box-demo"
                  value={communityCode}
                  onChange={(event, newValue) => {
                    //setValue(newValue);
                    // console.log('newValue: ',newValue);
                    // console.log('event: ',event);
                    if (newValue != null) {
                      this.setState({
                        ...this.state,
                        communityCode: newValue,
                      });
                    }
                  }}
                  options={newItems}
                  // getOptionLabel={(option) => option?.label}
                  style={{ width: window.innerWidth * (25 / 100) }}
                  renderInput={(params) => <TextField {...params} className={classes.textField} label="Community Code" variant="outlined"
                    // InputProps={{
                    //   endAdornment: (
                    //     <MUIconButton
                    //       aria-label="toggle options"
                    //       onClick={() => {console.log(params); params.onClick()}}
                    //     >
                    //       <ArrowDropDownIcon className={classes.dropdownIcon} />
                    //     </MUIconButton>
                    //   ),
                    // }}
                                           />}
                />
              </FormControl>
              <FormControl>
                <FormControl.Label>Username</FormControl.Label>
                <Input
                  // variant="rounded"
                  borderColor={borderColor}
                  size="lg"
                  // style={{ color: (this.state.darkMode) ? '#ffffff' : '#000000' }}
                  onChangeText={username => this.setState({username})}
                  value={this.state.username}
                  autoCapitalize={false}
                  autoCorrect={false}
                  placeholder={'Enter Username'}
                  // placeholderTextColor={(darkMode) ? '##666' : '#C7C7CD'}
                  InputLeftElement={<IconButton isDisabled={true} icon={<Icon as={<FontAwesomeIcon icon={['fas', 'user']} size="lg"/>} />}/>}
                  // InputLeftElement={<Icon as={<MaterialIcons name="person" />} size={5} ml="2" color="muted.400" />}
                  returnKeyType='next'
                  ref={(input) => { this.firstTextInput = input; }}
                  onSubmitEditing={() => { this.secondTextInput.focus(); }}/>
              </FormControl>

              <FormControl>
                <FormControl.Label>Password</FormControl.Label>
                <Input
                  //   w={{
                  //   base: "75%",
                  //   md: "25%"
                  // }}
                  // w="85%"
                  // variant="rounded"
                  borderColor={borderColor}
                  size="lg"
                  // style={{ borderColor: borderColor }}
                  type={this.state.show ? "text" : "password"}
                  InputLeftElement={<IconButton isDisabled={true} icon={<Icon as={<FontAwesomeIcon icon={['fas', "lock"]} size="lg"/>} />} />}
                  InputRightElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={this.state.show ? ['fas', 'eye'] : ['fas', 'eye-slash']} size="lg"/>}
                    size={1} mr="20" color="muted.400" />} onPress={() => {this.setState({show: !this.state.show})}} />}
                  // size={5} mr="2" color="muted.400" onPress={() => {this.setState({show: !this.state.show})}} />}
                  onChangeText={password => this.setState({password})}
                  value={this.state.password}
                  placeholder={'Enter Password'}
                  // placeholderTextColor={(darkMode) ? '##666' : '#C7C7CD'}
                  // secureTextEntry={true}
                  returnKeyType='done'
                  ref={(input) => { this.secondTextInput = input; }}
                  // onSubmitEditing={() => { Keyboard.dismiss() }}
                />
              </FormControl>

              <HStack space={6} justifyContent="space-between">
                <Checkbox shadow={2} value="test" borderColor={borderColor} accessibilityLabel="This is a dummy checkbox" isChecked={rememberCheck} onChange={() => {
                  this.setState({
                    rememberCheck: !rememberCheck,
                  });
                }}>
                  Remember me
                </Checkbox>
                {(this.state.loginAccounts.length>1) && (
                  <Pressable style={{justifyContent: 'center'}} onPress={() => this.displayModal(true)}>
                    <Heading _dark={{
                      color: switchButtonColor
                    }} color= {switchButtonColor} fontWeight="medium" size="sm">
                      Switch Account
                    </Heading>
                  </Pressable>
                )}
              </HStack>

              <VStack space={3} mt="5">
                <Button mt="2" _light={{ bg: loginButtonColor, _text: { color: "white" } }}
                  _dark={{ bg: loginButtonColor, _text: { color: "white" } }}
                  disabled={loading}
                  elevation={5}
                  // style={{borderRadius: 20}}
                  onPress={this.loginPress}>
                  Login
                </Button>
              </VStack>
            </VStack>
            {this.Copyright()}
          </Box>
        </HStack>
        {loading && (
          <div className={classes.loading}>
            <Spinner size="lg" />
          </div>
        )}
      </Flex>
    );
  }
}

LoginScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LoginScreen);
