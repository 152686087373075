import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
// import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
// import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
// import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
// import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
// import FilterListIcon from '@material-ui/icons/FilterList';
import {materialColor} from '../constants/colors';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AppBar from '@material-ui/core/AppBar';
import Slide from '@material-ui/core/Slide';
//import 'date-fns';
//import DateFnsUtils from '@date-io/date-fns';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import MomentUtils from '@date-io/moment';
// import {Link} from "react-router-dom";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
// import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from "moment";


import DataGrid, { Scrolling, Pager, Paging, Column } from 'devextreme-react/data-grid';
// import SelectBox from 'devextreme-react/select-box';
// import CheckBox from 'devextreme-react/check-box';
import './styles.css'
// const displayModes = [{ text: 'Display Mode \'full\'', value: 'full' }, { text: 'Display Mode \'compact\'', value: 'compact' }];
const allowedPageSizes = [5, 10, 'all'];

// function createData(name, calories, fat, carbs, protein) {
//   return { name, calories, fat, carbs, protein };
// }
//
// const rows = [
//   createData('Cupcake', 305, 3.7, 67, 4.3),
//   createData('Donut', 452, 25.0, 51, 4.9),
//   createData('Eclair', 262, 16.0, 24, 6.0),
//   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//   createData('Gingerbread', 356, 16.0, 49, 3.9),
//   createData('Honeycomb', 408, 3.2, 87, 6.5),
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//   createData('Jelly Bean', 375, 0.0, 94, 0.0),
//   createData('KitKat', 518, 26.0, 65, 7.0),
//   createData('Lollipop', 392, 0.2, 98, 0.0),
//   createData('Marshmallow', 318, 0, 81, 2.0),
//   createData('Nougat', 360, 19.0, 9, 37.0),
//   createData('Oreo', 437, 18.0, 63, 4.0),
// ];

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === 'desc'
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function stableSort(array, comparator) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map((el) => el[0]);
// }

const headCells = [
  { id: 'eventName', numeric: false, disablePadding: false, label: 'Event Name' },
  { id: 'startDate', numeric: true, disablePadding: false, label: 'Start Date' },
  { id: 'endDate', numeric: true, disablePadding: false, label: 'End Date' },
  { id: 'locationID', numeric: true, disablePadding: false, label: 'Location' },
  { id: 'venueID', numeric: true, disablePadding: false, label: 'Room/Area' },
  { id: 'registeredGuests', numeric: true, disablePadding: true, label: 'Registered Guests #' },
  { id: "", numeric: true, disablePadding: false, label: 'Actions' },
];

function ManageEventsHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    // console.log('property: ',property);
    // console.log('event: ',event);
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

ManageEventsHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    // overflowX: 'auto',
    // height: 400
  },
  table: {
    minWidth: 650,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  appBar: {
    position: 'relative',
  },
  title: {
    // width: "20%"
  },
  checkbox: {
    marginTop: '5%'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  rootToolbar: {
    width: "100%",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  container: {
    maxHeight: "65vh",
    width: "72vw"
  },
  loading: {
    position: 'absolute',
    left: 420,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    //backgroundColor: '#0000ff47',
    display:'flex'
  },
  margin: {
    // margin: theme.spacing(1),
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class ManageEvents1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order : 'asc',
      orderBy : 'eventName',
      selected : [],
      page : 0,
      dense : false,
      rowsPerPage: 5,
      loading: false,
      allData: [],
      open: false,
      isChecked: false,
      eventName: '',
      rooms: [],
      roomID: null,
      locationID: null,
      codeNumber: null,
      startDate: null,
      endDate: null,
      eventError: false,
      locationError: false,
      roomError: false,
      startDateError: false,
      endDateError: false,
      disabledText: false,
      eventID: null,
      locationName: '',
      roomName: '',
      rName: null,
      isAgreeChecked: false,
      openReview: false,
      agreeText: '',
      message: '',
      messageType: '',
      alertOpen: false,
      delId: null,
      openDeleteDialog: false,
      displayMode: 'full',
      showPageSizeSelector: true,
      showInfo: true,
      showNavButtons: true,
    };
    this.roomsAutoComplete = React.createRef();
  }

  displayModeChange = (value) => {
    this.setState({ ...this.state, displayMode: value });
  }

  showPageSizeSelectorChange = (value) => {
    this.setState({ ...this.state, showPageSizeSelector: value });
  }

  showInfoChange = (value) => {
    this.setState({ ...this.state, showInfo: value });
  }

  showNavButtonsChange = (value) => {
    this.setState({ ...this.state, showNavButtons: value });
  }

  isCompactMode() {
    return this.state.displayMode === 'compact';
  }

  customizeColumns(columns) {
    columns[0].width = 70;
  }

  componentDidMount= async () => {
    this.getAllEvents();
    // console.log('events: ',this.props.allEvent);
    // console.log('eventsEdited: ',events);
    // console.log('loginData: ',this.props.vendorLoginData);
    // this.setState({loading: false});
  }

  getAllEvents = async() => {
    this.setState({loading: true});
    const {getAllEvent} = this.props;
    await getAllEvent();
    // this.setState({allData: this.props.allEvent});
    let events = this.props.allEvent.map((item) => ({
      ...item,
      // startDate: (item.startDate) ? `${moment(item.startDate.split('T')[0]).format("MM-DD-YYYY")}` : '-',
      // endDate: (item.endDate) ? `${moment(item.endDate.split('T')[0]).format("MM-DD-YYYY")}` : '-',
      locationName:this.getLocation(item.locationID),
      roomName:this.getArea(item.venueID),
    }))
    this.setState({allData: events, loading: false});
  }

  getLocation = (locationId) => {
    if (this.props.vendorLoginData === null) {
      return;
    }
    for (const item of this.props.vendorLoginData?.fastAccessLocations) {
      if (item.locationID === locationId) {
        return item.description;
      }
    }
  }

  getArea = (venueId) => {
    if (this.props.vendorLoginData === null) {
      return;
    }
    for (const item of this.props.vendorLoginData.fastAccessRooms) {
      if (item.id === venueId) {
        return item.description;
      }
    }
  }

  goBack = () => {
    this.props.history.goBack();
  };

  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === 'asc';
    this.setState({order: (isAsc ? 'desc' : 'asc'), orderBy : property});
    // this.setState({orderBy : property});
    // console.log('property: ',property);
    // console.log('event: ',event);
  };

  handleSelectAllClick = (event) => {
    // if (event.target.checked) {
    //   const newSelecteds = this.state.allData.map((n) => n.eventName);
    //   this.setState({selected : newSelecteds});
    //   return;
    // }
    this.setState({selected : []});
  };

  handleClick = (event, name) => {
    const {selected} = this.state;
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({selected : newSelected});
  };

  handleChangePage = (event, newPage) => {
    this.setState({page : newPage});
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({rowsPerPage : parseInt(event.target.value, 10), page : 0});
    // this.setState({page : 0});
  };

  handleChangeDense = (event) => {
    this.setState({dense : event.target.checked});
  };

  isSelected = (name) => this.state.selected.indexOf(name) !== -1;

  emptyRows = () => {
    return this.state.rowsPerPage - Math.min(this.state.rowsPerPage, this.state.allData.length - this.state.page * this.state.rowsPerPage);
  }

  handleChange = (e) => {
    // console.log(e.target.name);
    // console.log(e.target.value);
    this.setState(Object.assign(this.state, { [e.target.name]: e.target.value }))
  }

  validate = async() => {
    const {eventName, startDate, endDate, codeNumber, locationID, roomID, isChecked, eventID} = this.state;

    if (locationID == null || locationID === '')
    {
      this.setState({locationError: true});
      return;
    }
    if (roomID == null || roomID === '')
    {
      this.setState({roomError: true});
      return;
    }
    if (eventName == null || eventName.trim() === '')
    {
      this.setState({eventError: true});
      return;
    }
    if (startDate == null || startDate === '')
    {
      this.setState({startDateError: true, eventError: false});
      return;
    }
    if (endDate == null || endDate === '')
    {
      this.setState({endDateError: true, startDateError: false, eventError: false});
      return;
    }

    var currentDateTime = new Date();

    var m = currentDateTime.getMonth();
    var d = currentDateTime.getDate();
    var y = currentDateTime.getFullYear();

    var currentDateString = y + "-" + (m + 1) + "-" + d;
    var currentDateUTC = new Date(currentDateString);

    var startDateUTC = new Date(startDate);

    if(currentDateUTC > startDateUTC){
      this.setState({startDateError: true});
      return;
    }

    var endDateUTC = new Date(endDate);

    if(endDateUTC < startDateUTC){
      this.setState({endDateError: true, startDateError: false});
      return;
    }

    this.setState({loading: true});
    let {addEventCall, editEventCall} = this.props;
    let abcd = "";
    if (eventID != null) {
      let eventData = {"codeNumber": ""+codeNumber, "eventName": ""+eventName.trim(), "startDate": ""+startDate, "endDate": ""+endDate, "venueID": ""+roomID, "fastAccessEventNeedsPassPrinting": ""+isChecked};
      abcd = await editEventCall(eventID, eventData);
      // console.log("abcd: ",abcd);
      if (abcd.success === false) {
        this.setState({loading: false, alertOpen: true, message: ''+abcd.data.data.message, messageType: 'error'});
        return;
      }
      this.handleClose();
      this.getAllEvents();
    } else {
      let eventData = {"codeNumber": ""+codeNumber, "eventName": ""+eventName.trim(), "startDate": ""+startDate, "endDate": ""+endDate, "venueID": ""+roomID, "fastAccessEventNeedsPassPrinting": ""+isChecked};
      abcd = await addEventCall(eventData);
      // console.log("abcd: ",abcd);
      if (!abcd.success) {
        this.setState({loading: false, alertOpen: true, message: ''+abcd.data.data.message, messageType: 'error'});
        return;
      }
      this.handleClose();
      this.getAllEvents();
      // console.log('abcd: ',abcd);
    }
  }

  openDialog = () => {
    this.setState({open: true});
  }

  handleOpen = () => {
    this.openDialog();
  };

  handleClose = () => {
    this.setState({open: false, disabledText: false, isChecked: false, eventID: null, eventName: '', startDate: null, endDate: null, locationID: null, roomID: null, codeNumber: null,
  startDateError: false, endDateError: false, eventError: false, roomError: false, locationError: false});
    // if(this.state.isNew){
    //   this.props.history.goBack();
    // }
  };

  showAlert = (message, severity) => {
    //console.log('showing alert');
    const { classes } = this.props;
    return(
      <div className={classes.fab} style={{position: 'fixed'}}>
        <Snackbar open={this.state.alertOpen} autoHideDuration={3000} onClose={this.handleCloseAlert}>
          <Alert
            variant="filled"
            severity={severity}
          >
            {(message === undefined) ? 'Something went wrong!' : message}
          </Alert>
        </Snackbar>
      </div>
    )
  }

  handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({alertOpen: false});
  };

  handleRooms = async(newValue) => {
    let areas = await this.props.vendorLoginData.fastAccessRooms;
    let newArryAreas = await areas.filter(item => item.parentID === newValue.locationID);
    // console.log('areas: ',newArryAreas);

    this.setState({
      ...this.state,
      rooms: newArryAreas,
      locationID: newValue.locationID,
      codeNumber: newValue.code_Number,
      locationError: false,
      rName: null,
      roomID: null
    });
    // this.roomsAutoComplete.getElementsByClassName('MuiAutocomplete-clearIndicator')[0].click();
  }

  editEvent = async(val) => {
    // let {getSpecificEvent} = this.props;
    // let event = await getSpecificEvent(val.eventID);
    // console.log('event: ', event);
    // console.log('val: ', val);
    this.setState({eventName: val.eventName, eventID: val.eventID, startDate: val.startDate, endDate: val.endDate, locationName: val.locationName, roomName: val.roomName, isChecked: val.fastAccessEventNeedsPassPrinting, open: true, disabledText: true,
    locationID: val.locationID, roomID: val.venueID, codeNumber: val.codeNumber});
  }

  deleteEvent = async() => {
    this.setState({loading: true});
    const {deleteEvent} = this.props;
    await deleteEvent(this.state.delId);
    this.setState({loading: false});
    this.getAllEvents();
    this.closeDeleteDialog();
  }

  handleStartDateChange = (date) => {
    // let sDate = moment(date).format();
    let sDate = moment(date).format('YYYY-MM-DD');
    // console.log('date: ',sDate);
    this.setState({startDate: sDate});
  };

  handleEndDateChange = (date) => {
    let eDate = moment(date).format('YYYY-MM-DD');
    // console.log('date: ',eDate);
    this.setState({endDate: eDate});
  };

  openReviewDialog = async(eventID) => {
    let {getAgreementText} = this.props;
    let res = await getAgreementText(eventID);
    console.log('res: ',res);
    let agreeText = '';
    if(res.success == true){
      agreeText = res.data[0].column1;
      this.setState({openReview: true, eventID: eventID, agreeText: agreeText});
    }
  };

  closeReviewDialog = () => {
    this.setState({openReview: false, eventID: null});
  };

  closeDeleteDialog = () => {
    this.setState({openDeleteDialog: false, delId: null});
  }

  openDeleteDialog = (val) => {
    this.setState({openDeleteDialog: true, delId: val});
  }

  validateReview = async() => {
    this.setState({isAgreeChecked: false});
    let {finalizeAgreementCall} = this.props;
    let resp = await finalizeAgreementCall(this.state.eventID);
    this.setState({isAgreeChecked: true});
    // console.log('resp: ',resp);
    this.closeReviewDialog();
    this.getAllEvents();
  };

  onCellPrepared = (e) => {
        if(e.rowType === "data" && e.column.caption === "Actions") {
          // e.cellElement.css("text-align", "left");
        }
    }

  cellRender = (data) => {
    // console.log("dta: ",data);
    return(
      <div style={{width: 250, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
        <div style={{width: 50, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <IconButton edge="end" aria-label="delete"
            disabled={(data.data.registeredGuests > 0) ? true : false}
            onClick={() => this.openDeleteDialog(data.data.eventID)}
          >
            <DeleteIcon
              style={{fill: (data.data.registeredGuests > 0) ? 'gray' : materialColor, fontSize: 15}}
            />
          </IconButton>
          <IconButton edge="end" aria-label="edit">
            <EditIcon
              style={{fill: materialColor, fontSize: 15}}
              onClick={() => this.editEvent(data.data)}
            />
          </IconButton>

        </div>
        <Button
          size="small"
          // className={classes.margin}
          style={{backgroundColor: materialColor, color: '#fff', marginLeft: 10, fontSize: 10}}
          variant="contained"
          // className={"tab active"}
          // component={Link}
          // to={"/webadmin/events/"+row.eventID+"/guests"}
          onClick={() => this.props.history.push({pathname: "/webadmin/events/"+data.data.eventID+"/guests"})}
        >
          Manage Guests
        </Button>
        {(data.data.fastAccessEventNeedsAgreement) && (
          <Button
            size="small"
            // className={classes.margin}
            style={{backgroundColor: 'orange', color: '#fff', marginLeft: 10, fontSize: 10}}
            variant="contained"
            // className={"tab active"}
            onClick={() => this.openReviewDialog(data.data.eventID)}
          >
            Review
          </Button>
        )}
      </div>
    );
  }

  render(){
    const {classes} = this.props;
    const {loading, allData, open, isChecked, eventName, rooms, eventError, locationError, roomError, startDateError, endDateError, disabledText, eventID, startDate, endDate, locationName, roomName, rName, isAgreeChecked, openReview, agreeText, messageType, message, openDeleteDialog} = this.state;
    return (
      <div className={classes.root}>
        {this.showAlert(message, messageType)}
        <Paper className={classes.paper}>

          <Dialog aria-labelledby="form-dialog-title" disableBackdropClick disableEscapeKeyDown open={open} onClose={this.handleClose} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
              <Toolbar>
                {/* <IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                  <CloseIcon />
                </IconButton> */}
                <Typography variant="h3" className={classes.title}>
                  {(eventID != null) ? "Edit Event" : "New Event"}
                </Typography>
              </Toolbar>
            </AppBar>

            <DialogContent>
              {(eventID != null) && (
                <TextField
                  variant="outlined"
                  margin="normal"
                  // error={eventError}
                  disabled={disabledText}
                  // type="email"
                  // style={{width: 400}}
                  //className={classes.textStyle}
                  required
                  fullWidth
                  id="eventID"
                  label="Event ID"
                  name="eventID"
                  // inputProps={{
                  //   maxlength: 50
                  // }}
                  // helperText={eventError ? "Event Name is required" + `${eventName.length}/${50}` : `${eventName.length}/${50}`}
                  //autoComplete="username"
                  // autoFocus
                  value={eventID}
                  onChange={this.handleChange}
                />
              )}
              <Autocomplete
                id="combo-box-demo"
                style={{marginTop: 20}}
                disabled={disabledText}
                // value={locationName}
                onChange={(event, newValue) => {
                  // console.log('newValue: ',newValue);
                  // console.log('event: ',event);
                  if (newValue == null) {
                    this.setState({
                      ...this.state,
                      rooms: [],
                      locationID: null,
                    });
                    return;
                  }
                  this.handleRooms(newValue);

                }}
                options={this.props.vendorLoginData?.fastAccessLocations}
                getOptionLabel={(option) => option.description}
                renderInput={(params) => <TextField {...params} error={locationError} helperText={locationError ? "Location is required" : ""} label={(eventID != null) ? locationName : "Select a Location"} variant="outlined" />}
              />
              <Autocomplete
                ref={this.roomsAutoComplete}
                id="combo-box-demo1"
                style={{marginTop: 20}}
                disabled={disabledText}
                value={rName}
                onChange={(event, newValue) => {
                  //setValue(newValue);
                  // console.log('newValue: ',newValue);
                  // console.log('event: ',event);
                  // if (newValue != null) {
                  this.setState({
                      ...this.state,
                    roomID: newValue != null ? newValue.id : null,
                    rName: newValue != null ? newValue : null,
                      roomError: false
                  });
                  // }
                }}
                options={rooms}
                getOptionLabel={(option) => option.description}
                renderInput={(params) => <TextField {...params} error={roomError} helperText={roomError ? "Room/Area is required" : ""} label={(eventID != null) ? roomName : "Select a Room/Area"} variant="outlined" />}
              />
              <TextField
                variant="outlined"
                margin="normal"
                error={eventError}
                disabled={disabledText}
                // type="email"
                // style={{width: 400}}
                //className={classes.textStyle}
                required
                fullWidth
                id="eventName"
                label="Event Name"
                name="eventName"
                inputProps={{
                  maxlength: 50
                }}
                helperText={eventError ? "Event Name is required" + `${eventName.length}/${50}` : `${eventName.length}/${50}`}
                //autoComplete="username"
                // autoFocus
                value={eventName}
                onChange={this.handleChange}
              />
              {/* <TextField
                id="startDate"
                label="Start Date"
                name="startDate"
                type="date"
                error={startDateError}
                helperText={startDateError ? "Start Date must be today or after" : ""}
                value={startDate}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={this.handleChange}
                />
                <TextField
                id="endDate"
                label="End Date"
                name="endDate"
                type="date"
                defaultValue={endDate}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={this.handleChange}
              /> */}
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM-DD-YYYY"
                  margin="normal"
                  id="startDate"
                  name="startDate"
                  error={startDateError}
                  label="Start Date"
                  value={startDate}
                  onChange={this.handleStartDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  disabled={disabledText}
                />
                <KeyboardDatePicker
                  disableToolbar
                  style={{marginLeft: 20}}
                  variant="inline"
                  format="MM-DD-YYYY"
                  margin="normal"
                  id="endDate"
                  name="endDate"
                  error={endDateError}
                  label="End Date"
                  value={endDate}
                  onChange={this.handleEndDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  disabled={disabledText}
                />
              </MuiPickersUtilsProvider>
              <div className={classes.checkbox}>
                <FormControlLabel
                  control={
                    <Checkbox
                      //value="remember"
                      disabled={disabledText}
                      color="primary"
                      checked={isChecked}
                      onChange={() => {
                        this.setState({
                          isChecked: !isChecked,
                        });
                      }} />
                  }
                  label="Events Needs Pass Printing"
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                disabled={loading}
                style={{backgroundColor: materialColor, color: '#fff', marginTop: 25}}
                variant="contained"
                className={"tab active"}
                onClick={this.validate}
              >
                Save
              </Button>
              <Button
                style={{backgroundColor: materialColor, color: '#fff', marginTop: 25, marginLeft: 20}}
                variant="contained"
                className={"tab active"}
                onClick={this.handleClose}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog aria-labelledby="form-dialog-title" disableBackdropClick disableEscapeKeyDown open={openReview} onClose={this.closeReviewDialog} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
              <Toolbar>
                <Typography variant="h6" className={classes.title}>
                  Review Charges
                </Typography>
              </Toolbar>
            </AppBar>

            <DialogContent>
              <Typography style={{marginTop: 20}} dangerouslySetInnerHTML={{ __html: `${agreeText}` }}>

              </Typography>

              <div className={classes.checkbox}>
                <FormControlLabel
                  control={
                    <Checkbox
                      //value="remember"
                      // disabled={disabledText}
                      color="primary"
                      checked={isAgreeChecked}
                      onChange={() => {
                        this.setState({
                          isAgreeChecked: !isAgreeChecked,
                        });
                      }} />
                  }
                  label="I Agree"
                />
              </div>
              <Typography color="secondary">
                By selecting "I Agree", you are authorizing a charge to your active balance of this module. Once agreed passes can no longer be modified (other than being cancelled).
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                disabled={!isAgreeChecked}
                style={{backgroundColor: (isAgreeChecked) ? materialColor : '#cccccc', color: '#fff', marginTop: 20, width:'220px'}}
                variant="contained"
                className={"tab active"}
                onClick={this.validateReview}
              >
                Finalize These Guests
              </Button>
              <Button
                style={{backgroundColor: materialColor, color: '#fff', marginTop: 20, marginLeft: 20}}
                variant="contained"
                className={"tab active"}
                onClick={this.closeReviewDialog}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog aria-labelledby="form-dialog-title" disableBackdropClick disableEscapeKeyDown open={openDeleteDialog} onClose={this.closeDeleteDialog} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
              <Toolbar>
                <Typography variant="h6" className={classes.title}>
                  Delete Event
                </Typography>
              </Toolbar>
            </AppBar>

            <DialogContent>
              <Typography color="secondary">
                Do you want to delete this event?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                disabled={loading}
                style={{backgroundColor: materialColor, color: '#fff', marginTop: 20, width:'220px'}}
                variant="contained"
                className={"tab active"}
                onClick={this.deleteEvent}
              >
                Yes
              </Button>
              <Button
                style={{backgroundColor: materialColor, color: '#fff', marginTop: 20, marginLeft: 20}}
                variant="contained"
                className={"tab active"}
                onClick={this.closeDeleteDialog}
              >
                No
              </Button>
            </DialogActions>
          </Dialog>

          <Toolbar className={classes.rootToolbar}>
            <div style={{flexDirection: 'row', width: "100%", display: 'flex', justifyContent: 'space-between'}}>

              <h3>
                Events Coordinator
              </h3>

              <Button
                style={{backgroundColor: materialColor, color: '#fff',}}
                variant="contained"
                className={"tab active"}
                onClick={this.handleOpen}
              >
                New Event
              </Button>
            </div>
          </Toolbar>
          {/* <TableContainer className={classes.container}>
            <Table
              className={classes.table}
              stickyHeader
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
              aria-label="enhanced table"
            >
              <ManageEventsHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={this.handleSelectAllClick}
            onRequestSort={this.handleRequestSort}
            rowCount={allData.length}
              />
              <TableBody>
            {stableSort(allData, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
            console.log("row: ", row);
            // const isItemSelected = this.isSelected(row.eventName);
            // const labelId = `enhanced-table-checkbox-${index}`;

            return (
            <TableRow
            hover
            style={{backgroundColor: (row.fastAccessEventNeedsAgreement) ? 'yellow' : 'white'}}
            // onClick={(event) => handleClick(event, row.name)}
            // role="checkbox"
            // aria-checked={isItemSelected}
            // tabIndex={-1}
            key={row.eventID}
            // selected={isItemSelected}
            >
            <TableCell>
            {row.eventName}
            </TableCell>
            <TableCell align="right">{moment(row.startDate.split('T')[0]).format("MM-DD-YYYY")}</TableCell>
            <TableCell align="right">{moment(row.endDate.split('T')[0]).format("MM-DD-YYYY")}</TableCell>
            <TableCell align="right">{row.locationName}</TableCell>
            <TableCell align="right">{row.roomName}</TableCell>
            <TableCell align="right">{row.registeredGuests}</TableCell>
            <TableCell align="right">
            <div style={{width: 250, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
            <div style={{width: 50, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <IconButton edge="end" aria-label="delete"
            disabled={(row.registeredGuests === 0) ? true : false}
            onClick={() => this.openDeleteDialog(row.eventID)}
            >
            <DeleteIcon
            style={{fill: (row.registeredGuests === 0) ? 'gray' : materialColor, fontSize: 15}}
            />
            </IconButton>
            <IconButton edge="end" aria-label="edit">
            <EditIcon
            style={{fill: materialColor, fontSize: 15}}
            onClick={() => this.editEvent(row)}
            />
            </IconButton>

            </div>
            <Button
            size="small"
            // className={classes.margin}
            style={{backgroundColor: materialColor, color: '#fff', marginLeft: 10, fontSize: 10}}
            variant="contained"
            // className={"tab active"}
            // component={Link}
            // to={"/webadmin/events/"+row.eventID+"/guests"}
            onClick={() => this.props.history.push({pathname: "/webadmin/events/"+row.eventID+"/guests"})}
            >
            Manage Guests
            </Button>
            {(row.fastAccessEventNeedsAgreement) && (
            <Button
            size="small"
            // className={classes.margin}
            style={{backgroundColor: 'orange', color: '#fff', marginLeft: 10, fontSize: 10}}
            variant="contained"
            // className={"tab active"}
            onClick={() => this.openReviewDialog(row.eventID)}
            >
            Review
            </Button>
            )}
            </div>
            </TableCell>
            </TableRow>
            );
            })}
            {this.emptyRows > 0 && (
            <TableRow style={{ height: (dense ? 33 : 53) * this.emptyRows }}>
            <TableCell colSpan={6} />
            </TableRow>
            )}
              </TableBody>
            </Table>
            </TableContainer>
            <TablePagination
            rowsPerPageOptions={[5, 10, { value: -1, label: 'All' }]}
            component="div"
            count={allData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          /> */}
          <DataGrid
            id='gridContainer'
            dataSource={allData}
            keyExpr="eventID"
            showBorders={true}
            allowColumnReordering={true}
            onCellPrepared={this.onCellPrepared}
            // customizeColumns={this.customizeColumns}
          >
            <Scrolling rowRenderingMode='virtual'></Scrolling>
            <Paging defaultPageSize={10} />
            <Pager
              visible={true}
              allowedPageSizes={allowedPageSizes}
              displayMode={this.state.displayMode}
              showPageSizeSelector={this.state.showPageSizeSelector}
              showInfo={this.state.showInfo}
              showNavigationButtons={this.state.showNavButtons} />
            {/* <Column dataField="eventID" width={80}/> */}
            <Column dataField="eventName" defaultSortOrder="asc" />
            <Column dataField="startDate" dataType="date" />
            <Column dataField="endDate" dataType="date" />
            <Column dataField="locationName" />
            <Column dataField="roomName" />
            <Column dataField="registeredGuests" width={140}/>
            <Column dataField="eventId" caption="Actions" allowSorting={false} cellRender={this.cellRender} width={300} />

          </DataGrid>
          {/* <div className='options'>
            <div className='caption'>Options</div>
            <div className='option-container'>
              <div className='option'>
            <SelectBox
            id='displayModes'
            items={displayModes}
            displayExpr='text'
            valueExpr='value'
            value={this.state.displayMode}
            onValueChange={this.displayModeChange} />
              </div>
              <div className='option'>
            <CheckBox
            id='showPageSizes'
            text='Show Page Size Selector'
            value={this.state.showPageSizeSelector}
            onValueChange={this.showPageSizeSelectorChange} />
              </div>
              <div className='option'>
            <CheckBox
            id='showInfo'
            text='Show Info Text'
            value={this.state.showInfo}
            onValueChange={this.showInfoChange}
            disabled={this.isCompactMode()} />
              </div>
              <div className='option'>
            <CheckBox
            id='showNavButtons'
            text='Show Navigation Buttons'
            value={this.state.showNavButtons}
            onValueChange={this.showNavButtonsChange}
            disabled={this.isCompactMode()} />
              </div>
            </div>
          </div> */}
        </Paper>
        {/* <FormControlLabel
          control={<Switch checked={dense} onChange={this.handleChangeDense} />}
          label="Dense padding"
        /> */}
        {loading && (
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        )}
      </div>
    );
  }

}

  ManageEvents1.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  //export default withStyles(useStyles)(ManageEvents1);
  export default compose( withRouter, withStyles(useStyles))(ManageEvents1);
