import {createAction} from 'redux-actions';
import {get, put} from '../../api/api';

import {
  GET_CONTACT_INFO_REQUEST,
  GET_CONTACT_INFO_SUCCESS,
  GET_CONTACT_INFO_FAILURE,
  EDIT_CONTACT_INFO_REQUEST,
  EDIT_CONTACT_INFO_SUCCESS,
  EDIT_CONTACT_INFO_FAILURE,
} from './ContactInfo.action.constant';

const getContactInfoRequest = createAction(GET_CONTACT_INFO_REQUEST);
const getContactInfoSuccess = createAction(GET_CONTACT_INFO_SUCCESS);
const getContactInfoFailure = createAction(GET_CONTACT_INFO_FAILURE);

const editContactInfoRequest = createAction(EDIT_CONTACT_INFO_REQUEST);
const editContactInfoSuccess = createAction(EDIT_CONTACT_INFO_SUCCESS);
const editContactInfoFailure = createAction(EDIT_CONTACT_INFO_FAILURE);

export function getContactInfo() {
  return async dispatch => {
    dispatch(getContactInfoRequest());
    const {error, response} = await get(`${'ContactInfoSettings'}`, {});
    if (response) {
      if (response) {
        dispatch(getContactInfoSuccess(response));
      } else {
        dispatch(getContactInfoFailure(response));
      }
    }
    if (error) {
      dispatch(getContactInfoFailure(error));
    }
    return {error, response}
  };
}

export function editContactInfo(data) {
  return async dispatch => {
    dispatch(editContactInfoRequest());
    const {error, response} = await put(`${'ContactInfoSettings'}`, data);
    if (response) {
      if (response) {
        dispatch(editContactInfoSuccess(response));
      } else {
        dispatch(editContactInfoFailure(response));
      }
    }
    if (error) {
      dispatch(editContactInfoFailure(error));
    }
    return {error, response}
  };
}
