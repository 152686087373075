import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';


const styles = {
  root: {
    // display: 'flex',
    width: '100%',
    marginRight: 10
    //maxWidth: 360,
    //backgroundColor: "#ffffff",
  },
  touchLinks: {
    flexDirection: 'row',
    marginTop: 20,
    marginLeft: '5%',
    alignItems: 'center'
  },
  touchContent: {
    flexDirection: 'column',
    marginLeft: '5%',
  },
  contentHeader: {
    color: '#042C5C',
    fontSize: 16,
  },
  contentSubText: {
    color: '#77869E',
    fontSize: 13,
  },
  submit: {
    //margin: theme.spacing(3, 0, 2),
    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    backgroundColor: "#f5f5f5",
    margin: 30,
    border: 0,
    borderRadius: 3,
    boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, .2)',
    color: 'black',
    height: 40,
    padding: '0 30px',
  },
};

// const iconColor = '#006400';

class CommercialPortalUser extends React.Component {

  state = {

    };

  componentDidMount() {

  }


  logMeOut = async (e) => {
    // console.log('logout',e);
    const { logOut } = this.props;
    await logOut();

    this.props.history.push({pathname: '/login', state:{logout: true}});
  }

  handleListItemClick = (event, index) => {
    this.setState({selectedIndex: index});
  };

  render() {

    const { classes } = this.props;
    // const {location: { pathname, state }} = this.props;
    // console.log('state: ', state);

    return (
      <div className={classes.root}>
        <Typography variant="h3" style={{margin: 10}}>
          Commercial Portal User
        </Typography>
        <Divider />

      </div>
    );
  }
}

CommercialPortalUser.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose( withRouter, withStyles(styles))(CommercialPortalUser);
