import { connect } from 'react-redux';
import _get from 'lodash/get';
import NotificationSettingsB from '../../screens/NotificationSettingsB';

import { getNotificationSettingB, editNotificationSettingB, deleteNotificationSettingB } from './NotificationSettingsB.action';

const mapStateToProps = state => ({
  globalData: state.loginData.globalData,
});

export default connect(
  mapStateToProps,
  {
    getNotificationSettingB,
    editNotificationSettingB,
    deleteNotificationSettingB,
  },
)(NotificationSettingsB);
