import ip, {chain} from 'icepick';
import {
  LOAD_REQUEST,
  LOAD_SUCCESS,
  LOAD_FAILURE,
  FORM_REQUEST,
  FORM_SUCCESS,
  FORM_FAILURE,
} from './CPSubmitDocument.action.constant';

const initialState = ip.freeze({
  loadStatus: null,
  loadError: null,
  loadData: null,
  formStatus: null,
  formError: null,
  formData: null,
});

export default function cpSubmitDocumentAPI(state = initialState, action) {
  switch (action.type) {
    case LOAD_REQUEST: {
      return chain(state)
        .setIn(['loadError'], null)
        .setIn(['loadStatus'], 'started')
        .setIn(['loadData'], [])
        .value();
    }

    case LOAD_SUCCESS: {
      const {data} = action.payload;
      // console.log('data:',data);
      return chain(state)
        .setIn(['loadError'], null)
        .setIn(['loadStatus'], 'finished')
        .setIn(['loadData'], data)
        .value();
    }

    case LOAD_FAILURE: {
      return chain(state)
        .setIn(['loadError'], 'error')
        .setIn(['loadStatus'], 'failled')
        .setIn(['loadData'], [])
        .value();
    }

    case FORM_REQUEST: {
      return chain(state)
        .setIn(['formError'], null)
        .setIn(['formStatus'], 'started')
        .setIn(['formData'], [])
        .value();
    }

    case FORM_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['formError'], null)
        .setIn(['formStatus'], 'finished')
        .setIn(['formData'], data.result)
        .value();
    }

    case FORM_FAILURE: {
      return chain(state)
        .setIn(['formError'], 'error')
        .setIn(['formStatus'], 'failled')
        .setIn(['formData'], [])
        .value();
    }

    default:
      return state;
  }
}
