import { connect } from 'react-redux';
// import _get from 'lodash/get';
import AddEditMultipleGuests from '../../screens/AddEditMultipleGuests';

import { addGuestCall } from './AddEditMultipleGuests.action'
//import { getAllGuest } from '../ManageGuestList/ManageGuestList.action';

const mapStateToProps = state => ({
  loginData: state.loginData.loginData,
});

export default connect(
  mapStateToProps,
  {
    addGuestCall,
  },
)(AddEditMultipleGuests);
