import { connect } from 'react-redux';
// import _get from 'lodash/get';
import TravelInfo from '../../screens/TravelInfo';

import { getAllTravel, deleteTravel } from './TravelInfo.action';

const mapStateToProps = state => ({
  travelData: state.travelInfo.allTravel,
  loginData: state.loginData.loginData,
});

export default connect(
  mapStateToProps,
  {
    getAllTravel,
    deleteTravel,
  },
)(TravelInfo);
