import { connect } from 'react-redux';
// import _get from 'lodash/get';
import ManageGuestList from '../../screens/ManageGuestList';

import { getAllGuest, deleteGuest } from './ManageGuestList.action';

const mapStateToProps = state => ({
  loginData: state.loginData.loginData,
  allGuest: state.manageGuestList.allGuest,
  getAllGuestStatus: state.manageGuestList.getAllGuestStatus,
  getAllGuestError: state.manageGuestList.getAllGuestError,
});

export default connect(
  mapStateToProps,
  {
    getAllGuest,
    deleteGuest,
  },
)(ManageGuestList);
