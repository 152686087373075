import ip, {chain} from 'icepick';
import {
    GET_CONTACT_INFO_REQUEST,
    GET_CONTACT_INFO_SUCCESS,
    GET_CONTACT_INFO_FAILURE,
    EDIT_CONTACT_INFO_REQUEST,
    EDIT_CONTACT_INFO_SUCCESS,
    EDIT_CONTACT_INFO_FAILURE,
  } from './ContactInfo.action.constant';

const initialState = ip.freeze({
  getContactInfoStatus: null,
  getContactInfoError: null,
  contactInfo: null,
  editContactInfoStatus: null,
  editContactInfoError: null,
  editContactInfo: null,
});

export default function getContactInfo(state = initialState, action) {
  switch (action.type) {
    case GET_CONTACT_INFO_REQUEST: {
      return chain(state)
        .setIn(['getContactInfoError'], null)
        .setIn(['getContactInfoStatus'], 'started')
        .setIn(['contactInfo'], [])
        .value();
    }

    case GET_CONTACT_INFO_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['getContactInfoError'], null)
        .setIn(['getContactInfoStatus'], 'finished')
        .setIn(['contactInfo'], data.contactSettings[0])
        .value();
    }

    case GET_CONTACT_INFO_FAILURE: {
      return chain(state)
        .setIn(['getContactInfoError'], 'error')
        .setIn(['getContactInfoStatus'], 'failled')
        .setIn(['contactInfo'], [])
        .value();
    }

    case EDIT_CONTACT_INFO_REQUEST: {
      return chain(state)
        .setIn(['editContactInfoError'], null)
        .setIn(['editContactInfoStatus'], 'started')
        .setIn(['editContactInfo'], [])
        .value();
    }

    case EDIT_CONTACT_INFO_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['editContactInfoError'], null)
        .setIn(['editContactInfoStatus'], 'finished')
        .setIn(['editContactInfo'], data)
        .value();
    }

    case EDIT_CONTACT_INFO_FAILURE: {
      return chain(state)
        .setIn(['editContactInfoError'], 'error')
        .setIn(['editContactInfoStatus'], 'failled')
        .setIn(['editContactInfo'], [])
        .value();
    }

    default:
      return state;
  }
}
