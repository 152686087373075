import {createAction} from 'redux-actions';
import {get, del, post, put} from '../../api/vendorApi';

import {
  GET_ALL_EVENT_REQUEST,
  GET_ALL_EVENT_SUCCESS,
  GET_ALL_EVENT_FAILURE,
  DELETE_EVENT_REQUEST,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_FAILURE,
  ADD_EVENT_REQUEST,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_FAILURE,
  EDIT_EVENT_REQUEST,
  EDIT_EVENT_SUCCESS,
  EDIT_EVENT_FAILURE,
  GET_EVENT_REQUEST,
  GET_EVENT_SUCCESS,
  GET_EVENT_FAILURE,
  GET_AGREEMENT_REQUEST,
  GET_AGREEMENT_SUCCESS,
  GET_AGREEMENT_FAILURE,
  FINALIZE_AGREEMENT_REQUEST,
  FINALIZE_AGREEMENT_SUCCESS,
  FINALIZE_AGREEMENT_FAILURE,
} from './ManageEvents.action.constant';

const getAllEventRequest = createAction(GET_ALL_EVENT_REQUEST);
const getAllEventSuccess = createAction(GET_ALL_EVENT_SUCCESS);
const getAllEventFailure = createAction(GET_ALL_EVENT_FAILURE);

const deleteEventRequest = createAction(DELETE_EVENT_REQUEST);
const deleteEventSuccess = createAction(DELETE_EVENT_SUCCESS);
const deleteEventFailure = createAction(DELETE_EVENT_FAILURE);

const addEventRequest = createAction(ADD_EVENT_REQUEST);
const addEventSuccess = createAction(ADD_EVENT_SUCCESS);
const addEventFailure = createAction(ADD_EVENT_FAILURE);

const editEventRequest = createAction(EDIT_EVENT_REQUEST);
const editEventSuccess = createAction(EDIT_EVENT_SUCCESS);
const editEventFailure = createAction(EDIT_EVENT_FAILURE);

const getEventRequest = createAction(GET_EVENT_REQUEST);
const getEventSuccess = createAction(GET_EVENT_SUCCESS);
const getEventFailure = createAction(GET_EVENT_FAILURE);

const getAgreementRequest = createAction(GET_AGREEMENT_REQUEST);
const getAgreementSuccess = createAction(GET_AGREEMENT_SUCCESS);
const getAgreementFailure = createAction(GET_AGREEMENT_FAILURE);

const finalizeAgreementRequest = createAction(FINALIZE_AGREEMENT_REQUEST);
const finalizeAgreementSuccess = createAction(FINALIZE_AGREEMENT_SUCCESS);
const finalizeAgreementFailure = createAction(FINALIZE_AGREEMENT_FAILURE);

export function getAllEvent() {
  return async dispatch => {
    dispatch(getAllEventRequest());
    const {error, response} = await get(`${'FastAccessEvent/All'}`, {});
    if (response) {
      if (response) {
        dispatch(getAllEventSuccess(response));
      } else {
        dispatch(getAllEventFailure(response));
      }
    }
    if (error) {
      dispatch(getAllEventFailure(error));
    }
  };
}

export function deleteEvent(gid) {
  return async dispatch => {
    dispatch(deleteEventRequest());
    const {error, response} = await del(`${'FastAccessEvent/'}${gid}`, {});
    if (response) {
      if (response) {
        dispatch(deleteEventSuccess(response));
      } else {
        dispatch(deleteEventFailure(response));
      }
    }
    if (error) {
      dispatch(deleteEventFailure(error));
    }
  };
}

export function addEventCall(data) {
  return async dispatch => {
    dispatch(addEventRequest());
    const {error, response} = await post(`${'FastAccessEvent'}`, data);
    if (response) {
      if (response.data.results) {
        dispatch(addEventSuccess(response));
        return {
          success: true,
          data: response.data,
        };
      } else {
        dispatch(addEventFailure(response));
        return {
          success: false,
          data: response.data,
        };
      }
    }
    if (error) {
      dispatch(addEventFailure(error));
      return {
        success: false,
        data: error,
      };
    }
  };
}

export function editEventCall(eid, data) {
  return async dispatch => {
    dispatch(editEventRequest());
    const {error, response} = await put(`${'FastAccessEvent/'}${eid}`, data);
    if (response) {
      if (response.data.results) {
        dispatch(editEventSuccess(response));
        return {
          success: true,
          data: response.data,
        };
      } else {
        dispatch(editEventFailure(response));
        return {
          success: false,
          data: response.data,
        };
      }
    }
    if (error) {
      dispatch(editEventFailure(error));
      return {
        success: false,
        data: error,
      };
    }
  };
}

export function getSpecificEvent(eid) {
  return async dispatch => {
    dispatch(getEventRequest());
    const {error, response} = await get(`${'FastAccessEvent/'}${eid}`, {});
    if (response) {
      if (response.data.results) {
        dispatch(getEventSuccess(response));
        return {
          success: true,
          data: response.data,
        };
      } else {
        dispatch(getEventFailure(response));
        return {
          success: true,
          data: response.data,
        };
      }
    }
    if (error) {
      dispatch(getEventFailure(error));
      return {
        success: false,
        data: error,
      };
    }
  };
}

export function getAgreementText(eid) {
  return async dispatch => {
    dispatch(getAgreementRequest());
    const {error, response} = await get(`${'FastAccessEvent/Agreement/'}${eid}`, {});
    if (response) {
      if (response.data.result) {
        dispatch(getAgreementSuccess(response));
        return {
          success: true,
          data: response.data.result,
        };
      } else {
        dispatch(getAgreementFailure(response));
        return {
          success: false,
          data: response.data,
        };
      }
    }
    if (error) {
      dispatch(getAgreementFailure(error));
      return {
        success: false,
        data: error,
      };
    }
  };
}

export function finalizeAgreementCall(eid) {
  return async dispatch => {
    dispatch(finalizeAgreementRequest());
    const {error, response} = await put(`${'FastAccessEvent/Agreement/'}${eid}`, {});
    if (response) {
      if (response.data.result) {
        dispatch(finalizeAgreementSuccess(response));
        return {
          success: true,
          data: response.data.result,
        };
      } else {
        dispatch(finalizeAgreementFailure(response));
        return {
          success: false,
          data: response.data,
        };
      }
    }
    if (error) {
      dispatch(finalizeAgreementFailure(error));
      return {
        success: false,
        data: error,
      };
    }
  };
}
