import {createAction} from 'redux-actions';
import {get, put, del} from '../../api/api';

import {
  GET_NOTIFICATION_SETTINGB_REQUEST,
  GET_NOTIFICATION_SETTINGB_SUCCESS,
  GET_NOTIFICATION_SETTINGB_FAILURE,
  EDIT_NOTIFICATION_SETTINGB_REQUEST,
  EDIT_NOTIFICATION_SETTINGB_SUCCESS,
  EDIT_NOTIFICATION_SETTINGB_FAILURE,
  DELETE_NOTIFICATION_SETTINGB_REQUEST,
  DELETE_NOTIFICATION_SETTINGB_SUCCESS,
  DELETE_NOTIFICATION_SETTINGB_FAILURE,
} from './NotificationSettingsB.action.constant';

const getNotificationSettingBRequest = createAction(
  GET_NOTIFICATION_SETTINGB_REQUEST,
);
const getNotificationSettingBSuccess = createAction(
  GET_NOTIFICATION_SETTINGB_SUCCESS,
);
const getNotificationSettingBFailure = createAction(
  GET_NOTIFICATION_SETTINGB_FAILURE,
);

const editNotificationSettingBRequest = createAction(
  EDIT_NOTIFICATION_SETTINGB_REQUEST,
);
const editNotificationSettingBSuccess = createAction(
  EDIT_NOTIFICATION_SETTINGB_SUCCESS,
);
const editNotificationSettingBFailure = createAction(
  EDIT_NOTIFICATION_SETTINGB_FAILURE,
);

const deleteNotificationSettingBRequest = createAction(
  DELETE_NOTIFICATION_SETTINGB_REQUEST,
);
const deleteNotificationSettingBSuccess = createAction(
  DELETE_NOTIFICATION_SETTINGB_SUCCESS,
);
const deleteNotificationSettingBFailure = createAction(
  DELETE_NOTIFICATION_SETTINGB_FAILURE,
);

export function getNotificationSettingB(token) {
  return async dispatch => {
    dispatch(getNotificationSettingBRequest());
    const {error, response} = await get(`${'NotificationSettingsModeB?deviceToken='}${token}`, {});
    if (response) {
      if (response) {
        dispatch(getNotificationSettingBSuccess(response));
      } else {
        dispatch(getNotificationSettingBFailure(response));
      }
    }
    if (error) {
      dispatch(getNotificationSettingBFailure(error));
    }
    return {error, response};
  };
}

export function editNotificationSettingB(data) {
  return async dispatch => {
    dispatch(editNotificationSettingBRequest());
    const {error, response} = await put(`${'NotificationSettingsModeB'}`, data);
    if (response) {
      if (response) {
        dispatch(editNotificationSettingBSuccess(response));
      } else {
        dispatch(editNotificationSettingBFailure(response));
      }
    }
    if (error) {
      dispatch(editNotificationSettingBFailure(error));
    }
    return {error, response};
  };
}

export function deleteNotificationSettingB(nid) {
  return async dispatch => {
    dispatch(deleteNotificationSettingBRequest());
    const {error, response} = await del(`${'NotificationSettingsModeB/'}${nid}`, {});
    if (response) {
      if (response) {
        dispatch(deleteNotificationSettingBSuccess(response));
      } else {
        dispatch(deleteNotificationSettingBFailure(response));
      }
    }
    if (error) {
      dispatch(deleteNotificationSettingBFailure(error));
    }
  };
}
