import ip, {chain} from 'icepick';
import {
    GET_CONTACT_INFOB_REQUEST,
    GET_CONTACT_INFOB_SUCCESS,
    GET_CONTACT_INFOB_FAILURE,
    EDIT_CONTACT_INFOB_REQUEST,
    EDIT_CONTACT_INFOB_SUCCESS,
    EDIT_CONTACT_INFOB_FAILURE,
    GET_CONTACT_INFOB_PHONES_REQUEST,
    GET_CONTACT_INFOB_PHONES_SUCCESS,
    GET_CONTACT_INFOB_PHONES_FAILURE,
    ADD_CONTACT_INFOB_PHONES_REQUEST,
    ADD_CONTACT_INFOB_PHONES_SUCCESS,
    ADD_CONTACT_INFOB_PHONES_FAILURE,
    PUT_CONTACT_INFOB_PHONES_REQUEST,
    PUT_CONTACT_INFOB_PHONES_SUCCESS,
    PUT_CONTACT_INFOB_PHONES_FAILURE,
    DEL_CONTACT_INFOB_PHONES_REQUEST,
    DEL_CONTACT_INFOB_PHONES_SUCCESS,
    DEL_CONTACT_INFOB_PHONES_FAILURE,
  } from './ContactInfoB.action.constant';

const initialState = ip.freeze({
  getContactINFOBStatus: null,
  getContactINFOBError: null,
  contactINFOB: null,
  editContactINFOBStatus: null,
  editContactINFOBError: null,
  editContactINFOB: null,
  getContactINFOBPhonesStatus: null,
  getContactINFOBPhonesError: null,
  contactINFOBPhones: null,
  addContactINFOBPhonesStatus: null,
  addContactINFOBPhonesError: null,
  addContactINFOBPhones: null,
  putContactINFOBPhonesStatus: null,
  putContactINFOBPhonesError: null,
  putContactINFOBPhones: null,
  delContactINFOBPhonesStatus: null,
  delContactINFOBPhonesError: null,
  delContactINFOBPhones: null,
});

export default function getContactINFOB(state = initialState, action) {
  switch (action.type) {
    case GET_CONTACT_INFOB_REQUEST: {
      return chain(state)
        .setIn(['getContactINFOBError'], null)
        .setIn(['getContactINFOBStatus'], 'started')
        .setIn(['contactINFOB'], [])
        .value();
    }

    case GET_CONTACT_INFOB_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['getContactINFOBError'], null)
        .setIn(['getContactINFOBStatus'], 'finished')
        .setIn(['contactINFOB'], data.contactSettings[0])
        .value();
    }

    case GET_CONTACT_INFOB_FAILURE: {
      return chain(state)
        .setIn(['getContactINFOBError'], 'error')
        .setIn(['getContactINFOBStatus'], 'failled')
        .setIn(['contactINFOB'], [])
        .value();
    }

    case EDIT_CONTACT_INFOB_REQUEST: {
      return chain(state)
        .setIn(['editContactINFOBError'], null)
        .setIn(['editContactINFOBStatus'], 'started')
        .setIn(['editContactINFOB'], [])
        .value();
    }

    case EDIT_CONTACT_INFOB_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['editContactINFOBError'], null)
        .setIn(['editContactINFOBStatus'], 'finished')
        .setIn(['editContactINFOB'], data)
        .value();
    }

    case EDIT_CONTACT_INFOB_FAILURE: {
      return chain(state)
        .setIn(['editContactINFOBError'], 'error')
        .setIn(['editContactINFOBStatus'], 'failled')
        .setIn(['editContactINFOB'], [])
        .value();
    }

    case GET_CONTACT_INFOB_PHONES_REQUEST: {
      return chain(state)
        .setIn(['getContactINFOBPhonesError'], null)
        .setIn(['getContactINFOBPhonesStatus'], 'started')
        .setIn(['contactINFOBPhones'], [])
        .value();
    }

    case GET_CONTACT_INFOB_PHONES_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['getContactINFOBPhonesError'], null)
        .setIn(['getContactINFOBPhonesStatus'], 'finished')
        .setIn(['contactINFOBPhones'], data.contactPhones[0])
        .value();
    }

    case GET_CONTACT_INFOB_PHONES_FAILURE: {
      return chain(state)
        .setIn(['getContactINFOBPhonesError'], 'error')
        .setIn(['getContactINFOBPhonesStatus'], 'failled')
        .setIn(['contactINFOBPhones'], [])
        .value();
    }

    case ADD_CONTACT_INFOB_PHONES_REQUEST: {
      return chain(state)
        .setIn(['addContactINFOBPhonesError'], null)
        .setIn(['addContactINFOBPhonesStatus'], 'started')
        .setIn(['addContactINFOBPhones'], [])
        .value();
    }

    case ADD_CONTACT_INFOB_PHONES_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['addContactINFOBPhonesError'], null)
        .setIn(['addContactINFOBPhonesStatus'], 'finished')
        .setIn(['addContactINFOBPhones'], data)
        .value();
    }

    case ADD_CONTACT_INFOB_PHONES_FAILURE: {
      return chain(state)
        .setIn(['addContactINFOBPhonesError'], 'error')
        .setIn(['addContactINFOBPhonesStatus'], 'failled')
        .setIn(['addContactINFOBPhones'], [])
        .value();
    }

    case PUT_CONTACT_INFOB_PHONES_REQUEST: {
      return chain(state)
        .setIn(['putContactINFOBPhonesError'], null)
        .setIn(['putContactINFOBPhonesStatus'], 'started')
        .setIn(['putContactINFOBPhones'], [])
        .value();
    }

    case PUT_CONTACT_INFOB_PHONES_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['putContactINFOBPhonesError'], null)
        .setIn(['putContactINFOBPhonesStatus'], 'finished')
        .setIn(['putContactINFOBPhones'], data)
        .value();
    }

    case PUT_CONTACT_INFOB_PHONES_FAILURE: {
      return chain(state)
        .setIn(['putContactINFOBPhonesError'], 'error')
        .setIn(['putContactINFOBPhonesStatus'], 'failled')
        .setIn(['putContactINFOBPhones'], [])
        .value();
    }

    case DEL_CONTACT_INFOB_PHONES_REQUEST: {
      return chain(state)
        .setIn(['delContactINFOBPhonesError'], null)
        .setIn(['delContactINFOBPhonesStatus'], 'started')
        .setIn(['delContactINFOBPhones'], [])
        .value();
    }

    case DEL_CONTACT_INFOB_PHONES_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['delContactINFOBPhonesError'], null)
        .setIn(['delContactINFOBPhonesStatus'], 'finished')
        .setIn(['delContactINFOBPhones'], data)
        .value();
    }

    case DEL_CONTACT_INFOB_PHONES_FAILURE: {
      return chain(state)
        .setIn(['delContactINFOBPhonesError'], 'error')
        .setIn(['delContactINFOBPhonesStatus'], 'failled')
        .setIn(['delContactINFOBPhones'], [])
        .value();
    }

    default:
      return state;
  }
}
