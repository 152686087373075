import ip, {chain} from 'icepick';
import {LOGIN_PREFERENCE} from './Login.action.constant';

const initialState = ip.freeze({
  rememberMe: false,
  autoLogin: false,
  lastUserCred: null,
});

export default function loginPref(state = initialState, action) {
  switch (action.type) {
    case LOGIN_PREFERENCE: {
      // console.log('@reducer: loginPref');
      const data = action.payload;
      return chain(state)
        .setIn(['rememberMe'], data[0])
        .setIn(['autoLogin'], data[1])
        .setIn(['lastUserCred'], data[2])
        .value();
    }

    default:
      return state;
  }
}
