import { connect } from 'react-redux';
// import _get from 'lodash/get';
import RegisteredVehicles from '../../screens/RegisteredVehicles';

import { getRegisteredVehicles } from './RegisteredVehicles.action';

const mapStateToProps = state => ({
  registeredVehicles: state.registeredVehicles.registeredVehicles,
});

export default connect(
  mapStateToProps,
  {
    getRegisteredVehicles,
  },
)(RegisteredVehicles);
