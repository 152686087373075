import { connect } from 'react-redux';
// import _get from 'lodash/get';
import MainMenuWebAdmin from '../../vendorscreens/MainMenuWebAdmin';

import { vendorLogOut } from '../VendorLogin/VendorLogin.action';

const mapStateToProps = state => ({
    vendorLoginData: state.vendorLoginData.vendorLoginData,
});

export default connect(
  mapStateToProps,
  {
    vendorLogOut,
  },
)(MainMenuWebAdmin);
