import ip, {chain} from 'icepick';
import {
  GET_COMMUNITY_CODES_REQUEST,
  GET_COMMUNITY_CODES_SUCCESS,
  GET_COMMUNITY_CODES_FAILURE,
} from './SpalshScreen.action.constant';

const initialState = ip.freeze({
  getComunityCodesStatus: null,
  getComunityCodesError: null,
  comunityCodes: null,
});

export default function getComunityCodes(state = initialState, action) {
  switch (action.type) {
    case GET_COMMUNITY_CODES_REQUEST: {
      return chain(state)
        .setIn(['getComunityCodesError'], null)
        .setIn(['getComunityCodesStatus'], 'started')
        .setIn(['comunityCodes'], [])
        .value();
    }

    case GET_COMMUNITY_CODES_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['getComunityCodesError'], null)
        .setIn(['getComunityCodesStatus'], 'finished')
        .setIn(['comunityCodes'], data.result)
        .value();
    }

    case GET_COMMUNITY_CODES_FAILURE: {
      return chain(state)
        .setIn(['getComunityCodesError'], 'error')
        .setIn(['getComunityCodesStatus'], 'failled')
        .setIn(['comunityCodes'], [])
        .value();
    }

    default:
      return state;
  }
}
