import ip, {chain} from 'icepick';
import {
  GET_ALL_EVENT_REQUEST,
  GET_ALL_EVENT_SUCCESS,
  GET_ALL_EVENT_FAILURE,
  DELETE_EVENT_REQUEST,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_FAILURE,
  ADD_EVENT_REQUEST,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_FAILURE,
  EDIT_EVENT_REQUEST,
  EDIT_EVENT_SUCCESS,
  EDIT_EVENT_FAILURE,
  GET_EVENT_REQUEST,
  GET_EVENT_SUCCESS,
  GET_EVENT_FAILURE,
  GET_AGREEMENT_REQUEST,
  GET_AGREEMENT_SUCCESS,
  GET_AGREEMENT_FAILURE,
  FINALIZE_AGREEMENT_REQUEST,
  FINALIZE_AGREEMENT_SUCCESS,
  FINALIZE_AGREEMENT_FAILURE,
} from './ManageEvents.action.constant';

const initialState = ip.freeze({
  getAllEventStatus: null,
  getAllEventError: null,
  allEvent: null,
  deleteEventStatus: null,
  deleteEventError: null,
  deleteEvent: null,
  addEventStatus: null,
  addEventError: null,
  addEvent: null,
  editEventStatus: null,
  editEventError: null,
  editEvent: null,
  getEventStatus: null,
  getEventError: null,
  getEvent: null,
});

export default function getAllEvent(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_EVENT_REQUEST: {
      return chain(state)
        .setIn(['getAllEventError'], null)
        .setIn(['getAllEventStatus'], 'started')
        .setIn(['allEvent'], [])
        .value();
    }

    case GET_ALL_EVENT_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['getAllEventError'], null)
        .setIn(['getAllEventStatus'], 'finished')
        .setIn(['allEvent'], data.result)
        .value();
    }

    case GET_ALL_EVENT_FAILURE: {
      return chain(state)
        .setIn(['getAllEventError'], 'error')
        .setIn(['getAllEventStatus'], 'failled')
        .setIn(['allEvent'], [])
        .value();
    }

    case DELETE_EVENT_REQUEST: {
      return chain(state)
        .setIn(['deleteEventError'], null)
        .setIn(['deleteEventStatus'], 'started')
        .setIn(['deleteEvent'], [])
        .value();
    }

    case DELETE_EVENT_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['deleteEventError'], null)
        .setIn(['deleteEventStatus'], 'finished')
        .setIn(['deleteEvent'], data.employees)
        .value();
    }

    case DELETE_EVENT_FAILURE: {
      return chain(state)
        .setIn(['deleteEventError'], 'error')
        .setIn(['deleteEventStatus'], 'failled')
        .setIn(['deleteEvent'], [])
        .value();
    }

    case EDIT_EVENT_REQUEST: {
      return chain(state)
        .setIn(['editEventError'], null)
        .setIn(['editEventStatus'], 'started')
        .setIn(['editEvent'], [])
        .value();
    }

    case EDIT_EVENT_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['editEventError'], null)
        .setIn(['editEventStatus'], 'finished')
        .setIn(['editEvent'], data)
        .value();
    }

    case EDIT_EVENT_FAILURE: {
      return chain(state)
        .setIn(['editEventError'], 'error')
        .setIn(['editEventStatus'], 'failled')
        .setIn(['editEvent'], [])
        .value();
    }

    case ADD_EVENT_REQUEST: {
      return chain(state)
        .setIn(['addEventError'], null)
        .setIn(['addEventStatus'], 'started')
        .setIn(['addEvent'], [])
        .value();
    }

    case ADD_EVENT_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['addEventError'], null)
        .setIn(['addEventStatus'], 'finished')
        .setIn(['addEvent'], data)
        .value();
    }

    case ADD_EVENT_FAILURE: {
      return chain(state)
        .setIn(['addEventError'], 'error')
        .setIn(['addEventStatus'], 'failled')
        .setIn(['addEvent'], [])
        .value();
    }

    case GET_EVENT_REQUEST: {
      return chain(state)
        .setIn(['getEventError'], null)
        .setIn(['getEventStatus'], 'started')
        .setIn(['getEvent'], [])
        .value();
    }

    case GET_EVENT_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['getEventError'], null)
        .setIn(['getEventStatus'], 'finished')
        .setIn(['getEvent'], data.result)
        .value();
    }

    case GET_EVENT_FAILURE: {
      return chain(state)
        .setIn(['getEventError'], 'error')
        .setIn(['getEventStatus'], 'failled')
        .setIn(['getEvent'], [])
        .value();
    }

    case GET_AGREEMENT_REQUEST: {
      return chain(state)
        .setIn(['getAgreementError'], null)
        .setIn(['getAgreementStatus'], 'started')
        .setIn(['getAgreement'], [])
        .value();
    }

    case GET_AGREEMENT_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['getAgreementError'], null)
        .setIn(['getAgreementStatus'], 'finished')
        .setIn(['getAgreement'], data.result)
        .value();
    }

    case GET_AGREEMENT_FAILURE: {
      return chain(state)
        .setIn(['getAgreementError'], 'error')
        .setIn(['getAgreementStatus'], 'failled')
        .setIn(['getAgreement'], [])
        .value();
    }

    case FINALIZE_AGREEMENT_REQUEST: {
      return chain(state)
        .setIn(['finalizeAgreementError'], null)
        .setIn(['finalizeAgreementStatus'], 'started')
        .setIn(['finalizeAgreement'], [])
        .value();
    }

    case FINALIZE_AGREEMENT_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['finalizeAgreementError'], null)
        .setIn(['finalizeAgreementStatus'], 'finished')
        .setIn(['finalizeAgreement'], data.result)
        .value();
    }

    case FINALIZE_AGREEMENT_FAILURE: {
      return chain(state)
        .setIn(['finalizeAgreementError'], 'error')
        .setIn(['finalizeAgreementStatus'], 'failled')
        .setIn(['finalizeAgreement'], [])
        .value();
    }

    default:
      return state;
  }
}
