import React from 'react';
import Select from '@material-ui/core/Select';
import InputMask from 'react-input-mask';
import { Heading, VStack, FormControl, Input, Button, Icon, Spinner, Flex, IconButton, Text, HStack, Pressable } from "native-base";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import {materialColor, listMainTextColor, underlayColor, searchBarBorderColor, borderBottomColor, brandColor, headerColor, borderColor, titleTextColor, lightGreen} from '../constants/colors';
import Snackbar from '@material-ui/core/Snackbar';
import {ContactInfoBanner} from '../assets/images';

const styles = {
  paper: {
    flex: 1,
    // padding: 5
  },
  selectStyle: {width: 240},
  textStyle: {
    marginLeft: 25,
  },
  fab: {
    position: 'absolute',
    bottom: 30,
    right: 40,
    zIndex: 10,
    elevation: 10
  },
  loading: {
    position: 'absolute',
    left: 420,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    //backgroundColor: '#0000ff47',
    display:'flex'
  },
};

class ContactInfo extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedItems: [],
      homePhone: '',
      secondPhone: '',
      businessPhone: '',
      cellPhone1: '',
      cellPhone1Name: '',
      cellPhone2: '',
      cellPhone2Name: '',
      email1: '',
      email2: '',
      email3: '',
      cellProvider1: '',
      cellProvider2: '',
      providerOpts: [],
      loading: false,
      darkMode: false,
      textColor: '#000000',
      message: '',
      messageType: '',
      alertOpen: false
    };
  }

  createArray = items => {
    let newArr = [];
    for (let x in items) {
      let newItem = {
        label: items[x],
        value: items[x],
      };
      newArr.push(newItem);
    }
    return newArr;
  };

  getValue(setting) {
    const {loginData} = this.props;
    if (loginData) {
      const appOptions = loginData.appOptions;
      for (let x of appOptions) {
        if (x.setting === setting) {
          return x.value;
        }
      }
    }
  }

  componentDidMount = async () => {
    const {getContactInfo, globalData} = this.props;
    const {dropdownCellCarrierTypes} = globalData;
    const thisThat = this;
    this.setState({
      providerOpts: thisThat.createArray(dropdownCellCarrierTypes),
    });
    this.setState({loading: true});
    const {error, response} = await getContactInfo();
    this.setState({loading: false});
    if (error) {
    } else {
      //console.log('@componentWillMount');
      let dataSet = response.data.contactSettings[0];
      //console.log(dataSet);
      this.setState({
        homePhone: dataSet.home_phone,
        secondPhone: dataSet.second_phone,
        businessPhone: dataSet.business_phone,
        cellPhone1: dataSet.cell_phone,
        cellPhone1Name: dataSet.cell_Phone_Text,
        cellPhone2: dataSet.cell_phone2,
        cellPhone2Name: dataSet.cell_Phone2_Text,
        email1: dataSet.emailaddress,
        email2: dataSet.secondemail,
        email3: dataSet.thirdemail,
        cellProvider1: dataSet.cellProvider1,
        cellProvider2: dataSet.cellProvider2,
      });
    }
  };

  handleChangePicker = name => event => {
    // console.log(name);
    // console.log(event.target.value);
    this.setState({
      ...this.state,
      [name]: event.target.value,
    });
  };

  handleChange = (e) => {
    // console.log(e.target.name);
    // console.log(e.target.value);
    this.setState(Object.assign(this.state, { [e.target.name]: e.target.value }))
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({alertOpen: false});
  };

  showAlert = (message, severity) => {
    // console.log('showing alert');
    const { classes } = this.props;
    return(
      <div className={classes.fab} style={{position: 'fixed'}}>
        <Snackbar open={this.state.alertOpen} autoHideDuration={3000} onClose={this.handleClose}>
          <Alert
            variant="filled"
            severity={severity}
          >
            {message}
          </Alert>
        </Snackbar>
      </div>
    )
  }

  addEditContactInfo = async() => {
    this.setState({loading: true, alertOpen: true, message: 'Loading...', messageType: 'info'});
    const {editContactInfo} = this.props;
    let {
      homePhone,
      secondPhone,
      businessPhone,
      cellPhone1,
      cellPhone2,
      email1,
      email2,
      email3,
      cellPhone1Name,
      cellPhone2Name,
      cellProvider1,
      cellProvider2,
    } = this.state;

    var homePhone1 = homePhone.replaceAll('_', '');
    var secondPhone1 = secondPhone.replaceAll('_', '');
    var businessPhone1 = businessPhone.replaceAll('_', '');
    var cellPhone11 = cellPhone1.replaceAll('_', '');
    var cellPhone21 = cellPhone2.replaceAll('_', '');

    homePhone1 = homePhone1.replace(/\s/g,'');
    secondPhone1 = secondPhone1.replace(/\s/g,'');
    businessPhone1 = businessPhone1.replace(/\s/g,'');
    cellPhone11 = cellPhone11.replace(/\s/g,'');
    cellPhone21 = cellPhone21.replace(/\s/g,'');

    // console.log('secondPhone: ',secondPhone.length);
    // console.log('secondPhone1: ',secondPhone1.length);
    if(homePhone1.length > 0 && homePhone1.length < 10){
      this.setState({message: 'Home phone number should be of 10 digits', loading: false, messageType: 'error'});
      return;
    }

    if(secondPhone1.length > 0 && secondPhone1.length < 10){
      this.setState({message: 'Secondary phone number should be of 10 digits', loading: false, messageType: 'error'});
      return;
    }

    if(businessPhone1.length > 0 && businessPhone1.length < 10){
      this.setState({message: 'Business phone number should be of 10 digits', loading: false, messageType: 'error'});
      return;
    }

    if(cellPhone11.length > 0 && cellPhone11.length < 10){
      this.setState({message: 'Cellphone1 should be of 10 digits', loading: false, messageType: 'error'});
      return;
    }

    if(cellPhone21.length > 0 && cellPhone21.length < 10){
      this.setState({message: 'Cellphone2 should be of 10 digits', loading: false, messageType: 'error'});
      return;
    }
    // console.log('email1: '+email1+' email2: '+email2+' email3: '+email3);
    var reEmail = /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
    // var reEmail = /^([\w-]+(?:\.[\w-]+))@((?:[\w-]+\.)\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;
    // if (email1 === null || email1 === '') {
    //   this.setState({ message: 'E-mail 1 can\'t be Empty' });
    //   return;
    // }
    if (email1.length>0 && new RegExp(reEmail).test(email1) === false) {
      this.setState({ message: 'E-mail 1 is not in good format', loading: false, messageType: 'error' });
      return;
    }
    // if (email2 === null || email2 === '') {
    //   this.setState({ message: 'E-mail 2 can\'t be Empty' });
    //   return;
    // }
    if (email2.length>0 && new RegExp(reEmail).test(email2) === false) {
      this.setState({ message: 'E-mail 2 is not in good format', loading: false, messageType: 'error' });
      return;
    }
    // if (email3 === null || email3 === '') {
    //   this.setState({ message: 'E-mail 3 can\'t be Empty' });
    //   return;
    // }
    if (email3.length>0 && new RegExp(reEmail).test(email3) === false) {
      this.setState({ message: 'E-mail 3 is not in good format', loading: false, messageType: 'error' });
      return;
    }

    await editContactInfo({
      business_phone: businessPhone,
      cell_phone: cellPhone1,
      cell_phone2: cellPhone2,
      emailaddress: email1,
      guestarrival: 'Alert All',
      secondemail: email2,
      thirdemail: email3,
      second_phone: secondPhone,
      incDirAddress: '',
      incDirPhone: '',
      incDirCells: '',
      incDirEmails: '',
      cellProvider1: cellProvider1,
      cellProvider2: cellProvider2,
      home_phone: homePhone,
      cell_Phone_Text: cellPhone1Name,
      cell_Phone2_Text: cellPhone2Name,
    });
    this.setState({loading: false, alertOpen: true, message: 'Contact info updated.', messageType: 'success'});
  };

  render() {
    const {
      homePhone,
      secondPhone,
      businessPhone,
      cellPhone1,
      cellPhone1Name,
      cellPhone2,
      // cellPhone2Name,
      email1,
      email2,
      email3,
      cellProvider1,
      cellProvider2,
      providerOpts,
      loading,
      message,
      messageType
    } = this.state;
    const { classes } = this.props;

    return (
      <div className={classes.paper}>
        {this.showAlert(message, messageType)}
        <img
          style={{width: window.innerWidth, resizeMode: 'contain', margin: 'auto', alignSelf: 'center'}}
          src={ContactInfoBanner}
          alt={'ContactInfoBanner'}
        />
        <div style={{backgroundColor: headerColor, padding: 20, marginTop: 5, borderRadius: 10, zIndex: 10, elevation: 10, marginBottom: 20}}>
          <VStack style={{borderWidth: 1, borderRadius: 5, borderColor: borderColor, backgroundColor: 'white', marginTop: 15}}>
            <VStack space={5} justifyContent="center" >
              <Heading ml={5} mt={5} _dark={{
                color: titleTextColor
              }} color={titleTextColor} fontWeight="medium" size="md">
                Phone Numbers:
              </Heading>
              {/* <div> */}
              <VStack space={5} ml={5} mr={5}>
                <HStack space={4} w='32%' alignItems='center' justifyContent='space-between'>
                  <FormControl>
                    <FormControl.Label>
                      Home Phone
                    </FormControl.Label>
                    <InputMask
                      mask="999 999 9999"
                      value={homePhone}
                      onChange={this.handleChange}
                    >
                      {() => <Input
                        // variant="rounded"
                        borderColor={borderColor}
                        size="lg"
                        autoCapitalize={false}
                        autoCorrect={false}
                        placeholder={'Enter home phone'}
                        InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'phone']} size="lg"/>} />}/>}
                             />}
                    </InputMask>
                  </FormControl>
                  <FormControl>
                    <FormControl.Label>
                      Secondary Phone
                    </FormControl.Label>
                    <InputMask
                      mask="999 999 9999"
                      value={secondPhone}
                      onChange={this.handleChange}
                    >
                      {() => <Input
                        // variant="rounded"
                        borderColor={borderColor}
                        size="lg"
                        autoCapitalize={false}
                        autoCorrect={false}
                        placeholder={'Enter secondary phone'}
                        InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'phone']} size="lg"/>} />}/>}
                             />}
                    </InputMask>
                  </FormControl>
                  <FormControl>
                    <FormControl.Label>
                      Business Phone
                    </FormControl.Label>
                    <InputMask
                      mask="999 999 9999"
                      value={businessPhone}
                      onChange={this.handleChange}
                    >
                      {() => <Input
                        // variant="rounded"
                        borderColor={borderColor}
                        size="lg"
                        autoCapitalize={false}
                        autoCorrect={false}
                        placeholder={'Enter business phone'}
                        InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'phone']} size="lg"/>} />}/>}
                             />}
                    </InputMask>
                  </FormControl>
                </HStack>
                <HStack space={4} w='32%' alignItems='center' justifyContent='space-between'>
                  <FormControl>
                    <FormControl.Label>
                      Cell Phone #1
                    </FormControl.Label>
                    <InputMask
                      mask="999 999 9999"
                      value={cellPhone1}
                      onChange={this.handleChange}
                    >
                      {() => <Input
                        // variant="rounded"
                        borderColor={borderColor}
                        size="lg"
                        autoCapitalize={false}
                        autoCorrect={false}
                        placeholder={'Enter cell phone #1'}
                        InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'phone']} size="lg"/>} />}/>}
                             />}
                    </InputMask>
                  </FormControl>

                  <FormControl>
                    {this.getValue('HideCellProviders') && (
                      <FormControl.Label>
                        Select Provider
                      </FormControl.Label>
                    )}
                    {this.getValue('HideCellProviders') && (
                      <Select
                        native
                        className={classes.selectStyle}
                        value={cellProvider1}
                        onChange={this.handleChangePicker('cellProvider1')}
                        inputProps={{
                          name: 'cellProvider1',
                          id: 'age-native-simple',
                        }}
                      >

                        {providerOpts.map((item, index) => {
                          return <option value={item.value} key={index}>{item.label}</option>
                        })}

                      </Select>
                    )}
                  </FormControl>
                  <FormControl>
                    <FormControl.Label>
                      Cell Phone #1 Contact Name
                    </FormControl.Label>

                    <Input
                      // variant="rounded"
                      borderColor={borderColor}
                      size="lg"
                      // isReadOnly={!isEditableText}
                      // style={{ color: (this.state.darkMode) ? '#ffffff' : '#000000' }}
                      onChangeText={cellPhone1Name => this.setState({cellPhone1Name})}
                      value={cellPhone1Name}
                      autoCapitalize={false}
                      autoCorrect={false}
                      placeholder={'Cell Phone #1 Contact Name'}
                      // placeholderTextColor={(darkMode) ? '##666' : '#C7C7CD'}
                      InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'user']} size="lg"/>} />}/>}
                      // InputLeftElement={<Icon as={<MaterialIcons name="person" />} size={5} ml="2" color="muted.400" />}
                      returnKeyType='next'
                    />
                  </FormControl>
                </HStack>
                <HStack space={4} w='32%' alignItems='center' justifyContent='space-between'>
                  <FormControl>
                    <FormControl.Label>
                      Cell Phone #2
                    </FormControl.Label>
                    <InputMask
                      mask="999 999 9999"
                      value={cellPhone2}
                      onChange={this.handleChange}
                    >
                      {()=><Input
                        // variant="rounded"
                        borderColor={borderColor}
                        size="lg"
                        autoCapitalize={false}
                        autoCorrect={false}
                        placeholder={'Enter cell phone #2'}
                        InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'phone']} size="lg"/>} />}/>}
                           />}
                    </InputMask>
                  </FormControl>

                  <FormControl>
                    {this.getValue('HideCellProviders') && (
                      <FormControl.Label>
                        Select Provider
                      </FormControl.Label>
                    )}
                    {this.getValue('HideCellProviders') && (
                      <Select
                        native
                        className={classes.selectStyle}
                        value={cellProvider2}
                        onChange={this.handleChangePicker('cellProvider2')}
                        inputProps={{
                          name: 'cellProvider2',
                          id: 'cellProvider2',
                        }}
                      >

                        {providerOpts.map((item, index) => {
                          return <option value={item.value} key={index}>{item.label}</option>
                        })}

                      </Select>
                    )}
                  </FormControl>
                  <FormControl>
                    <FormControl.Label>
                      Cell Phone #2 Contact Name
                    </FormControl.Label>

                    <Input
                      // variant="rounded"
                      borderColor={borderColor}
                      size="lg"
                      // isReadOnly={!isEditableText}
                      // style={{ color: (this.state.darkMode) ? '#ffffff' : '#000000' }}
                      onChangeText={cellPhone1Name => this.setState({cellPhone1Name})}
                      value={cellPhone1Name}
                      autoCapitalize={false}
                      autoCorrect={false}
                      placeholder={'Cell Phone #2 Contact Name'}
                      // placeholderTextColor={(darkMode) ? '##666' : '#C7C7CD'}
                      InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'user']} size="lg"/>} />}/>}
                      // InputLeftElement={<Icon as={<MaterialIcons name="person" />} size={5} ml="2" color="muted.400" />}
                      returnKeyType='next'
                    />
                  </FormControl>
                </HStack>
              </VStack>
              <Heading ml={5} mt={5} _dark={{
                color: titleTextColor
              }} color={titleTextColor} fontWeight="medium" size="md">
                E-mail Addresses:
              </Heading>
              <VStack space={7} ml={5} mr={5}>
                <HStack space={4} alignItems='center' justifyContent='space-between'>
                  <Input
                    // variant="rounded"
                    w="48%"
                    borderColor={borderColor}
                    size="lg"
                    // isReadOnly={!isEditableText}
                    // style={{ color: (this.state.darkMode) ? '#ffffff' : '#000000' }}
                    onChangeText={email1 => this.setState({email1})}
                    value={email1}
                    autoCapitalize={false}
                    autoCorrect={false}
                    placeholder={'First E-mail'}
                    // placeholderTextColor={(darkMode) ? '##666' : '#C7C7CD'}
                    InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'envelope']} size="lg"/>} />}/>}
                    // InputLeftElement={<Icon as={<MaterialIcons name="person" />} size={5} ml="2" color="muted.400" />}
                    returnKeyType='next'
                  />
                  <Input
                    // variant="rounded"
                    w="48%"
                    borderColor={borderColor}
                    size="lg"
                    // isReadOnly={!isEditableText}
                    // style={{ color: (this.state.darkMode) ? '#ffffff' : '#000000' }}
                    onChangeText={email2 => this.setState({email2})}
                    value={email2}
                    autoCapitalize={false}
                    autoCorrect={false}
                    placeholder={'Second E-mail'}
                    // placeholderTextColor={(darkMode) ? '##666' : '#C7C7CD'}
                    InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'envelope']} size="lg"/>} />}/>}
                    // InputLeftElement={<Icon as={<MaterialIcons name="person" />} size={5} ml="2" color="muted.400" />}
                    returnKeyType='next'
                  />
                </HStack>
                <Input
                  // variant="rounded"
                  w="48%"
                  borderColor={borderColor}
                  size="lg"
                  // isReadOnly={!isEditableText}
                  // style={{ color: (this.state.darkMode) ? '#ffffff' : '#000000' }}
                  onChangeText={email3 => this.setState({email3})}
                  value={email3}
                  autoCapitalize={false}
                  autoCorrect={false}
                  placeholder={'Third E-mail'}
                  // placeholderTextColor={(darkMode) ? '##666' : '#C7C7CD'}
                  InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'envelope']} size="lg"/>} />}/>}
                  // InputLeftElement={<Icon as={<MaterialIcons name="person" />} size={5} ml="2" color="muted.400" />}
                  returnKeyType='done'
                />
              </VStack>

              <HStack space={3} m={6} w='95%' justifyContent='flex-end' mb={5} mt={5}>
                <Pressable
                  style={{borderRadius: 5, width: '25%', justifyContent: 'center', alignItems: 'center', background: 'linear-gradient(180deg, #0B7645 30%, #042B1C 90%)'}}
                  onPress={() => this.addEditContactInfo()}
                  disabled={loading}
                  underlayColor={underlayColor}>
                  <HStack space={3} justifyContent="space-between" alignItems="center" p="4">
                    <Icon as={<FontAwesomeIcon icon={['fas', 'user-plus']} size="lg" color={"white"}/>}/>
                    <Heading _dark={{
                      color: "white"
                    }} color="white" fontWeight="medium" size="sm">
                      Save
                    </Heading>
                  </HStack>
                </Pressable>
                <Pressable
                  style={{borderRadius: 5, width: '25%', justifyContent: 'center', alignItems: 'center', background: '#ED1C24'}}
                  onPress={()=>{this.props.history.goBack()}}
                  disabled={loading}
                  underlayColor={underlayColor}>
                  <Heading _dark={{
                      color: "white"
                  }} color="white" fontWeight="medium" size="sm">
                    Cancel
                  </Heading>
                </Pressable>
              </HStack>

              {/* <VStack mt="8" mb='10'>
                <Button _light={{ bg: materialColor, _text: { color: "white" } }}
                  _dark={{ bg: materialColor, _text: { color: "white" } }}
                  disabled={loading}
                  style={{borderRadius: 20}}
                  onPress={() => this.addEditContactInfo()}>
                  Save
                </Button>
              </VStack> */}
            </VStack>
          </VStack>
          {this.getValue('ContactsPageMessage') && (
            <VStack style={{marginTop: 10, backgroundColor: lightGreen, justifyContent: 'center', alignItems: 'center',marginHorizontal:10, borderRadius: 5}}>
              <Text style={{padding: 5, color: "#000000", textAlign:'center'}}>
                {this.getValue('ContactsPageMessage')}
              </Text>
            </VStack>
          )}
        </div>
        {loading && (
          <div className={classes.loading}>
            <Spinner size="lg" />
          </div>
        )}
      </div>
    );
  }
}
ContactInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(ContactInfo);
