import { connect } from 'react-redux';
// import _get from 'lodash/get';
import CommunityInfo from '../../screens/CommunityInfo';

import { getCommunityInfo, } from './CommunityInfo.action'

const mapStateToProps = state => ({
    communityInfo: state.communityInfo,
    loginData: state.loginData.loginData,
});

export default connect(
  mapStateToProps,
  {
    getCommunityInfo,
  },
)(CommunityInfo);
