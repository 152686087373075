import { createAction } from 'redux-actions';
import { get } from '../../api/api';

import {
    GET_COMMUNITY_CODES_REQUEST,
    GET_COMMUNITY_CODES_SUCCESS,
    GET_COMMUNITY_CODES_FAILURE
} from './SpalshScreen.action.constant'

const getCommunityCodesRequest = createAction(GET_COMMUNITY_CODES_REQUEST);
const getCommunityCodesSuccess = createAction(GET_COMMUNITY_CODES_SUCCESS);
const getCommunityCodesFailure = createAction(GET_COMMUNITY_CODES_FAILURE);

export function getCommunityCodes() {
    return async (dispatch) => {
      dispatch(getCommunityCodesRequest());
      const { error, response } = await get(
        `${'ApiAuthorization/CommunityCodes'}`,
        {}
      );
      if (response) {
        // console.log('communityCode: ',response);
        if (response.data) {
          dispatch(getCommunityCodesSuccess(response));
        } else {
          dispatch(getCommunityCodesFailure(response));
        }
      }
      if (error) {
        dispatch(getCommunityCodesFailure(error));
      }
      return { error, response };
    };
  }
