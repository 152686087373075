import { connect } from 'react-redux';
// import _get from 'lodash/get';
import SplashScreen from '../../screens/SplashScreen';

import { getCommunityCodes } from './SpalshScreen.action';

const mapStateToProps = state => ({
  loginData: state.loginData.loginData,
  communityCodes: state.appConst.comunityCodes,
  loginPref: state.loginPref,
});

export default connect(
  mapStateToProps,
  {
    getCommunityCodes
  },
)(SplashScreen);
