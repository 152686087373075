export const ALL_GUESTS = 'ALL_GUESTS';

export const GET_ALL_GUEST_REQUEST = 'GET_ALL_GUEST_REQUEST';
export const GET_ALL_GUEST_SUCCESS = 'GET_ALL_GUEST_SUCCESS';
export const GET_ALL_GUEST_FAILURE = 'GET_ALL_GUEST_FAILURE';

export const DELETE_GUEST_REQUEST = 'DELETE_GUEST_REQUEST';
export const DELETE_GUEST_SUCCESS = 'DELETE_GUEST_SUCCESS';
export const DELETE_GUEST_FAILURE = 'DELETE_GUEST_FAILURE';

export const ADD_GUEST_REQUEST = 'ADD_GUEST_REQUEST';
export const ADD_GUEST_SUCCESS = 'ADD_GUEST_SUCCESS';
export const ADD_GUEST_FAILURE = 'ADD_GUEST_FAILURE';

export const EDIT_GUEST_REQUEST = 'EDIT_GUEST_REQUEST';
export const EDIT_GUEST_SUCCESS = 'EDIT_GUEST_SUCCESS';
export const EDIT_GUEST_FAILURE = 'EDIT_GUEST_FAILURE';

export const GET_GUEST_REQUEST = 'GET_GUEST_REQUEST';
export const GET_GUEST_SUCCESS = 'GET_GUEST_SUCCESS';
export const GET_GUEST_FAILURE = 'GET_GUEST_FAILURE';

export const ADD_BULK_GUEST_REQUEST = 'ADD_BULK_GUEST_REQUEST';
export const ADD_BULK_GUEST_SUCCESS = 'ADD_BULK_GUEST_SUCCESS';
export const ADD_BULK_GUEST_FAILURE = 'ADD_BULK_GUEST_FAILURE';
//
// export const FINALIZE_AGREEMENT_REQUEST = 'FINALIZE_AGREEMENT_REQUEST';
// export const FINALIZE_AGREEMENT_SUCCESS = 'FINALIZE_AGREEMENT_SUCCESS';
// export const FINALIZE_AGREEMENT_FAILURE = 'FINALIZE_AGREEMENT_FAILURE';
