import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { VStack, Icon, Text, AlertDialog, FormControl, Input, Button, Spinner, IconButton, Flex, NativeBaseProvider, Modal } from "native-base";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {GuestListBanner} from '../assets/images';
import {materialColor, brandColor, borderColor} from '../constants/colors';
// import moment from "moment";


const styles = {
  root: {
    flexDirection: "column", alignItems: 'center', justifyContent: 'center'
    //maxWidth: 360,
    //backgroundColor: "#ffffff",
  },
  loading: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    //backgroundColor: '#0000ff47',
    display:'flex'
  },
};

class LoginCredentialScreen extends React.Component {

  state = {
      textColor: '#042C5C',
      isVisible: false,
      isBVisible: false,
      username: '',
      password: '',
      confirmPassword: '',
      curPassword: '',
      show: false,
      // updateClicked: false,
      loading: false
      // logThisOut: false
    };

  componentDidMount() {
    console.log('val1 : ', this.getValue('ButtonLoginCredentialsVisible'));
    console.log('val2 : ', this.getValue('LoginCredentialsModeB'));
    if(this.getValue('ButtonLoginCredentialsVisible')) {
      if (this.getValue('LoginCredentialsModeB')) {
        this.displayModalB(true);
      } else {
        this.displayModal(true);
      }
    }
  }

  getValue(setting) {
    const {loginData} = this.props;
    if (loginData) {
      const appOptions = loginData.appOptions;
      for (let x of appOptions) {
        if (x.setting === setting) {
          return x.value;
        }
      }
    }
  }

  logMeOut = async (e) => {
    // console.log('logout',e);
    const { logOut } = this.props;
    await logOut();
    //console.log('logout');
    clearInterval(this.interval);
    //console.log('logout');
    localStorage.removeItem('@AbdiStore:loginTime');
    //console.log('logout');
    this.props.history.push({pathname: '/login', state:{logout: true}});
  }

  // hide show modal
  displayModal = (show) => {
    if (show) {
      this.getLoginInfo();
    }
    this.setState({isVisible: show});
  }

  displayModalB = (show) => {
    if (show) {
      this.getLoginInfo();
    }
    this.setState({isBVisible: show});
  }

  getLoginInfo = async () => {
    console.log('hii');
    const { getLoginInfo } = this.props;
    this.setState({loading: true});
    await getLoginInfo();
    console.log('loginInfo: ', this.props.loginInfo);
    // if (this.getValue('ButtonLoginCredentialsVisible') && this.getValue('LoginCredentialsModeB')) {
      this.setState({username: this.props.loginInfo.webUserName, loading: false});
    // } else {
    //   this.setState({username: this.props.loginInfo.webUserName, password: this.props.loginInfo.webPassword, loading: false});
    // }
  }

  loginPress = async () => {
    this.setState({loading: true});
    const {username, password, confirmPassword} = this.state;
    if (username && password && confirmPassword) {
      if (password === confirmPassword) {
        const { putLoginInfo } = this.props;
        let {response, error} = await putLoginInfo({WebUserName: username, WebPassword: password});
        console.log('ressssssss: ',response);
        if (response.status === 200) {
          if (this.getValue('ButtonLoginCredentialsVisible') && this.getValue('LoginCredentialsModeB')) {
            this.setState({loading: false});
          } else {
            this.setState({loading: false, isVisible: false});
            // this.refs.toast.show('Your Credentials were successfully saved. Please login again.');
            this.logMeOut();
          }
        } else {
          this.setState({loading: false});
        }
      } else {
        this.setState({loading: false});
        // this.refs.toast.show('Passwords does not matched!!!');
      }
    } else {
      this.setState({loading: false});
      // this.refs.toast.show('Please fill all the fields!');
    }
  }

  changePressB = async () => {
    this.setState({loading: true});
    const {curPassword} = this.state;
    if (curPassword) {
      const { putPassInfo } = this.props;
      let {response, error} = await putPassInfo({VerbalPassword: curPassword});

      if (response.status === 200) {
        this.setState({loading: false, isBVisible: false});
        // this.refs.toast.show('Your Credentials were successfully saved. Please login again.');
        this.logMeOut();
      } else {
        this.setState({loading: false});
      }
    } else {
      this.setState({loading: false});
      // this.refs.toast.show('Please fill password!');
    }
  }

  render() {

    const { classes, location: { pathname } } = this.props;
    const {textColor, loading} = this.state;

    return (
      <NativeBaseProvider>
        <VStack h={900} w={900} bg="#fff">

          <AlertDialog
            isOpen={this.state.isVisible}
            // transparent={true}
            // animationType = {"slide"}
            // visible={this.state.isVisible}
            size={'xl'}
            onClose={() => {
              this.setState({isVisible: false});
            }}>
            <AlertDialog.Content maxH="612">
              <AlertDialog.CloseButton />
              <AlertDialog.Header _text={{color: "#FFFFFF"}} style={{backgroundColor: brandColor}}>Change Login Credentials</AlertDialog.Header>
              <AlertDialog.Body>
                <FormControl>
                  <FormControl.Label> New User Name</FormControl.Label>
                  <Input
                    // variant="rounded"
                    size="lg"
                    borderColor={borderColor}
                    // style={{ color: (this.state.darkMode) ? '#ffffff' : '#000000' }}
                    onChangeText={username => this.setState({username})}
                    value={this.state.username}
                    autoCapitalize={false}
                    autoCorrect={false}
                    placeholder={'Enter Username'}
                    // placeholderTextColor={(darkMode) ? '##666' : '#C7C7CD'}
                    InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'user']} size="lg"/>} />}/>}
                    returnKeyType='next'
                    ref={(input) => { this.firstTextInput = input; }}
                    onSubmitEditing={() => { this.secondTextInput.focus(); }}/>
                </FormControl>

                <FormControl mt="3">
                  <FormControl.Label>New Password</FormControl.Label>
                  <Input
                    // variant="rounded"
                    size="lg"
                    borderColor={borderColor}
                    // style={{ color: (this.state.darkMode) ? '#ffffff' : '#000000' }}
                    type={this.state.show ? "text" : "password"}
                    InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', "lock"]} size="lg"/>} />} />}
                    InputRightElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={this.state.show ? ['fas', 'eye'] : ['fas', 'eye-slash']} size="lg"/>}
                      size={1} mr="20" color="muted.400" />} onPress={() => {this.setState({show: !this.state.show})}} />}
                    onChangeText={password => this.setState({password})}
                    value={this.state.password}
                    placeholder={'Enter Password'}
                    // placeholderTextColor={(darkMode) ? '##666' : '#C7C7CD'}
                    // secureTextEntry={true}
                    returnKeyType='next'
                    ref={(input) => { this.secondTextInput = input; }}
                    onSubmitEditing={() => { this.thirdTextInput.focus(); }}/>
                </FormControl>

                <FormControl mt="3">
                  <FormControl.Label>Confirm Password</FormControl.Label>
                  <Input
                    // variant="rounded"
                    size="lg"
                    borderColor={borderColor}
                    // style={{ color: (this.state.darkMode) ? '#ffffff' : '#000000' }}
                    type={"password"}
                    InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', "lock"]} size="lg"/>} />} />}
                    // InputRightElement={<Icon as={<MaterialIcons name={this.state.show ? "visibility" : "visibility-off"} />}
                    //   size={5} mr="2" color="muted.400" onPress={() => {this.setState({show: !this.state.show})}} />}
                    onChangeText={confirmPassword => this.setState({confirmPassword})}
                    value={this.state.confirmPassword}
                    placeholder={'Enter Password'}
                    // placeholderTextColor={(darkMode) ? '##666' : '#C7C7CD'}
                    // secureTextEntry={true}
                    returnKeyType='done'
                    ref={(input) => { this.thirdTextInput = input; }}
                  />
                </FormControl>
                <AlertDialog.Footer>
                  {/* <VStack space={3} mt="5"> */}
                  <Button flex="1" _light={{ bg: materialColor, _text: { color: "white" } }}
                    _dark={{ bg: materialColor, _text: { color: "white" } }}
                    // disabled={loading}
                    // style={{borderRadius: 20}}
                    onPress={this.loginPress}>
                    Update Login Information
                  </Button>
                  {/* </VStack> */}
                </AlertDialog.Footer>
              </AlertDialog.Body>
              {loading && (
                <div className={classes.loading}>
                  <Spinner size="lg" />
                </div>
              )}
            </AlertDialog.Content>
          </AlertDialog>

          <Modal
            isOpen={this.state.isBVisible}
            // transparent={true}
            // animationType = {"slide"}
            // visible={this.state.isBVisible}
            size={'xl'}

            onClose={() => {
              this.setState({isBVisible: false});
            }}>
            <Modal.Content maxH="642">
              <Modal.CloseButton />
              <Modal.Header _text={{color: "#FFFFFF"}} style={{backgroundColor: brandColor}}>Change Password</Modal.Header>
              <Modal.Body>

                <div style={{padding: 10}}>
                  <FormControl>
                    <FormControl.Label>Registered User Name</FormControl.Label>
                    <Text style={{ fontSize: 18 }}>{this.state.username}</Text>
                  </FormControl>

                  <FormControl mt="3">
                    <FormControl.Label>New Password</FormControl.Label>
                    <Input
                      // variant="rounded"
                      size="lg"
                      borderColor={borderColor}
                      // style={{ color: (this.state.darkMode) ? '#ffffff' : '#000000' }}
                      type={this.state.show ? "text" : "password"}
                      InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', "lock"]} size="lg"/>} />} />}
                      InputRightElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={this.state.show ? ['fas', 'eye'] : ['fas', 'eye-slash']} size="lg"/>}
                        size={1} mr="20" color="muted.400" />} onPress={() => {this.setState({show: !this.state.show})}} />}
                      onChangeText={password => this.setState({password})}
                      value={this.state.password}
                      placeholder={'Enter Password'}
                      // placeholderTextColor={(darkMode) ? '##666' : '#C7C7CD'}
                      // secureTextEntry={true}
                      returnKeyType='next'
                      ref={(input) => { this.forthTextInput = input; }}
                      onSubmitEditing={() => { this.fifthTextInput.focus(); }}/>
                  </FormControl>

                  <FormControl mt="3">
                    <FormControl.Label>Confirm Password</FormControl.Label>
                    <Input
                      // variant="rounded"
                      size="lg"
                      borderColor={borderColor}
                      // style={{ color: (this.state.darkMode) ? '#ffffff' : '#000000' }}
                      type={"password"}
                      InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', "lock"]} size="lg"/>} />} />}
                      // InputRightElement={<Icon as={<MaterialIcons name={this.state.show ? "visibility" : "visibility-off"} />}
                      //   size={5} mr="2" color="muted.400" onPress={() => {this.setState({show: !this.state.show})}} />}
                      onChangeText={confirmPassword => this.setState({confirmPassword})}
                      value={this.state.confirmPassword}
                      placeholder={'Enter Password'}
                      // placeholderTextColor={(darkMode) ? '##666' : '#C7C7CD'}
                      // secureTextEntry={true}
                      returnKeyType='done'
                      ref={(input) => { this.fifthTextInput = input; }}
                    />
                  </FormControl>

                  <VStack space={3} mt="5">
                    <Button mt="2" _light={{ bg: materialColor, _text: { color: "white" } }}
                      _dark={{ bg: materialColor, _text: { color: "white" } }}
                      // disabled={loading}
                      // style={{borderRadius: 20}}
                      onPress={this.loginPress}>
                      Update Password
                    </Button>
                  </VStack>
                </div>

                {this.getValue("AllowSecurityWord") && (
                  <VStack>
                    <div style={{flexDirection: 'row', backgroundColor: materialColor, borderColor: materialColor, borderBottomWidth: 1, padding: 10, marginTop: 10}}>
                      <Text style={{fontSize: 16, color: '#fff', fontWeight: 'bold'}}>
                      Security Verification Word</Text>
                    </div>
                    <div style={{padding: 10}}>
                      <FormControl mt="3">
                        <FormControl.Label>Current Security Verification Word</FormControl.Label>
                        <Input
                          // variant="rounded"
                          size="lg"
                          borderColor={borderColor}
                          // style={{ color: (this.state.darkMode) ? '#ffffff' : '#000000' }}
                          type={this.state.show ? "text" : "password"}
                          InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', "lock"]} size="lg"/>} />} />}
                          InputRightElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={this.state.show ? ['fas', 'eye'] : ['fas', 'eye-slash']} size="lg"/>}
                            size={1} mr="20" color="muted.400" />} onPress={() => {this.setState({show: !this.state.show})}} />}
                          onChangeText={curPassword => this.setState({curPassword})}
                          value={this.state.curPassword}
                          placeholder={'Enter Password'}
                          // placeholderTextColor={(darkMode) ? '##666' : '#C7C7CD'}
                          // secureTextEntry={true}
                          returnKeyType='next'
                          // ref={(input) => { this.forthTextInput = input; }}
                        />
                      </FormControl>
                      <VStack space={3} mt="5">
                        <Button mt="2" _light={{ bg: materialColor, _text: { color: "white" } }}
                          _dark={{ bg: materialColor, _text: { color: "white" } }}
                          // disabled={loading}
                          // style={{borderRadius: 20}}
                          onPress={this.changePressB}>
                          Change Security Verification Word
                        </Button>
                      </VStack>
                    </div>
                  </VStack>
                )}

                {loading && (
                  <div className={classes.loading}>
                    <Spinner size="lg" />
                  </div>
                )}
              </Modal.Body>
            </Modal.Content>
          </Modal>

        </VStack>
      </NativeBaseProvider>
    );
  }
}

LoginCredentialScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default compose( withRouter, withStyles(styles))(LoginCredentialScreen);
