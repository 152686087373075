/* eslint-disable */
import React from 'react';
import { Box, Heading, VStack, HStack, Spinner, Flex, FlatList, Text, Pressable, Button } from "native-base";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import moment from "moment";
import SearchBar from "material-ui-search-bar";
import {materialColor, borderBottomColor, listMainTextColor, underlayColor, searchBarBorderColor} from '../constants/colors';

const styles = {
  rowFront: {
    flexDirection: 'row',
    borderBottomColor: borderBottomColor,
    borderBottomWidth: 1,
  },
  rowFront1: {
    flexDirection: 'row',
    borderColor: borderBottomColor,
    borderWidth: 1,
  },
  loading: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    //backgroundColor: '#0000ff47',
    display:'flex'
  },
};

class ManageAuthorizedVendorsList extends React.Component {
  constructor() {
    super();
    this.arrayholder = [];
    this.state = {
      allData: [],
      value: '',
      loading: false
    };
  }

  // search = text => {
  //   const newData = this.arrayholder.filter(item => {
  //     const itemData = `${item.guest_Name.toLowerCase()}`;
  //     const textData = text.toLowerCase();
  //     return itemData.indexOf(textData) > -1;
  //   });
  //   this.setState({
  //     allData: newData,
  //   });
  // }

  async componentDidMount() {
    const {getAuthorizedVendors} = this.props;
    this.setState({loading: true});
    await getAuthorizedVendors();
    this.setState({allData: this.props.authorizedVendors, loading: false});
    this.arrayholder = this.props.authorizedVendors;
  }

  onVendorPress = async(isSelected, vendorId) => {
    this.setState({loading: true});
    let {putAuthorizedVendors} = this.props;
    await putAuthorizedVendors({"selected" : !isSelected, "vendorId" : vendorId});
    let {getAuthorizedVendors} = this.props;
    await getAuthorizedVendors();
    this.setState({allData: this.props.authorizedVendors, loading: false});
  };

  ListHeader = () => {
    //View to set in Header
    return (
      <Box flex="1" style={[styles.rowFront1, {backgroundColor: searchBarBorderColor}]} underlayColor={underlayColor} mr={2} ml={2}>
        <HStack justifyContent="space-between" flex={1}>
          <VStack justifyContent="center" w="28%" paddingHorizontal={2} paddingVertical={8}>
            <Text
              style={{
                color: listMainTextColor,
                // fontSize: 13,
                padding: 10,
                textTransform: 'capitalize',
                fontWeight: "bold"
              }}>
              Category
            </Text>
          </VStack>
          <VStack justifyContent="center" w="40%" paddingHorizontal={2} paddingVertical={8} style={{borderLeftColor: borderBottomColor, borderRightWidth: 1, borderLeftWidth: 1, borderRightColor: borderBottomColor}}>
            <Text
              style={{
                color: listMainTextColor,
                // fontSize: 13,
                padding: 10,
                textTransform: 'capitalize',
                fontWeight: "bold"
              }}>
              Vendor Name
            </Text>
          </VStack>
          <VStack justifyContent="center" w="32%" paddingHorizontal={2} paddingVertical={8}>
            {/* <Button _light={{ bg: item.selected ? materialColor : "#C3CFDD", _text: { color: "white" } }}
              _dark={{ bg: item.selected ? materialColor : "#C3CFDD", _text: { color: "white" } }}
              size={"xs"}
              style={{borderRadius: 20}}
              onPress={this.onVendorPress}
              >
              {item.selected ? "Selected" : "Not Selected"}
            </Button> */}
          </VStack>

        </HStack>
      </Box>
    );
  };


  render() {
    const {classes} = this.props;
    const {loading, allData} = this.state;
    return (
      <Box flex={1} justifyContent="center" alignItems="center">

          <FlatList
            style={{width:"100%"}}
            data={allData}
            renderItem={({item, index}) => (
              <Box flex="1" style={[styles.rowFront, {backgroundColor: 'white' }]} underlayColor={underlayColor} mr={2} ml={2}>
                <HStack justifyContent="space-between" flex={1}>
                  <VStack justifyContent="center" w="28%" paddingHorizontal={2} paddingVertical={8} style={{borderLeftColor: borderBottomColor, borderLeftWidth: 1}}>
                    <Text
                      style={{
                        color: listMainTextColor,
                        // fontSize: 13,
                        padding: 10,
                        textTransform: 'capitalize',
                      }}>
                      {item.category}
                    </Text>
                  </VStack>
                  <VStack justifyContent="center" w="40%" paddingHorizontal={2} paddingVertical={8} style={{borderLeftColor: borderBottomColor, borderRightWidth: 1, borderLeftWidth: 1, borderRightColor: borderBottomColor}}>
                    <Text
                      style={{
                        color: listMainTextColor,
                        // fontSize: 13,
                        padding: 10,
                        textTransform: 'capitalize',
                      }}>
                      {item.vendorName}
                    </Text>
                  </VStack>
                  <VStack justifyContent="center" alignItems="center" w="32%" style={{borderRightWidth: 1, borderRightColor: borderBottomColor, padding: 10}}>
                    <Button _light={{ bg: item.selected ? materialColor : borderBottomColor, _text: { color: "white" } }}
                      _dark={{ bg: item.selected ? materialColor : borderBottomColor, _text: { color: "white" } }}
                      // size={"xs"}
                      style={{borderRadius: 20, width: "50%"}}
                      onPress={()=> this.onVendorPress(item.selected, item.vendorID)}
                    >
                      {item.selected ? "Selected" : "Not Selected"}
                    </Button>
                  </VStack>

                </HStack>
              </Box>
            )}
            ListHeaderComponent={this.ListHeader}
            // keyExtractor={item => item.documentID}
          />
          <Heading _dark={{
              color: "warmGray.200"
          }} color="coolGray.600" fontWeight="medium" size="sm" m={5}>
            {this.props.history.location.state.message}
          </Heading>
        {loading && (
          <div className={classes.loading}>
            <Spinner size="lg" />
          </div>
        )}
      </Box>
    );
  }
}
ManageAuthorizedVendorsList.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(ManageAuthorizedVendorsList);
