import { connect } from 'react-redux';
// import _get from 'lodash/get';
import ContactInfoB from '../../screens/ContactInfoB';

import { getContactINFOB, editContactINFOB, getContactINFOBPhones, addContactINFOBPhones, putContactINFOBPhones, delContactINFOBPhones } from './ContactInfoB.action';

const mapStateToProps = state => ({
  contactINFOB: state.contactInfoB.contactINFOB,
  globalData: state.loginData.globalData,
  loginData: state.loginData.loginData,
});

export default connect(
  mapStateToProps,
  {
    getContactINFOB,
    editContactINFOB,
    getContactINFOBPhones,
    addContactINFOBPhones,
    putContactINFOBPhones,
    delContactINFOBPhones
  },
)(ContactInfoB);
