import { createAction } from 'redux-actions';
import { get } from '../../api/api';

import {
    GET_REGISTERED_VEHICLES_REQUEST,
    GET_REGISTERED_VEHICLES_SUCCESS,
    GET_REGISTERED_VEHICLES_FAILURE,
} from './RegisteredVehicles.action.constant'

const getRegisteredVehiclesRequest = createAction(GET_REGISTERED_VEHICLES_REQUEST);
const getRegisteredVehiclesSuccess = createAction(GET_REGISTERED_VEHICLES_SUCCESS);
const getRegisteredVehiclesFailure = createAction(GET_REGISTERED_VEHICLES_FAILURE);

export function getRegisteredVehicles() {
    return async (dispatch) => {
      dispatch(getRegisteredVehiclesRequest());
      const { error, response } = await get(
        `${'Vehicle/all'}`,
        {}
      );
      if (response) {
        if (response) {
          dispatch(getRegisteredVehiclesSuccess(response));
        } else {
          dispatch(getRegisteredVehiclesFailure(response));
        }
      }
      if (error) {
        dispatch(getRegisteredVehiclesFailure(error));
      }
    };
  }
