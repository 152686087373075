import ip, {chain} from 'icepick';
import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT_REQUEST,
} from './Login.action.constant';

const initialState = ip.freeze({
  loginStatus: null,
  loginError: null,
  loginData: null,
  globalData: null,
});

export default function loginAPI(state = initialState, action) {
  switch (action.type) {
    case LOGOUT_REQUEST: {
      return chain(state)
        .setIn(['loginError'], null)
        .setIn(['loginStatus'], null)
        .setIn(['loginData'], null)
        .setIn(['globalData'], null)
        .value();
    }

    case LOGIN_REQUEST: {
      return chain(state)
        .setIn(['loginError'], null)
        .setIn(['loginStatus'], 'started')
        .setIn(['loginData'], null)
        .setIn(['globalData'], null)
        .value();
    }

    case LOGIN_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['loginError'], null)
        .setIn(['loginStatus'], 'finished')
        .setIn(['loginData'], data.result)
        .setIn(['globalData'], data)
        .value();
    }

    case LOGIN_FAILURE: {
      return chain(state)
        .setIn(['loginError'], 'error')
        .setIn(['loginStatus'], 'failled')
        .setIn(['loginData'], null)
        .setIn(['globalData'], null)
        .value();
    }

    default:
      return state;
  }
}
