import ip, {chain} from 'icepick';
import {
  EDIT_REQUEST,
  EDIT_SUCCESS,
  EDIT_FAILURE,
  ADD_REQUEST,
  ADD_SUCCESS,
  ADD_FAILURE,
  GET_GUEST_REQUEST,
  GET_GUEST_SUCCESS,
  GET_GUEST_FAILURE,
  GET_QR_REQUEST,
  GET_QR_SUCCESS,
  GET_QR_FAILURE,
} from './AddEditGuest.action.constant';

const initialState = ip.freeze({
  editStatus: null,
  editError: null,
  editData: null,
  addStatus: null,
  addError: null,
  addData: null,
  getGuestStatus: null,
  getGuestError: null,
  getGuestData: null,
  getQrStatus: null,
  getQrError: null,
  getQrData: null,
});

export default function editAPI(state = initialState, action) {
  switch (action.type) {
    case GET_QR_REQUEST: {
      return chain(state)
        .setIn(['getQrError'], null)
        .setIn(['getQrStatus'], 'started')
        .setIn(['getQrData'], [])
        .value();
    }

    case GET_QR_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['getQrError'], null)
        .setIn(['getQrStatus'], 'finished')
        .setIn(['getQrData'], data)
        .value();
    }

    case GET_QR_FAILURE: {
      return chain(state)
        .setIn(['getQrError'], 'error')
        .setIn(['getQrStatus'], 'failled')
        .setIn(['getQrData'], [])
        .value();
    }

    case GET_GUEST_REQUEST: {
      return chain(state)
        .setIn(['getGuestError'], null)
        .setIn(['getGuestStatus'], 'started')
        .setIn(['getGuestData'], [])
        .value();
    }

    case GET_GUEST_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['getGuestError'], null)
        .setIn(['getGuestStatus'], 'finished')
        .setIn(['getGuestData'], data.result)
        .value();
    }

    case GET_GUEST_FAILURE: {
      return chain(state)
        .setIn(['getGuestError'], 'error')
        .setIn(['getGuestStatus'], 'failled')
        .setIn(['getGuestData'], [])
        .value();
    }

    case EDIT_REQUEST: {
      return chain(state)
        .setIn(['editError'], null)
        .setIn(['editStatus'], 'started')
        .setIn(['editData'], [])
        .value();
    }

    case EDIT_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['editError'], null)
        .setIn(['editStatus'], 'finished')
        .setIn(['editData'], data.result)
        .value();
    }

    case EDIT_FAILURE: {
      return chain(state)
        .setIn(['editError'], 'error')
        .setIn(['editStatus'], 'failled')
        .setIn(['editData'], [])
        .value();
    }

    case ADD_REQUEST: {
      return chain(state)
        .setIn(['addError'], null)
        .setIn(['addStatus'], 'started')
        .setIn(['addData'], [])
        .value();
    }

    case ADD_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['addError'], null)
        .setIn(['addStatus'], 'finished')
        .setIn(['addData'], data.result)
        .value();
    }

    case ADD_FAILURE: {
      return chain(state)
        .setIn(['addError'], 'error')
        .setIn(['addStatus'], 'failled')
        .setIn(['addData'], [])
        .value();
    }

    default:
      return state;
  }
}
