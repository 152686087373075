import { connect } from 'react-redux';
// import _get from 'lodash/get';
import CPSubmitDocument from '../../vendorscreens/CPSubmitDocument';

import { cpLoadCall, cpFormCall } from './CPSubmitDocument.action'

const mapStateToProps = state => ({
  loadData: state.cpSubmitDocumentAPI.loadData,
});

export default connect(
  mapStateToProps,
  {
    cpLoadCall,
    cpFormCall,
  },
)(CPSubmitDocument);
