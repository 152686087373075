import { connect } from 'react-redux';
// import _get from 'lodash/get';
import EmergencyContact from '../../screens/EmergencyContact';

import { getAllEmergencyContacts, deleteEmergencyContact } from './EmergencyContact.action';

const mapStateToProps = state => ({
  allEmmCon: state.emergencyContact.allEmergency,
});

export default connect(
  mapStateToProps,
  {
    getAllEmergencyContacts,
    deleteEmergencyContact,
  },
)(EmergencyContact);
