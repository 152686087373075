import { connect } from 'react-redux';
// import _get from 'lodash/get';
import ManageEvents from '../../vendorscreens/ManageEvents1';

import { getAllEvent, deleteEvent, addEventCall, editEventCall, getSpecificEvent, getAgreementText, finalizeAgreementCall } from './ManageEvents.action';

const mapStateToProps = state => ({
  vendorLoginData: state.vendorLoginData.vendorLoginData,
  allEvent: state.manageEvents.allEvent
});

export default connect(
  mapStateToProps,
  {
    getAllEvent,
    addEventCall,
    editEventCall,
    deleteEvent,
    getSpecificEvent,
    getAgreementText,
    finalizeAgreementCall
  },
)(ManageEvents);
