import React from 'react';
import {
  Route,
  Switch,
} from "react-router-dom";
import LayoutVendorWebAdmin from './LayoutVendorWebAdmin';
// import WebAdmin from './vendorscreens/WebAdmin';
import ManageEvents from './redux/WebAdminManageEvents/ManageEvents.container';
import ManageGuest from './redux/WebAdminManageGuests/ManageGuest.container';

export default class VendorWebAdminNavigator extends React.Component {

    render() {
        return (
          <LayoutVendorWebAdmin>
            <Switch>
              {/* <Route path="/" component={WebAdmin} /> */}
              <Route exact path="/webadmin/:id" component={ManageEvents} />
              <Route path="/webadmin/events/:id/guests" component={ManageGuest} />
            </Switch>
          </LayoutVendorWebAdmin>
        );
    }
}
