import { connect } from 'react-redux';
import VendorLogin from '../../vendorscreens/VendorLogin';

import { getVendorCommunityCodes, vendorLoginCall, saveVendorLoginPreference } from './VendorLogin.action'

const mapStateToProps = state => ({
  vendorCommunityCodes: state.vendorLoginData.vendorComunityCodes,
  vendorLoginData: state.vendorLoginData.vendorLoginData,
  vendorLoginPref: state.vendorLoginPref,
});

export default connect(
  mapStateToProps,
  {
    getVendorCommunityCodes,
    vendorLoginCall,
    saveVendorLoginPreference,
  },
)(VendorLogin);
