import {createAction} from 'redux-actions';
import {getGuestList, del} from '../../api/api';

import {
  GET_ALL_GUEST_REQUEST,
  GET_ALL_GUEST_SUCCESS,
  GET_ALL_GUEST_FAILURE,

  DELETE_GUEST_REQUEST,
  DELETE_GUEST_SUCCESS,
  DELETE_GUEST_FAILURE,
} from './ManageGuestList.action.constant';

const getAllGuestRequest = createAction(GET_ALL_GUEST_REQUEST);
const getAllGuestSuccess = createAction(GET_ALL_GUEST_SUCCESS);
const getAllGuestFailure = createAction(GET_ALL_GUEST_FAILURE);

const deleteGuestRequest = createAction(DELETE_GUEST_REQUEST);
const deleteGuestSuccess = createAction(DELETE_GUEST_SUCCESS);
const deleteGuestFailure = createAction(DELETE_GUEST_FAILURE);

export function getAllGuest(headers) {
  return async dispatch => {
    dispatch(getAllGuestRequest());
    const {error, response} = await getGuestList(`${'Guest/all'}`, {}, headers);
    //console.log('error:',error);
    //console.log("response:",response);
    if (response) {
      //if (response) {
        dispatch(getAllGuestSuccess(response));
      // } else {
      //   dispatch(getAllGuestFailure(response));
      // }
    }
    if (error) {
      dispatch(getAllGuestFailure(error));
    }
  };
}

export function deleteGuest(gid) {
  return async dispatch => {
    dispatch(deleteGuestRequest());
    const {error, response} = await del(`${'Guest/'}${gid}`, {});
    if (response) {
      if (response) {
        dispatch(deleteGuestSuccess(response));
      } else {
        dispatch(deleteGuestFailure(response));
      }
    }
    if (error) {
      dispatch(deleteGuestFailure(error));
    }
  };
}
