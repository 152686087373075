import { connect } from 'react-redux';
// import _get from 'lodash/get';
import ViewPdf from '../../screens/ViewPdf';

const mapStateToProps = state => ({
    getPdf: state.documents.getPdf,
});

export default connect(
  mapStateToProps,
  {},
)(ViewPdf);
