import {createAction} from 'redux-actions';
import {get, put, post, del} from '../../api/api';

import {
  GET_CONTACT_INFOB_REQUEST,
  GET_CONTACT_INFOB_SUCCESS,
  GET_CONTACT_INFOB_FAILURE,
  EDIT_CONTACT_INFOB_REQUEST,
  EDIT_CONTACT_INFOB_SUCCESS,
  EDIT_CONTACT_INFOB_FAILURE,
  GET_CONTACT_INFOB_PHONES_REQUEST,
  GET_CONTACT_INFOB_PHONES_SUCCESS,
  GET_CONTACT_INFOB_PHONES_FAILURE,
  ADD_CONTACT_INFOB_PHONES_REQUEST,
  ADD_CONTACT_INFOB_PHONES_SUCCESS,
  ADD_CONTACT_INFOB_PHONES_FAILURE,
  PUT_CONTACT_INFOB_PHONES_REQUEST,
  PUT_CONTACT_INFOB_PHONES_SUCCESS,
  PUT_CONTACT_INFOB_PHONES_FAILURE,
  DEL_CONTACT_INFOB_PHONES_REQUEST,
  DEL_CONTACT_INFOB_PHONES_SUCCESS,
  DEL_CONTACT_INFOB_PHONES_FAILURE,
} from './ContactInfoB.action.constant';

const getContactINFOBRequest = createAction(GET_CONTACT_INFOB_REQUEST);
const getContactINFOBSuccess = createAction(GET_CONTACT_INFOB_SUCCESS);
const getContactINFOBFailure = createAction(GET_CONTACT_INFOB_FAILURE);

const editContactINFOBRequest = createAction(EDIT_CONTACT_INFOB_REQUEST);
const editContactINFOBSuccess = createAction(EDIT_CONTACT_INFOB_SUCCESS);
const editContactINFOBFailure = createAction(EDIT_CONTACT_INFOB_FAILURE);

const getContactINFOBPhonesRequest = createAction(GET_CONTACT_INFOB_PHONES_REQUEST);
const getContactINFOBPhonesSuccess = createAction(GET_CONTACT_INFOB_PHONES_SUCCESS);
const getContactINFOBPhonesFailure = createAction(GET_CONTACT_INFOB_PHONES_FAILURE);

const addContactINFOBPhonesRequest = createAction(ADD_CONTACT_INFOB_PHONES_REQUEST);
const addContactINFOBPhonesSuccess = createAction(ADD_CONTACT_INFOB_PHONES_SUCCESS);
const addContactINFOBPhonesFailure = createAction(ADD_CONTACT_INFOB_PHONES_FAILURE);

const putContactINFOBPhonesRequest = createAction(PUT_CONTACT_INFOB_PHONES_REQUEST);
const putContactINFOBPhonesSuccess = createAction(PUT_CONTACT_INFOB_PHONES_SUCCESS);
const putContactINFOBPhonesFailure = createAction(PUT_CONTACT_INFOB_PHONES_FAILURE);

const delContactINFOBPhonesRequest = createAction(DEL_CONTACT_INFOB_PHONES_REQUEST);
const delContactINFOBPhonesSuccess = createAction(DEL_CONTACT_INFOB_PHONES_SUCCESS);
const delContactINFOBPhonesFailure = createAction(DEL_CONTACT_INFOB_PHONES_FAILURE);

export function getContactINFOB() {
  return async dispatch => {
    dispatch(getContactINFOBRequest());
    const {error, response} = await get(`${'ContactInfoSettingsModeB'}`, {});
    if (response) {
      if (response) {
        dispatch(getContactINFOBSuccess(response));
      } else {
        dispatch(getContactINFOBFailure(response));
      }
    }
    if (error) {
      dispatch(getContactINFOBFailure(error));
    }
    return {error, response};
  };
}

export function editContactINFOB(data) {
  return async dispatch => {
    dispatch(editContactINFOBRequest());
    const {error, response} = await put(`${'ContactInfoSettingsModeB'}`, data);
    if (response) {
      if (response) {
        dispatch(editContactINFOBSuccess(response));
      } else {
        dispatch(editContactINFOBFailure(response));
      }
    }
    if (error) {
      dispatch(editContactINFOBFailure(error));
    }
    return {error, response};
  };
}

export function getContactINFOBPhones() {
  return async dispatch => {
    dispatch(getContactINFOBPhonesRequest());
    const {error, response} = await get(`${'ContactInfoSettingsModeB/Phones'}`, {});
    if (response) {
      if (response) {
        dispatch(getContactINFOBPhonesSuccess(response));
      } else {
        dispatch(getContactINFOBPhonesFailure(response));
      }
    }
    if (error) {
      dispatch(getContactINFOBPhonesFailure(error));
    }
    return {error, response};
  };
}

export function addContactINFOBPhones(data) {
  return async dispatch => {
    dispatch(addContactINFOBPhonesRequest());
    const {error, response} = await post(`${'ContactInfoSettingsModeB/Phone'}`, data);
    if (response) {
      if (response) {
        dispatch(addContactINFOBPhonesSuccess(response));
      } else {
        dispatch(addContactINFOBPhonesFailure(response));
      }
    }
    if (error) {
      dispatch(addContactINFOBPhonesFailure(error));
    }
    return {error, response};
  };
}

export function putContactINFOBPhones(data) {
  return async dispatch => {
    dispatch(putContactINFOBPhonesRequest());
    const {error, response} = await put(`${'ContactInfoSettingsModeB/Phone'}`, data);
    if (response) {
      if (response) {
        dispatch(putContactINFOBPhonesSuccess(response));
      } else {
        dispatch(putContactINFOBPhonesFailure(response));
      }
    }
    if (error) {
      dispatch(putContactINFOBPhonesFailure(error));
    }
    return {error, response};
  };
}

export function delContactINFOBPhones(ID) {
  return async dispatch => {
    dispatch(delContactINFOBPhonesRequest());
    const {error, response} = await del(`${'ContactInfoSettingsModeB/Phone/'}${ID}`, {});
    if (response) {
      if (response) {
        dispatch(delContactINFOBPhonesSuccess(response));
      } else {
        dispatch(delContactINFOBPhonesFailure(response));
      }
    }
    if (error) {
      dispatch(delContactINFOBPhonesFailure(error));
    }
    return {error, response};
  };
}
