import ip, {chain} from 'icepick';
import {VENDOR_LOGIN_PREFERENCE} from './VendorLogin.action.constant';

const initialState = ip.freeze({
  vendorRememberMe: false,
  autoVendorLogin: false,
  vendorLastUserCred: null,
});

export default function vendorLoginPref(state = initialState, action) {
  switch (action.type) {
    case VENDOR_LOGIN_PREFERENCE: {
      // console.log('@reducer: vendorLoginPref');
      const data = action.payload;
      return chain(state)
        .setIn(['vendorRememberMe'], data[0])
        .setIn(['autoVendorLogin'], data[1])
        .setIn(['vendorLastUserCred'], data[2])
        .value();
    }

    default:
      return state;
  }
}
