import React from 'react';
// import Container from '@material-ui/core/Container';
// import { HOMELOGO, GA3 } from '../assets/images';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
// import { Contacts, Group, RecentActors, Notifications, ContactPhone, Pets, DriveEta, Flight, Error, Info, InsertDriveFile, ExitToApp } from '@material-ui/icons';
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
// import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import {setAuthHeaders} from '../api/api';
// import moment from "moment";
// import {materialColor} from '../constants/colors';

const styles = {
  root: {
    // display: 'flex',
    width: '100%',
    marginRight: 10
    //maxWidth: 360,
    //backgroundColor: "#ffffff",
  },
  touchLinks: {
    flexDirection: 'row',
    marginTop: 20,
    marginLeft: '5%',
    alignItems: 'center'
  },
  touchContent: {
    flexDirection: 'column',
    marginLeft: '5%',
  },
  contentHeader: {
    color: '#042C5C',
    fontSize: 16,
  },
  contentSubText: {
    color: '#77869E',
    fontSize: 13,
  },
  submit: {
    //margin: theme.spacing(3, 0, 2),
    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    backgroundColor: "#f5f5f5",
    margin: 30,
    border: 0,
    borderRadius: 3,
    boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, .2)',
    color: 'black',
    height: 40,
    padding: '0 30px',
  },
};

// const iconColor = '#006400';

class WebAdmin extends React.Component {

  state = {

    };

  componentDidMount() {
    //const {loginData} = this.props;
    //console.log('loginData: '+ JSON.stringify(loginData));
    // setAuthHeaders(this.getValue('AuthorizationToken'));
    //AppState.addEventListener('change', this._handleAppStateChange);
    // this.compareTime();
    // this.interval = setInterval(() => this.setState({ time: Date.now() }), 1000);

    // window.history.pushState(null, document.title, window.location.href);
    // window.addEventListener('popstate', function (event){
    //     window.history.pushState(null, document.title,  window.location.href);
    // });

  }

  // compareTime = async() => {
  //   var loginTime = await localStorage.getItem('@AbdiStore:loginTime');
  //   try {
  //         this.interval = setInterval(async () => {
  //             var currentTime = moment(new Date());
  //             var difference = currentTime.diff(loginTime, "minutes");
  //             //console.log('currentTime: '+currentTime+' loginTime:'+loginTime);
  //             //console.log('difference: '+difference);
  //             if (difference > 20) {
  //               alert('For your security your session has been timed out');
  //               this.logMeOut();
  //             }
  //           }, 30000);
  //         } catch(e) {
  //           console.log(e);
  //         }
  // }

  getValue(setting) {
    const {loginData} = this.props;
    if (loginData) {
      const appOptions = loginData.appOptions;
      for (let x of appOptions) {
        if (x.setting === setting) {
          return x.value;
        }
      }
    }
  }

  goToScreen = (screen, props = {}) => {
    //const {navigation} = this.props;
    //navigation.navigate(screen, props);
    this.props.history.push({pathname: screen, state: props});
  };

  logMeOut = async (e) => {
    // console.log('logout',e);
    const { logOut } = this.props;
    await logOut();
    //console.log('logout');
    // clearInterval(this.interval);
    //console.log('logout');
    // localStorage.removeItem('@AbdiStore:loginTime');
    //console.log('logout');
    //this.goToScreen('LoginScreen', {logout: true})
    this.props.history.push({pathname: '/login', state:{logout: true}});
  }

  handleListItemClick = (event, index) => {
    this.setState({selectedIndex: index});
  };

  render() {

    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Typography variant="h3" style={{margin: 10}}>
          Admin Index
        </Typography>
        <Divider />
        <Button
          // style={{backgroundColor: "#f5f5f5", margin: 20}}
          // type="submit"
          // fullWidth
          // variant="contained"
          // color="primary"
          className={classes.submit}
        >
          Admin Overview
        </Button>
      </div>
    );
  }
}

WebAdmin.propTypes = {
  classes: PropTypes.object.isRequired,
};
//export default withStyles(styles)(WebAdmin);
export default compose( withRouter, withStyles(styles))(WebAdmin);
