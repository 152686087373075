import {createAction} from 'redux-actions';
import {get, del, post, put} from '../../api/api';

import {
  GET_ALL_TRAVEL_REQUEST,
  GET_ALL_TRAVEL_SUCCESS,
  GET_ALL_TRAVEL_FAILURE,

  DELETE_TRAVEL_REQUEST,
  DELETE_TRAVEL_SUCCESS,
  DELETE_TRAVEL_FAILURE,

  ADD_TRAVEL_REQUEST,
  ADD_TRAVEL_SUCCESS,
  ADD_TRAVEL_FAILURE,

  EDIT_TRAVEL_REQUEST,
  EDIT_TRAVEL_SUCCESS,
  EDIT_TRAVEL_FAILURE,
} from './TravelInfo.action.constant';

const getAllTravelRequest = createAction(GET_ALL_TRAVEL_REQUEST);
const getAllTravelSuccess = createAction(GET_ALL_TRAVEL_SUCCESS);
const getAllTravelFailure = createAction(GET_ALL_TRAVEL_FAILURE);

const deleteTravelRequest = createAction(DELETE_TRAVEL_REQUEST);
const deleteTravelSuccess = createAction(DELETE_TRAVEL_SUCCESS);
const deleteTravelFailure = createAction(DELETE_TRAVEL_FAILURE);

const editTravelRequest = createAction(EDIT_TRAVEL_REQUEST);
const editTravelSuccess = createAction(EDIT_TRAVEL_SUCCESS);
const editTravelFailure = createAction(EDIT_TRAVEL_FAILURE);

const addTravelRequest = createAction(ADD_TRAVEL_REQUEST);
const addTravelSuccess = createAction(ADD_TRAVEL_SUCCESS);
const addTravelFailure = createAction(ADD_TRAVEL_FAILURE);

export function getAllTravel() {
  return async dispatch => {
    dispatch(getAllTravelRequest());
    const {error, response} = await get(`${'TravelReservation/all'}`, {});
    if (response) {
      if (response) {
        dispatch(getAllTravelSuccess(response));
      } else {
        dispatch(getAllTravelFailure(response));
      }
    }
    if (error) {
      dispatch(getAllTravelFailure(error));
    }
  };
}

export function deleteTravel(gid) {
  return async dispatch => {
    dispatch(deleteTravelRequest());
    const {error, response} = await del(`${'TravelReservation/'}${gid}`, {});
    if (response) {
      if (response) {
        dispatch(deleteTravelSuccess(response));
      } else {
        dispatch(deleteTravelFailure(response));
      }
    }
    if (error) {
      dispatch(deleteTravelFailure(error));
    }
  };
}

export function addTravelCall(data) {
  return async dispatch => {
    dispatch(addTravelRequest());
    const {error, response} = await post(`${'TravelReservation'}`, data);
    if (response) {
      if (response.data.result) {
        dispatch(addTravelSuccess(response));
        return {
          success: true,
          data: response.data.result,
        };
      } else {
        dispatch(addTravelFailure(response));
        return {
          success: false,
          data: response.data,
        };
      }
    }
    if (error) {
      dispatch(addTravelFailure(error));
      return {
        success: false,
        data: error,
      };
    }
  };
}

export function editTravelCall(data) {
  return async dispatch => {
    dispatch(editTravelRequest());
    const {error, response} = await put(`${'TravelReservation'}`, data);
    if (response) {
      if (response.data.result) {
        dispatch(editTravelSuccess(response));
        return {
          success: true,
          data: response.data.result,
        };
      } else {
        dispatch(editTravelFailure(response));
        return {
          success: false,
          data: response.data,
        };
      }
    }
    if (error) {
      dispatch(editTravelFailure(error));
      return {
        success: false,
        data: error,
      };
    }
  };
}