import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React from 'react';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import { NativeBaseProvider } from "native-base";
import AppNavigator from './MainNavigator';
//import ErrorBoundary from './app/components/ErrorBoundary/ErrorBoundary';
import store from './store';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import 'devextreme/dist/css/dx.light.css';

// This exports the whole icon packs for Brand and Solid.
library.add(fab, fas)

Bugsnag.start({
  apiKey: '8ae2e57fb50bf4bbcde95671d97829e9',
  plugins: [new BugsnagPluginReact(React)],
  //otherOptions: value
})

var ErrorBoundary = Bugsnag.getPlugin('react')

const {appStore, persistor} = store;
console.disableYellowBox = true;
console.error = (error) => error.apply;

export default class App extends React.Component {
  render() {
    return (
      <Provider store={appStore}>
        <PersistGate loading={null} persistor={persistor}>
          <ErrorBoundary>
            <NativeBaseProvider>
              <AppNavigator />
            </NativeBaseProvider>
          </ErrorBoundary>
        </PersistGate>
      </Provider>
    );
  }
}
