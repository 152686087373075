import React from 'react';
import Container from '@material-ui/core/Container';
// import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import {materialColor} from '../constants/colors';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from '@material-ui/core/Typography';
import packageJson from '../../package.json';
//import Link from '@material-ui/core/Link';
//import { Link as RouterLLink } from 'react-router-dom';
//import Grid from '@material-ui/core/Grid';
import {GA3} from '../assets/images';

import moment from "moment";

const styles = {
  '@global': {
    body: {
      //backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    //marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    //margin: theme.spacing(1),
    //backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: '15%'
    //marginTop: theme.spacing(1),
  },
  submit: {
    //margin: theme.spacing(3, 0, 2),
    marginTop: '15%'
  },
  formControl: {
    //margin: theme.spacing(1),
    minWidth: 120,
    marginBottom: '10%'
  },
  selectEmpty: {
    //marginTop: theme.spacing(2),
  },
  checkbox: {
    marginTop: '5%'
  },
  loading: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    //backgroundColor: '#0000ff47',
    display:'flex'
  },
};


class VendorLoginScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      community: '',
      username: '',
      password: '',
      loginType: 'WebAdmin',
      rememberCheck: false,
      autoLoginCheck: false,
      items: [],
      newItems: [],
      communityCode: null,
      uniqueId: '',
      loading: false,
      age: ''
    };
  }

  // onSelectedItemsChange = communityCode => {
  //   console.log(communityCode);
  //   this.setState({
  //     communityCode,
  //   });
  // };

  performLogin = async (data) => {
    // console.log('data: ',data);
    const {vendorLoginCall} = this.props;
    this.setState({loading: true});
    let loginSuccess = await vendorLoginCall(data);
    this.setState({loading: false});
    if (loginSuccess.success) {
      // console.log('success');
      localStorage.setItem('@AbdiStore:vendorloginTime', String(moment(new Date())));
      if (this.state.loginType === 'CommercialPortalUser') {
        this.props.history.push({pathname: '/commercialportaluser', state:  this.state.loginType});
      } else {
        this.props.history.push({pathname: '/webadmin', state:  this.state.loginType});
      }
      this.showAlert('Vendor Login success!', 'success');
    } else {
      //console.log('error');
      this.showAlert('Error! Please check and try again.', 'error');
    }
  };

  loginPress = async (e) => {
    e.preventDefault();
    var {saveVendorLoginPreference} = this.props;
    const {
      username,
      password,
      communityCode,
      loginType,
      // uniqueId,
      rememberCheck,
      autoLoginCheck,
    } = this.state;

    if (username && password && communityCode) {
      // if (username && password) {
      //this.refs.toast.show('Logging in ...');
      //let thisThat = this;
      let data = {
        // platformType: 'web',
        communityCode: communityCode,
        // communityCode: 'Demo',
        username: username,
        password: password,
        loginType:loginType,
        rememberMe: rememberCheck
        // deviceID: uniqueId,
      };
      saveVendorLoginPreference(rememberCheck, autoLoginCheck, data);
      await this.performLogin(data);
    } else {
      this.showAlert('Please fill the required fields.', 'error');
    }
  };

  checkHistory = () => {
    var {saveVendorLoginPreference} = this.props;
    const {vendorLoginPref} = this.props;
    const logOut = this.props.history.location.state?.logout;
    const {vendorRememberMe, autoVendorLogin, vendorLastUserCred} = vendorLoginPref;
    //console.log('loginPref: ',loginPref);
    if (vendorRememberMe) {
      const {username, password, communityCode} = vendorLastUserCred;
      this.setState({
        username,
        password,
        communityCode,
        rememberCheck: vendorRememberMe,
        autoLoginCheck: autoVendorLogin,
      });
    }
    if(autoVendorLogin){
      this.setState({
        autoLoginCheck: autoVendorLogin,
      })
    }
    if (logOut) {
      this.setState({
        autoLoginCheck: false,
      })
      saveVendorLoginPreference(vendorRememberMe, false, vendorLastUserCred);
    }
    if(vendorRememberMe && autoVendorLogin && !logOut){
      //console.log("here: "+autoLogin);
      this.performLogin(vendorLastUserCred);
    }
  };

  showAlert = (message, severity) => {
    return(
      <Alert severity={severity}>{message}</Alert>
    )
  }

  // getValue = () => {
  //   const {communityCodes} = this.props;
  //     for (let x of communityCodes) {
  //       if (x.communityCode === this.state.communityCode) {
  //         return x.customMessage;
  //       }
  //     }
  // }

  // loginInfo = async() => {
  //   let message = await this.getValue();
  //   alert(message);
  // }

  callGetCommunityCodeApi = async () => {
    const {getVendorCommunityCodes} = this.props;
    this.setState({loading: true});
    const {response} = await getVendorCommunityCodes();
    this.setState({loading: false});
    if (response) {
      this.navigateAsNeeded();
    } else {
      this.callGetCommunityCodeApi();
    }
  };

  navigateAsNeeded = () => {
    const {vendorLoginData, vendorCommunityCodes, vendorLoginPref} = this.props;
    const { autoVendorLogin} = vendorLoginPref;
    // console.log('vendorLoginData: ',vendorLoginData);
    // console.log('vendorCommunityCodes: ',vendorCommunityCodes);
    if (vendorLoginData && autoVendorLogin) {
      // this.props.history.push({pathname: '/dashboard'});
      this.props.history.push({pathname: '/webadmin'});
    }
    // const {vendorCommunityCodes} = this.props;
    // this.props.history.replace({pathname: '/vendorlogin', state:{logout: false}});
    let newArr = [];
    let newItems = [];
    //console.log('bavsgd: ',this.props.history.location.state.logout);
    for (let x in vendorCommunityCodes) {
      let newItem = {
        label: vendorCommunityCodes[x].communityCode,
        value: vendorCommunityCodes[x].communityCode,
        msg: vendorCommunityCodes[x].customMessage
      };
      newArr.push(newItem);
      newItems.push(vendorCommunityCodes[x].communityCode);
    }
    this.setState({items: newArr, newItems: newItems});

     this.checkHistory();
    // if (rememberMe && !autoLogin) {
    //   this.props.history.push({pathname: '/login', state:{logout: false}});
    //   //navigation.navigate('LoginScreen', {logout: false});
    // }
    // if (!rememberMe && !autoLogin) {
    //   this.props.history.push({pathname: '/login', state:{logout: false}});
    //   //navigation.navigate('LoginScreen', {logout: false});
    // }
    // if (communityCodes && !loginData) {
    //   //navigation.navigate('Onboarding');
    //   this.props.history.push({pathname: '/login', state:{logout: false}});
    //   //navigation.navigate('LoginScreen', {logout: false});
    // }
  };

  componentDidMount() {
    this.callGetCommunityCodeApi();
    const queryString = window.location.search;
    console.log("queryString: ",queryString);
    const urlParams = new URLSearchParams(queryString);
    const loginType = urlParams.get('logintype')
    console.log("loginType: ",loginType);
    if (loginType != null && loginType !== '') {
      // console.log('if executed');
      this.setState({loginType: loginType});
    }
    // const {vendorCommunityCodes} = this.props;
    // let newArr = [];
    // let newItems = [];
    // //console.log('bavsgd: ',this.props.history.location.state.logout);
    // for (let x in vendorCommunityCodes) {
    //   let newItem = {
    //     label: vendorCommunityCodes[x].communityCode,
    //     value: vendorCommunityCodes[x].communityCode,
    //     msg: vendorCommunityCodes[x].customMessage
    //   };
    //   newArr.push(newItem);
    //   newItems.push(vendorCommunityCodes[x].communityCode);
    // }
    // this.setState({items: newArr, newItems: newItems});
    //
    //  this.checkHistory();

    //  window.history.pushState(null, document.title, window.location.href);
    //  window.addEventListener('popstate', function (event){
    //      window.history.pushState(null, document.title,  window.location.href);
    //  });
  }

  handleChange = (e) => {
    //console.log(e.target.name);
    //console.log(e.target.value);
    this.setState(Object.assign(this.state, { [e.target.name]: e.target.value }))
  }

  handleChangePicker = name => event => {
    //console.log(name);
    //console.log(event.target.value);
    this.setState({
      ...this.state,
      [name]: event.target.value,
    });
  };

  Copyright = () => {
    return (
      <Typography
        // className={classes.copyright}
        style={{position: 'absolute', bottom: 10, left: 0, right: 0}}
        variant="body2"
        color="textSecondary"
        align="center"
      >
        {"© Copyright "}
        {" "}
        {new Date().getFullYear()}
        {" APPLICATIONS BY DESIGN, INC.-All Rights Reserved / v"}
        {packageJson.version}
        {"."}
      </Typography>
    );
  }

  render() {
    //const classes = useStyles();
    const {
      // items,
      newItems,
      //loading,
      rememberCheck,
      // autoLoginCheck,
      communityCode,
      loading
    } = this.state;
    const { classes } = this.props;

    return (
      <Container component="main" maxWidth="xs">
        {/* <CssBaseline /> */}
        <div className={classes.paper}>
          {/* <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
            Sign in
          </Typography> */}
          <div style={{display: 'flex', marginTop: '10%', alignItems: 'center', justifyContent: 'center'}}>
            <img
              style={{width: window.innerWidth * (40 / 100), resizeMode: 'contain', margin: 'auto', alignSelf: 'center'}}
              src={GA3}
              alt={'vendorlogin'}
            />
          </div>
          <form className={classes.form} onSubmit={this.loginPress}>
            <FormControl className={classes.formControl}>
              {/* <InputLabel style={{width: '85vh'}} htmlFor="age-native-simple">Community Code</InputLabel> */}
              {/* <Select
                native
                value={communityCode}
                onChange={this.handleChangePicker('communityCode')}
                inputProps={{
                  name: 'communityCode',
                  id: 'age-native-simple',
                }}
                >

                {items.map((item, index) => {
                  return <option value={item.value} key={index}>{item.label}</option>
                })}

              </Select> */}
              {/* <div>{`value: ${communityCode !== null ? `'${communityCode}'` : 'null'}`}</div> */}

              <Autocomplete
                id="combo-box-demo"
                value={communityCode}
                onChange={(event, newValue) => {
                  //setValue(newValue);
                  // console.log('newValue: ',newValue);
                  // console.log('event: ',event);
                  if (newValue != null) {
                    this.setState({
                      ...this.state,
                      communityCode: newValue,
                    });
                  }
                }}
                options={newItems}
                //getOptionLabel={(option) => option.label}
                style={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Community Code" variant="outlined" />}
              />
            </FormControl>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              value={this.state.username}
              onChange={this.handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={this.state.password}
              onChange={this.handleChange}
            />
            <div className={classes.checkbox}>
              <FormControlLabel
                control={
                  <Checkbox
                    //value="remember"
                    color="primary"
                    checked={rememberCheck}
                    onChange={() => {
                      this.setState({
                        rememberCheck: !rememberCheck,
                      });
                    }} />
                }
                label="Remember me"
              />
            </div>
            {/* <div>
              <FormControlLabel
                control={
              <Checkbox
              //value="remember"
              color="primary"
              checked={autoLoginCheck}
              onChange={() => {
              this.setState({
              autoLoginCheck: !autoLoginCheck,
              });
              }} />
                }
                label="Auto Login"
              />
            </div> */}
            <Button
              disabled={loading}
              style={{backgroundColor: materialColor}}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
          </form>
          {loading && (
            <div className={classes.loading}>
              <CircularProgress />
            </div>
          )}
        </div>
        {this.Copyright()}
        {/* <Box mt={8}>
          <Copyright />
        </Box> */}
      </Container>
    );
  }
}

VendorLoginScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(VendorLoginScreen);
