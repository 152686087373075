import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { Heading, VStack, FormControl, Flex, Spinner, Button, HStack, Icon, Pressable, IconButton, Input, Text } from "native-base";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';
import moment from "moment";
import {TravelInfoBanner} from '../assets/images';
import {materialColor, listMainTextColor, underlayColor, searchBarBorderColor, borderBottomColor, brandColor, headerColor, borderColor, titleTextColor, lightGreen} from '../constants/colors';
import Snackbar from '@material-ui/core/Snackbar';

const styles = {
  paper: {
    flex: 1,
    // padding: 5
  },
  fab: {
    position: 'absolute',
    bottom: 30,
    right: 40,
  },
  loading: {
    position: 'absolute',
    left: 420,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    //backgroundColor: '#0000ff47',
    display:'flex'
  },
};

class AddEditTravel extends React.Component {
  constructor() {
    super();
    this.state = {
      isNew: true,
      vacationID: '',
      leave_date: '',
      return_date: '',
      notes: '',
      loading: false,
      darkMode: false,
      textColor: '#000000',
      message: '',
      messageType: '',
      alertOpen: false
    };
  }

  componentDidMount() {
    //const {navigation} = this.props;
    const allData = this.props.history.location.state.data;
    // console.log('@AddEditTravel.');
    // console.log(allData);

    const thisComp = this;
    if (allData) {
      // res = str.split('T')
      thisComp.setState({
        isNew: false,
        // leave_date: displayFormatDate(new Date(allData.leave_date.split('T')[0])),
        // return_date: displayFormatDate(new Date(allData.return_date.split('T')[0])),
        leave_date: allData.leave_date,
        return_date: allData.return_date,
        vacationID: allData.vacationID,
        notes: allData.notes,
      });
    }
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({alertOpen: false});
  };

  showAlert = (message, severity) => {
    // console.log('showing alert');
    const { classes } = this.props;
    return(
      <div className={classes.fab} style={{position: 'fixed'}}>
        <Snackbar open={this.state.alertOpen} autoHideDuration={3000} onClose={this.handleClose}>
          <Alert
            variant="filled"
            severity={severity}
          >
            {message}
          </Alert>
        </Snackbar>
      </div>
    )
  }

  onSubmit=()=> {
   // this.setState({ leave_date: startDate.format("YYYY-MM-DD"), return_date: untilDate.format("YYYY-MM-DD") })

    const {isNew, untilDate, startDate, vacationID, notes} = this.state;

    if ((startDate === '')||(startDate==null)){
      this.setState({alertOpen: true, message: 'Select Leave Date', messageType: 'error'});
      return false;
    }
    if ((untilDate === '')||(untilDate==null)) {
      this.setState({alertOpen: true, message: 'Select Return Date', messageType: 'error'});
      return false;
    }

    let saveData = {
      // leave_date: apiFormatDate(leave_date)+'T00:00:00',
      // return_date: apiFormatDate(return_date)+'T00:00:00',
      leave_date: new Date(startDate),
      return_date: new Date(untilDate),
      notes,
    };
    if (!isNew) {
      saveData.vacationID = vacationID;
    }
    this.saveEdit(saveData);
  }

  saveEdit = async savedata => {
    this.setState({loading: true});
    // console.log(savedata);
    const {getAllTravel, addTravelCall, editTravelCall} = this.props;
    if (savedata.vacationID) {
      await editTravelCall(savedata);
    } else {
      await addTravelCall(savedata);
    }
    await getAllTravel();
    this.setState({loading: false});
    this.props.history.goBack();
  };

  handleChange = (e) => {
    // console.log(e.target.name);
    // console.log(e.target.value);
    this.setState(Object.assign(this.state, { [e.target.name]: e.target.value }))
  }

  render() {
    const {leave_date, return_date, notes, loading, message, messageType} = this.state;
    let {classes} = this.props;
    let today = new Date();
    let maxDate = new Date();
    // let today_date = today.getFullYear() +'-'+ (today.getMonth() + 1) +'-'+today.getDate();
    maxDate.setDate(today.getDate()+365);
    // console.log('maxDate: '+moment(leave_date).unix()+' today: '+moment(return_date.split('T')[0], "YYYY-MM-DD").format("x")+" "+moment());
    if(leave_date !== ''){
      today = leave_date;
    }

    return (
      <div className={classes.paper}>
        <img
          style={{width: window.innerWidth, resizeMode: 'contain', margin: 'auto', alignSelf: 'center'}}
          src={TravelInfoBanner}
          alt={'TravelInfoBanner'}
        />
        <div style={{backgroundColor: headerColor, padding: 20, marginTop: 5, borderRadius: 10, zIndex: 10, elevation: 10, marginBottom: 20}}>
          <VStack style={{borderWidth: 1, borderRadius: 5, borderColor: borderColor, backgroundColor: 'white', marginTop: 15}}>
            {this.showAlert(message, messageType)}
            <VStack space={3} w='97%' alignItems='center' justifyContent='center' p={5}>
              <FormControl>
                <FormControl.Label>
                  Notes
                </FormControl.Label>
                <Input
                  // variant="rounded"
                  borderColor={borderColor}
                  size="lg"
                  onChangeText={notes => this.setState({notes})}
                  value={notes}
                  autoCapitalize={false}
                  autoCorrect={false}
                  placeholder={'Notes'}
                  InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'book']} size="lg"/>} />}/>}
                  returnKeyType='done'
                />
              </FormControl>

              <HStack space={2} m={6} w='100%' mb={1} mt={4} zIndex={10}>
                <Heading _dark={{
                  color: titleTextColor
                }} color={titleTextColor} fontWeight="medium" size="sm" alignSelf="center">
                  Select Leave Date and Return Date
                </Heading>
                <DateRangePicker
                  //startDate={sDate}
                  //endDate={''}
                  startDate={(leave_date !== '') ? moment(leave_date.split('T')[0], 'YYYY-MM-DD') : null} // momentPropTypes.momentObj or null,
                  startDateId="startDate" // PropTypes.string.isRequired,
                  endDate={(return_date !== '') ? moment(return_date.split('T')[0], 'YYYY-MM-DD') : null} // momentPropTypes.momentObj or null,
                  endDateId="endDate" // PropTypes.string.isRequired,
                  onDatesChange={({ startDate, endDate }) => {
                    // console.log('startDate: '+startDate+' untilDate: '+endDate);
                    this.setState({startDate, untilDate: endDate, leave_date: (startDate !== null) ? startDate.format("YYYY-MM-DD") : '', return_date: (endDate !== null) ? endDate.format("YYYY-MM-DD") : '' })
                  }} // PropTypes.func.isRequired,
                  focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                  onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                />
              </HStack>

              <HStack space={3} m={6} w='95%' justifyContent='flex-end' mb={5} mt={5}>
                <Pressable
                  style={{borderRadius: 5, width: '25%', justifyContent: 'center', alignItems: 'center', background: 'linear-gradient(180deg, #0B7645 30%, #042B1C 90%)'}}
                  onPress={() => this.onSubmit()}
                  disabled={loading}
                  underlayColor={underlayColor}>
                  <HStack space={3} justifyContent="space-between" alignItems="center" p="4">
                    <Icon as={<FontAwesomeIcon icon={['fas', 'user-plus']} size="lg" color={"white"}/>}/>
                    <Heading _dark={{
                      color: "white"
                    }} color="white" fontWeight="medium" size="sm">
                      Save
                    </Heading>
                  </HStack>
                </Pressable>
                <Pressable
                  style={{borderRadius: 5, width: '25%', justifyContent: 'center', alignItems: 'center', background: '#ED1C24'}}
                  onPress={()=>{this.props.history.goBack()}}
                  disabled={loading}
                  underlayColor={underlayColor}>
                  <Heading _dark={{
                      color: "white"
                  }} color="white" fontWeight="medium" size="sm">
                    Cancel
                  </Heading>
                </Pressable>
              </HStack>

            </VStack>
          </VStack>
        </div>
        {loading && (
          <div className={classes.loading}>
            <Spinner size="lg" />
          </div>
        )}
      </div>
    );
  }
}

AddEditTravel.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(AddEditTravel);
