export const VENDOR_LOGIN_REQUEST = 'VENDOR_LOGIN_REQUEST';
export const VENDOR_LOGIN_SUCCESS = 'VENDOR_LOGIN_SUCCESS';
export const VENDOR_LOGIN_FAILURE = 'VENDOR_LOGIN_FAILURE';
export const VENDOR_LOGOUT_REQUEST = 'VENDOR_LOGOUT_REQUEST';
export const VENDOR_LOGIN_PREFERENCE = 'VENDOR_LOGIN_PREFERENCE';

export const VENDOR_COMMUNITY_CODES = 'VENDOR_COMMUNITY_CODES';

export const GET_VENDOR_COMMUNITY_CODES_REQUEST = 'GET_VENDOR_COMMUNITY_CODES_REQUEST';
export const GET_VENDOR_COMMUNITY_CODES_SUCCESS = 'GET_VENDOR_COMMUNITY_CODES_SUCCESS';
export const GET_VENDOR_COMMUNITY_CODES_FAILURE = 'GET_VENDOR_COMMUNITY_CODES_FAILURE';
