import {createAction} from 'redux-actions';
import {get} from '../../api/api';

import {
  GET_DOCUMENTS_REQUEST,
  GET_DOCUMENTS_SUCCESS,
  GET_DOCUMENTS_FAILURE,
  GET_PDF_REQUEST,
  GET_PDF_SUCCESS,
  GET_PDF_FAILURE,
} from './Documents.action.constant';

const getDocumentsRequest = createAction(GET_DOCUMENTS_REQUEST);
const getDocumentsSuccess = createAction(GET_DOCUMENTS_SUCCESS);
const getDocumentsFailure = createAction(GET_DOCUMENTS_FAILURE);

const getPdfRequest = createAction(GET_PDF_REQUEST);
const getPdfSuccess = createAction(GET_PDF_SUCCESS);
const getPdfFailure = createAction(GET_PDF_FAILURE);

export function getDocuments() {
  return async dispatch => {
    dispatch(getDocumentsRequest());
    const {error, response} = await get(`${'CommunityDocument/all'}`, {});
    if (response) {
      if (response) {
        dispatch(getDocumentsSuccess(response));
      } else {
        dispatch(getDocumentsFailure(response));
      }
    }
    if (error) {
      dispatch(getDocumentsFailure(error));
    }
    return {error, response};
  };
}

export function getPdf(did) {
  return async dispatch => {
    dispatch(getPdfRequest());
    const {error, response} = await get(`${'CommunityDocument/'+did+'/pdf'}`, {});
    if (response) {
      if (response) {
        dispatch(getPdfSuccess(response));
      } else {
        dispatch(getPdfFailure(response));
      }
    }
    if (error) {
      dispatch(getPdfFailure(error));
    }
    return {error, response};
  };
}
