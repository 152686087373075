import ip, {chain} from 'icepick';
import {
    GET_AUTHORIZED_VENDORS_REQUEST,
    GET_AUTHORIZED_VENDORS_SUCCESS,
    GET_AUTHORIZED_VENDORS_FAILURE,
    PUT_AUTHORIZED_VENDORS_REQUEST,
    PUT_AUTHORIZED_VENDORS_SUCCESS,
    PUT_AUTHORIZED_VENDORS_FAILURE,
} from './AuthorizedVendors.action.constant'

const initialState = ip.freeze({
  getAuthorizedVendorsStatus: null,
  getAuthorizedVendorsError: null,
  authorizedVendors: [],
  putAuthorizedVendorsStatus: null,
  putAuthorizedVendorsError: null,
});

export default function getAuthorizedVendors(state = initialState, action) {
  switch (action.type) {
    case GET_AUTHORIZED_VENDORS_REQUEST: {
      return chain(state)
        .setIn(['getAuthorizedVendorsError'], null)
        .setIn(['getAuthorizedVendorsStatus'], 'started')
        .setIn(['authorizedVendors'], [])
        .value();
    }

    case GET_AUTHORIZED_VENDORS_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['getAuthorizedVendorsError'], null)
        .setIn(['getAuthorizedVendorsStatus'], 'finished')
        .setIn(['authorizedVendors'], data.vendors)
        .value();
    }

    case GET_AUTHORIZED_VENDORS_FAILURE: {
      return chain(state)
        .setIn(['getAuthorizedVendorsError'], 'error')
        .setIn(['getAuthorizedVendorsStatus'], 'failled')
        .setIn(['authorizedVendors'], [])
        .value();
    }
    case PUT_AUTHORIZED_VENDORS_REQUEST: {
      return chain(state)
        .setIn(['putAuthorizedVendorsError'], null)
        .setIn(['putAuthorizedVendorsStatus'], 'started')
        .setIn(['authorizedVendors'], [])
        .value();
    }

    case PUT_AUTHORIZED_VENDORS_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['putAuthorizedVendorsError'], null)
        .setIn(['putAuthorizedVendorsStatus'], 'finished')
        .setIn(['authorizedVendors'], data)
        .value();
    }

    case PUT_AUTHORIZED_VENDORS_FAILURE: {
      return chain(state)
        .setIn(['putAuthorizedVendorsError'], 'error')
        .setIn(['putAuthorizedVendorsStatus'], 'failled')
        .setIn(['authorizedVendors'], [])
        .value();
    }

    default:
      return state;
  }
}
