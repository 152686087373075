import ip, {chain} from 'icepick';
import {
  GET_ALL_PET_REQUEST,
  GET_ALL_PET_SUCCESS,
  GET_ALL_PET_FAILURE,
  DELETE_PET_REQUEST,
  DELETE_PET_SUCCESS,
  DELETE_PET_FAILURE,
  ADD_PET_REQUEST,
  ADD_PET_SUCCESS,
  ADD_PET_FAILURE,
  EDIT_PET_REQUEST,
  EDIT_PET_SUCCESS,
  EDIT_PET_FAILURE,
} from './ManagePet.action.constant';

const initialState = ip.freeze({
  getAllPetStatus: null,
  getAllPetError: null,
  allPet: null,
  deletePetStatus: null,
  deletePetError: null,
  deletePet: null,
  addPetStatus: null,
  addPetError: null,
  addPet: null,
  editPetStatus: null,
  editPetError: null,
  editPet: null,
});

export default function getAllPet(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_PET_REQUEST: {
      return chain(state)
        .setIn(['getAllPetError'], null)
        .setIn(['getAllPetStatus'], 'started')
        .setIn(['allPet'], [])
        .value();
    }

    case GET_ALL_PET_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['getAllPetError'], null)
        .setIn(['getAllPetStatus'], 'finished')
        .setIn(['allPet'], data.pets)
        .value();
    }

    case GET_ALL_PET_FAILURE: {
      return chain(state)
        .setIn(['getAllPetError'], 'error')
        .setIn(['getAllPetStatus'], 'failled')
        .setIn(['allPet'], [])
        .value();
    }

    case DELETE_PET_REQUEST: {
      return chain(state)
        .setIn(['deletePetError'], null)
        .setIn(['deletePetStatus'], 'started')
        .setIn(['deletePet'], [])
        .value();
    }

    case DELETE_PET_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['deletePetError'], null)
        .setIn(['deletePetStatus'], 'finished')
        .setIn(['deletePet'], data.employees)
        .value();
    }

    case DELETE_PET_FAILURE: {
      return chain(state)
        .setIn(['deletePetError'], 'error')
        .setIn(['deletePetStatus'], 'failled')
        .setIn(['deletePet'], [])
        .value();
    }

    case EDIT_PET_REQUEST: {
      return chain(state)
        .setIn(['editPetError'], null)
        .setIn(['editPetStatus'], 'started')
        .setIn(['editPet'], [])
        .value();
    }

    case EDIT_PET_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['editPetError'], null)
        .setIn(['editPetStatus'], 'finished')
        .setIn(['editPet'], data)
        .value();
    }

    case EDIT_PET_FAILURE: {
      return chain(state)
        .setIn(['editPetError'], 'error')
        .setIn(['editPetStatus'], 'failled')
        .setIn(['editPet'], [])
        .value();
    }

    case ADD_PET_REQUEST: {
      return chain(state)
        .setIn(['addPetError'], null)
        .setIn(['addPetStatus'], 'started')
        .setIn(['addPet'], [])
        .value();
    }

    case ADD_PET_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['addPetError'], null)
        .setIn(['addPetStatus'], 'finished')
        .setIn(['addPet'], data)
        .value();
    }

    case ADD_PET_FAILURE: {
      return chain(state)
        .setIn(['addPetError'], 'error')
        .setIn(['addPetStatus'], 'failled')
        .setIn(['addPet'], [])
        .value();
    }

    default:
      return state;
  }
}
