import {createAction} from 'redux-actions';
import {get, put} from '../../api/api';

import {
  GET_LOGIN_INFO_REQUEST,
  GET_LOGIN_INFO_SUCCESS,
  GET_LOGIN_INFO_FAILURE,
  PUT_LOGIN_INFO_REQUEST,
  PUT_LOGIN_INFO_SUCCESS,
  PUT_LOGIN_INFO_FAILURE,
  PUT_PASS_REQUEST,
  PUT_PASS_SUCCESS,
  PUT_PASS_FAILURE,
} from './MainMenu.action.constant';

const getLoginInfoRequest = createAction(GET_LOGIN_INFO_REQUEST);
const getLoginInfoSuccess = createAction(GET_LOGIN_INFO_SUCCESS);
const getLoginInfoFailure = createAction(GET_LOGIN_INFO_FAILURE);

const putLoginInfoRequest = createAction(PUT_LOGIN_INFO_REQUEST);
const putLoginInfoSuccess = createAction(PUT_LOGIN_INFO_SUCCESS);
const putLoginInfoFailure = createAction(PUT_LOGIN_INFO_FAILURE);

const putPassRequest = createAction(PUT_PASS_REQUEST);
const putPassSuccess = createAction(PUT_PASS_SUCCESS);
const putPassFailure = createAction(PUT_PASS_FAILURE);

export function getLoginInfo() {
  return async dispatch => {
    dispatch(getLoginInfoRequest());
    const {error, response} = await get(`${'logininformation'}`, {});
    if (response) {
      if (response) {
        dispatch(getLoginInfoSuccess(response));
      } else {
        dispatch(getLoginInfoFailure(response));
      }
    }
    if (error) {
      dispatch(getLoginInfoFailure(error));
    }
    return {error, response};
  };
}

export function putLoginInfo(data) {
  return async dispatch => {
    dispatch(putLoginInfoRequest());
    const {error, response} = await put(`${'logininformation/credentials'}`, data);
    if (response) {
      if (response) {
        dispatch(putLoginInfoSuccess(response));
      } else {
        dispatch(putLoginInfoFailure(response));
      }
    }
    if (error) {
      dispatch(putLoginInfoFailure(error));
    }
    return {error, response};
  };
}

export function putPassInfo(data) {
  return async dispatch => {
    dispatch(putPassRequest());
    const {error, response} = await put(`${'logininformation/verbalPassword'}`, data);
    if (response) {
      if (response) {
        dispatch(putPassSuccess(response));
      } else {
        dispatch(putPassFailure(response));
      }
    }
    if (error) {
      dispatch(putPassFailure(error));
    }
    return {error, response};
  };
}
