import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'typeface-roboto';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
// import fontsCSS from "@native-base/icons/FontsCSS";
//
// const style = document.createElement("style");
// style.type = "text/css";
// style.appendChild(document.createTextNode(fontsCSS));
// document.head.appendChild(style);

// ReactDOM.render(<App />, document.getElementById('root'));

ReactDOM.render(
    <BrowserRouter forceRefresh={true}>
        <App />
    </BrowserRouter>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
