import ip, {chain} from 'icepick';
import {
  GET_ALL_GUEST_REQUEST,
  GET_ALL_GUEST_SUCCESS,
  GET_ALL_GUEST_FAILURE,
  DELETE_GUEST_REQUEST,
  DELETE_GUEST_SUCCESS,
  DELETE_GUEST_FAILURE,
} from './ManageGuestList.action.constant';

const initialState = ip.freeze({
  getAllGuestStatus: null,
  getAllGuestError: null,
  allGuest: null,
  deleteGuestStatus: null,
  deleteGuestError: null,
  deleteGuest: null,
});

export default function getAllGuest(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_GUEST_REQUEST: {
      return chain(state)
        .setIn(['getAllGuestError'], null)
        .setIn(['getAllGuestStatus'], 'started')
        .setIn(['allGuest'], [])
        .value();
    }

    case GET_ALL_GUEST_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['getAllGuestError'], null)
        .setIn(['getAllGuestStatus'], 'finished')
        .setIn(['allGuest'], data.employees)
        .value();
    }

    case GET_ALL_GUEST_FAILURE: {
      return chain(state)
        .setIn(['getAllGuestError'], 'error')
        .setIn(['getAllGuestStatus'], 'failed')
        .setIn(['allGuest'], [])
        .value();
    }

    case DELETE_GUEST_REQUEST: {
      return chain(state)
        .setIn(['deleteGuestError'], null)
        .setIn(['deleteGuestStatus'], 'started')
        .setIn(['deleteGuest'], [])
        .value();
    }

    case DELETE_GUEST_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['deleteGuestError'], null)
        .setIn(['deleteGuestStatus'], 'finished')
        .setIn(['deleteGuest'], data.employees)
        .value();
    }

    case DELETE_GUEST_FAILURE: {
      return chain(state)
        .setIn(['deleteGuestError'], 'error')
        .setIn(['deleteGuestStatus'], 'failed')
        .setIn(['deleteGuest'], [])
        .value();
    }

    default:
      return state;
  }
}
