import ip, {chain} from 'icepick';
import {
  GET_ALL_GUEST_REQUEST,
  GET_ALL_GUEST_SUCCESS,
  GET_ALL_GUEST_FAILURE,
  DELETE_GUEST_REQUEST,
  DELETE_GUEST_SUCCESS,
  DELETE_GUEST_FAILURE,
  ADD_GUEST_REQUEST,
  ADD_GUEST_SUCCESS,
  ADD_GUEST_FAILURE,
  EDIT_GUEST_REQUEST,
  EDIT_GUEST_SUCCESS,
  EDIT_GUEST_FAILURE,
  GET_GUEST_REQUEST,
  GET_GUEST_SUCCESS,
  GET_GUEST_FAILURE,
  ADD_BULK_GUEST_REQUEST,
  ADD_BULK_GUEST_SUCCESS,
  ADD_BULK_GUEST_FAILURE
} from './ManageGuest.action.constant';

const initialState = ip.freeze({
  getAllGuestStatus: null,
  getAllGuestError: null,
  allGuest: null,
  deleteGuestStatus: null,
  deleteGuestError: null,
  deleteGuest: null,
  addGuestStatus: null,
  addGuestError: null,
  addGuest: null,
  editGuestStatus: null,
  editGuestError: null,
  editGuest: null,
  getGuestStatus: null,
  getGuestError: null,
  getGuest: null,
  addBulkGuestStatus: null,
  addBulkGuestError: null,
  addBulkGuest: null,
});

export default function getAllGuest(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_GUEST_REQUEST: {
      return chain(state)
        .setIn(['getAllGuestError'], null)
        .setIn(['getAllGuestStatus'], 'started')
        .setIn(['allGuest'], [])
        .value();
    }

    case GET_ALL_GUEST_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['getAllGuestError'], null)
        .setIn(['getAllGuestStatus'], 'finished')
        .setIn(['allGuest'], data.result)
        .value();
    }

    case GET_ALL_GUEST_FAILURE: {
      return chain(state)
        .setIn(['getAllGuestError'], 'error')
        .setIn(['getAllGuestStatus'], 'failled')
        .setIn(['allGuest'], [])
        .value();
    }

    case DELETE_GUEST_REQUEST: {
      return chain(state)
        .setIn(['deleteGuestError'], null)
        .setIn(['deleteGuestStatus'], 'started')
        .setIn(['deleteGuest'], [])
        .value();
    }

    case DELETE_GUEST_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['deleteGuestError'], null)
        .setIn(['deleteGuestStatus'], 'finished')
        .setIn(['deleteGuest'], data.employees)
        .value();
    }

    case DELETE_GUEST_FAILURE: {
      return chain(state)
        .setIn(['deleteGuestError'], 'error')
        .setIn(['deleteGuestStatus'], 'failled')
        .setIn(['deleteGuest'], [])
        .value();
    }

    case EDIT_GUEST_REQUEST: {
      return chain(state)
        .setIn(['editGuestError'], null)
        .setIn(['editGuestStatus'], 'started')
        .setIn(['editGuest'], [])
        .value();
    }

    case EDIT_GUEST_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['editGuestError'], null)
        .setIn(['editGuestStatus'], 'finished')
        .setIn(['editGuest'], data)
        .value();
    }

    case EDIT_GUEST_FAILURE: {
      return chain(state)
        .setIn(['editGuestError'], 'error')
        .setIn(['editGuestStatus'], 'failled')
        .setIn(['editGuest'], [])
        .value();
    }

    case ADD_GUEST_REQUEST: {
      return chain(state)
        .setIn(['addGuestError'], null)
        .setIn(['addGuestStatus'], 'started')
        .setIn(['addGuest'], [])
        .value();
    }

    case ADD_GUEST_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['addGuestError'], null)
        .setIn(['addGuestStatus'], 'finished')
        .setIn(['addGuest'], data)
        .value();
    }

    case ADD_GUEST_FAILURE: {
      return chain(state)
        .setIn(['addGuestError'], 'error')
        .setIn(['addGuestStatus'], 'failled')
        .setIn(['addGuest'], [])
        .value();
    }

    case GET_GUEST_REQUEST: {
      return chain(state)
        .setIn(['getGuestError'], null)
        .setIn(['getGuestStatus'], 'started')
        .setIn(['getGuest'], [])
        .value();
    }

    case GET_GUEST_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['getGuestError'], null)
        .setIn(['getGuestStatus'], 'finished')
        .setIn(['getGuest'], data.result)
        .value();
    }

    case GET_GUEST_FAILURE: {
      return chain(state)
        .setIn(['getGuestError'], 'error')
        .setIn(['getGuestStatus'], 'failled')
        .setIn(['getGuest'], [])
        .value();
    }

    case ADD_BULK_GUEST_REQUEST: {
      return chain(state)
        .setIn(['addBulkGuestError'], null)
        .setIn(['addBulkGuestStatus'], 'started')
        .setIn(['addBulkGuest'], [])
        .value();
    }

    case ADD_BULK_GUEST_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['addBulkGuestError'], null)
        .setIn(['addBulkGuestStatus'], 'finished')
        .setIn(['addBulkGuest'], data)
        .value();
    }

    case ADD_BULK_GUEST_FAILURE: {
      return chain(state)
        .setIn(['addBulkGuestError'], 'error')
        .setIn(['addBulkGuestStatus'], 'failled')
        .setIn(['addBulkGuest'], [])
        .value();
    }

    default:
      return state;
  }
}
