import ip, {chain} from 'icepick';
import {
  GET_LOGIN_INFO_REQUEST,
  GET_LOGIN_INFO_SUCCESS,
  GET_LOGIN_INFO_FAILURE,
  PUT_LOGIN_INFO_REQUEST,
  PUT_LOGIN_INFO_SUCCESS,
  PUT_LOGIN_INFO_FAILURE,
  PUT_PASS_REQUEST,
  PUT_PASS_SUCCESS,
  PUT_PASS_FAILURE,
} from './MainMenu.action.constant';

const initialState = ip.freeze({
  getLoginInfoStatus: null,
  getLoginInfoError: null,
  loginInfo: null,
  putLoginInfoStatus: null,
  putLoginInfoError: null,
  putLoginInfo: null,
  putPassInfoStatus: null,
  putPassInfoError: null,
  putPassInfo: null,
});

export default function getContactInfo(state = initialState, action) {
  switch (action.type) {
    case GET_LOGIN_INFO_REQUEST: {
      return chain(state)
        .setIn(['getLoginInfoError'], null)
        .setIn(['getLoginInfoStatus'], 'started')
        .setIn(['loginInfo'], [])
        .value();
    }

    case GET_LOGIN_INFO_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['getLoginInfoError'], null)
        .setIn(['getLoginInfoStatus'], 'finished')
        .setIn(['loginInfo'], data.result[0])
        .value();
    }

    case GET_LOGIN_INFO_FAILURE: {
      return chain(state)
        .setIn(['getLoginInfoError'], 'error')
        .setIn(['getLoginInfoStatus'], 'failled')
        .setIn(['loginInfo'], [])
        .value();
    }

    case PUT_LOGIN_INFO_REQUEST: {
      return chain(state)
        .setIn(['putLoginInfoError'], null)
        .setIn(['putLoginInfoStatus'], 'started')
        .setIn(['putLoginInfo'], [])
        .value();
    }

    case PUT_LOGIN_INFO_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['putLoginInfoError'], null)
        .setIn(['putLoginInfoStatus'], 'finished')
        .setIn(['putLoginInfo'], data)
        .value();
    }

    case PUT_LOGIN_INFO_FAILURE: {
      return chain(state)
        .setIn(['putLoginInfoError'], 'error')
        .setIn(['putLoginInfoStatus'], 'failled')
        .setIn(['putLoginInfo'], [])
        .value();
    }

    case PUT_PASS_REQUEST: {
      return chain(state)
        .setIn(['putPassInfoError'], null)
        .setIn(['putPassInfoStatus'], 'started')
        .setIn(['putPassInfo'], [])
        .value();
    }

    case PUT_PASS_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['putPassInfoError'], null)
        .setIn(['putPassInfoStatus'], 'finished')
        .setIn(['putPassInfo'], data)
        .value();
    }

    case PUT_PASS_FAILURE: {
      return chain(state)
        .setIn(['putPassInfoError'], 'error')
        .setIn(['putPassInfoStatus'], 'failled')
        .setIn(['putPassInfo'], [])
        .value();
    }

    default:
      return state;
  }
}
