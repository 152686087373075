import { createAction } from 'redux-actions';
import { get } from '../../api/api';

import {
    GET_RECENT_VISITORS_REQUEST,
    GET_RECENT_VISITORS_SUCCESS,
    GET_RECENT_VISITORS_FAILURE,
} from './RecentVisitors.action.constant'

const getRecentVisitorsRequest = createAction(GET_RECENT_VISITORS_REQUEST);
const getRecentVisitorsSuccess = createAction(GET_RECENT_VISITORS_SUCCESS);
const getRecentVisitorsFailure = createAction(GET_RECENT_VISITORS_FAILURE);

export function getRecentVisitors() {
    return async (dispatch) => {
      dispatch(getRecentVisitorsRequest());
      const { error, response } = await get(
        `${'VisitorEntries'}`,
        {}
      );
      if (response) {
        if (response) {
          dispatch(getRecentVisitorsSuccess(response));
        } else {
          dispatch(getRecentVisitorsFailure(response));
        }
      }
      if (error) {
        dispatch(getRecentVisitorsFailure(error));
      }
    };
  }