import {createAction} from 'redux-actions';
import {post} from '../../api/api';

import {
  ADD_REQUEST,
  ADD_SUCCESS,
  ADD_FAILURE,
  // GET_GUEST_REQUEST,
  // GET_GUEST_SUCCESS,
  // GET_GUEST_FAILURE,
} from './AddEditMultipleGuests.action.constant';

const addRequest = createAction(ADD_REQUEST);
const addSuccess = createAction(ADD_SUCCESS);
const addFailure = createAction(ADD_FAILURE);

// const getGuestRequest = createAction(GET_GUEST_REQUEST);
// const getGuestSuccess = createAction(GET_GUEST_SUCCESS);
// const getGuestFailure = createAction(GET_GUEST_FAILURE);

export function addGuestCall(data) {
  return async dispatch => {
    dispatch(addRequest());
    const {error, response} = await post(`${'Guest/multiple'}`, data);
    if (response) {
      if (response.data.result) {
        dispatch(addSuccess(response));
        return {
          success: true,
          data: response.data.result,
        };
      } else {
        dispatch(addFailure(response));
        return {
          success: true,
          data: response.data,
        };
      }
    }
    if (error) {
      dispatch(addFailure(error));
      return {
        success: false,
        data: error,
      };
    }
  };
}

// export function getSpecificGuest(gid) {
//   return async dispatch => {
//     dispatch(getGuestRequest());
//     const {error, response} = await get(`${'Guest/'}${gid}`, {});
//     if (response) {
//       if (response.data.result) {
//         dispatch(getGuestSuccess(response));
//         return {
//           success: true,
//           data: response.data.result,
//         };
//       } else {
//         dispatch(getGuestFailure(response));
//         return {
//           success: true,
//           data: response.data,
//         };
//       }
//     }
//     if (error) {
//       dispatch(getGuestFailure(error));
//       return {
//         success: false,
//         data: error,
//       };
//     }
//   };
// }
