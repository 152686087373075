import { connect } from 'react-redux';
// import _get from 'lodash/get';
import AddEditEmergencyContact from '../../screens/AddEditEmergencyContact';

import { getAllEmergencyContacts, deleteEmergencyContact, addEmergencyCall, editEmergencyCall,  } from './EmergencyContact.action';

const mapStateToProps = state => ({
  allEmmCon: state.emergencyContact.allEmergency,
});

export default connect(
  mapStateToProps,
  {
    getAllEmergencyContacts,
    deleteEmergencyContact,
    addEmergencyCall,
    editEmergencyCall,
  },
)(AddEditEmergencyContact);
