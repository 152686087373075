import React from 'react';
import Alert from '@material-ui/lab/Alert';
import ReCAPTCHA from "react-google-recaptcha";
import {
  AppBar, Toolbar, IconButton
} from '@material-ui/core';
import InputMask from 'react-input-mask';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {materialColor,primaryColor} from '../constants/colors';
import { GA3 } from '../assets/images';
// import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
// import Checkbox from '@material-ui/core/Checkbox';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import Snackbar from '@material-ui/core/Snackbar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import packageJson from '../../package.json';

const styles = {
  '@global': {
    body: {
      //backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    //marginTop: theme.spacing(8),
    // display: 'flex',
    flexDirection: 'column',
    marginTop: '3%'
    // alignItems: 'center',
  },
  fab: {
    position: 'absolute',
    bottom: 30,
    right: 40,
    zIndex: 10,
    elevation: 10
  },
  htmlpart: {
    marginLeft: 50,
    marginRight: 60
  },
  divider: {
    height: 1,
    backgroundColor: "#cbcbcb",
    width: '100%',
    marginTop: '3%',
    marginBottom: '3%'
  },
  imageView: {
    //marginVertical: 5,
    //alignSelf: 'auto',
    paddingVertical: 10,
    paddingHorizontal: 10,
    width: 90,
    height: 90,
  },
  noImageView: {
    width: 80,
    height: 80,
    paddingVertical: 10,
    paddingHorizontal: 10,
    backgroundColor: '#C3CFDD',
    alignSelf: 'center',
  },
  avatar: {
    //margin: theme.spacing(1),
    //backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: '15%'
    //marginTop: theme.spacing(1),
  },
  submit: {
    //margin: theme.spacing(3, 0, 2),
    width: '20%',
    marginLeft: 50,
    marginTop: '3%',
    marginBottom: '5%'
  },
  formControl: {
    //margin: theme.spacing(1),
    minWidth: 120,
    marginBottom: '10%'
  },
  selectEmpty: {
    //marginTop: theme.spacing(2),
  },
  checkbox: {
    marginTop: '5%'
  },
  loading: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    //backgroundColor: '#0000ff47',
    display:'flex'
  },
};


class CPSubmitDocument extends React.Component {

  postedJson = []

    constructor(props) {
      super(props);
      this.state = {
        formData: {},
        questions: [],
        disabled: true,
        loading: false,
        linkId: null,
        message: '',
        messageType: '',
        alertOpen: false,
        openDialog: false
        // age: ''
      };
      this.recaptchaRef = React.createRef();
    }

    showAlert = (message, severity) => {
      return(
        <Alert severity={severity}>{message}</Alert>
      )
    }

    componentDidMount = async() => {
      const queryString = window.location.search;
      // console.log("queryString: ",queryString);
      const urlParams = new URLSearchParams(queryString);

      let token = urlParams.get('token')
      let linkId = urlParams.get('linkid')
      // console.log('token:',token);

       if (token!=null && token !== '') {
         const {cpLoadCall, loadData} = this.props;
         let response = await cpLoadCall(token, linkId)
         // console.log('response: ',response);
         // console.log('loadData: ',loadData);
         if (!response.success) {
           this.props.history.push({pathname: '/ErrorPage'});
           return;
         }
         this.setState({formData: loadData.form, questions: loadData.questions, linkId: linkId});
         // if (response != null && response.data.message === 'Results OK') {
         //   this.form = { ...response.data.form }
         //   this.questions = [...response.data.questions]
         //   this.linkId = linkId
         //
         //   this.loaded = true
         // } else {
         //   // TODO: redirect?
         //   alert('You have a bad route.')
         // }
       } else {
         // TODO: redirect?
         this.props.history.push({pathname: '/ErrorPage'});
         // alert('You have a bad route.')
       }
    }
    // createdApi = async (token, linkId) => {
    //   let url = `/CommercialPortal/Index/Load/?token=${token}&linkid=${linkId}`
    //   let response = null
    //   try {
    //     response = await this.$axiosAuth.get(url)
    //   } catch (err) {
    //   }
    //   return response
    // }

    Copyright = () => {
      return (
        <Typography
          // className={classes.copyright}
          style={{position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: "#cbcbcb", padding: 5}}
          variant="body2"
          color="textSecondary"
          align="center"
        >
          {"© Copyright "}
          {" "}
          {new Date().getFullYear()}
          {" APPLICATIONS BY DESIGN, INC.-All Rights Reserved / v"}
          {packageJson.version}
          {"."}
        </Typography>
      );
    }

    handleChange = (e, question) => {
      //console.log(e.target.name);
      //console.log(e.target.value);
      this.setState(Object.assign(this.state, { [e.target.name]: e.target.value }));
      // console.log('state: ',this.state);
      // if (this.postedJson.length>0) {
      //   this.postedJson.pop();
      //   this.postedJson.push(e.target.value);
      // } else {
      //   this.postedJson.push(e.target.value);
      // }
      // console.log('postedJson: ',this.postedJson);
      // this.postedJson.splice(index,1,item);
    }

    handleChangePicker = name => event => {
      //console.log(name);
      //console.log(event.target.value);
      this.setState({
        ...this.state,
        [name]: event.target.value,
      });
    };

    onCancel = (e) => {
      this.setState({photo: ''});
    }

    onImageChange = (event) => {
      if (event.target.files && event.target.files[0]) {
        let reader = new FileReader();
        reader.onloadend = () => {
          this.setState({photo: reader.result.replace('data:image/jpeg;base64,','')});
          // console.log('photo1: ',reader.result.replace('data:image/jpeg;base64,',''));
        };
        reader.readAsDataURL(event.target.files[0]);
        // console.log('photo: ',event.target.files[0]);

        // this.setState({
        //   photo: URL.createObjectURL(event.target.files[0])
        // });
      }
    }

    handleQuestionClick = (e) => {
      this.setState({openDialog: true});
    }

    handleDialogClose = (e) => {
      this.setState({openDialog: false});
    }

    getQuestionUI = (question, index) => {
      const { classes } = this.props;

      switch (question.questionTypeID) {
        case 0:
          return(
            <div>
              <Typography variant="h10" color="textSecondary">
                {question.questionTitle}
              </Typography>
              <TextField
                style={{marginTop: 10, marginBottom: 15}}
                variant="outlined"
                type={'text'}
                // margin="normal"
                size="small"
                required={!question.optional}
                fullWidth
                id={question.id}
                // label="Enter username"
                name={question.questionTitle.replace(/ /g, "_")}
                // autoComplete="username"
                // autoFocus
                value={this.state[question.questionTitle.replace(/ /g, "_")]}
                onChange={(e)=> this.handleChange(e,question)}
              />
            </div>
          )
          break;
        case 1:
          return(
            <div>
              <Typography variant="h10" color="textSecondary">
                {question.questionTitle}
              </Typography>
              {/* <br></br>
                <select style={{height: 40, width: '40%', marginTop: 10, marginBottom: 15}} required={!question.optional} onChange={this.handleChange}>
                <option value={""}> Please select one </option>
                {question.answerList.map(answer => {
                  return (
                <option value={answer}> {answer} </option>
                  )
                })}
              </select> */}
              <Autocomplete
                style={{marginTop: 10, marginBottom: 15}}
                id="combo-box-demo"
                required={!question.optional}
                size="small"
                // value={question.answerList}
                onChange={(event, newValue) => {
                  this.setState(Object.assign(this.state, { [question.questionTitle.replace(/ /g, "_")]: newValue }));
                  // console.log('newValue: ',newValue);
                  // console.log('event: ',event);
                  // if (newValue != null) {
                  //   this.setState({
                  //     ...this.state,
                  //     communityCode: newValue,
                  //   });
                  // }
                }}
                options={question.answerList}
                getOptionLabel={(option) => option}
                // renderOption={(option, { selected }) => (
                //   <Typography noWrap>{option}</Typography>
                // )}
                // style={{ width: 300 }}
                renderInput={(params) => <TextField {...params} size="small" label="Please select one" variant="outlined"
                  // helperText={'Required'}
                  // error={false}
                                         />}
              />
            </div>
          )
          break;
        case 2:
          return(
            <div>
              <Typography variant="h10" color="textSecondary">
                {question.questionTitle}
              </Typography>
              <TextField
                style={{marginTop: 10, marginBottom: 15}}
                size="small"
                type={"date"}
                variant="outlined"
                // margin="normal"
                required={!question.optional}
                fullWidth
                id={question.id}
                // label="Enter username"
                name={question.questionTitle.replace(/ /g, "_")}
                // autoComplete="username"
                // autoFocus
                value={this.state[question.questionTitle.replace(/ /g, "_")]}
                onChange={(e)=> this.handleChange(e,question)}
              />
            </div>
          )
          break;
        case 4:
          return(
            <div>
              <Typography variant="h10" color="textSecondary">
                {question.questionTitle}
              </Typography>
              <IconButton color="primary" onClick={this.handleQuestionClick}>
                <HelpOutlineIcon color="action" fontSize="medium" />
              </IconButton>
              <Dialog
                open={this.state.openDialog}
                onClose={this.handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                {/* <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle> */}
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    We accept driver's licenses, state IDs, foreign passports (as long as they have a current Visa applied).
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleDialogClose} color="primary" autoFocus>
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
              <br></br>
              <br></br>
              {this.state.photo ? (
                <img
                  className={classes.imageView}
                  //style={styles.imageView}
                  id='target'
                  alt={'target'}
                  src={`data:image/jpeg;base64,${this.state.photo}`}
                />
              ) : (
                <InputBase
                  className={classes.noImageView}
                  defaultValue="No Image"
                  inputProps={{ 'aria-label': 'naked' }}
                />
              )}
              <input
                accept="image/jpeg"
                required={!question.optional}
                //className={classes.input}
                style={{marginLeft: 20}}
                id="contained-button-file"
                multiple
                type="file"
                onChange={this.onImageChange}
              />

              {/* <Button
                style={{backgroundColor: "#ccc", fontSize: 12, padding: 0}}
                type="submit"
                //fullWidth
                variant="contained"
                //color="primary"
                //className={classes.submit}
                onClick={() => this.onCancel()}
                >
                Reset
              </Button> */}
            </div>
          )
          break;
        case 8:
          return(
            <div style={{width:'25%'}}>
              <Typography variant="h10" color="textSecondary">
                {question.questionTitle}
              </Typography>
              <TextField
                style={{marginTop: 10, marginBottom: 15}}
                size="small"
                type={"number"}
                variant="outlined"
                // margin="normal"
                required={!question.optional}
                fullWidth
                id={question.id}
                // label="Enter username"
                name={question.questionTitle.replace(/ /g, "_")}
                // autoComplete="username"
                // autoFocus
                value={this.state[question.questionTitle.replace(/ /g, "_")]}
                onChange={(e)=> this.handleChange(e,question)}
              />
            </div>
          )
          break;
        case 11:
          return(
            <div>
              <Typography variant="h10" color="textSecondary">
                {question.questionTitle}
              </Typography>
              <TextField
                style={{marginTop: 10, marginBottom: 15}}
                variant="outlined"
                type={'email'}
                // margin="normal"
                size="small"
                required={!question.optional}
                fullWidth
                id={question.id}
                // label="Enter username"
                name={question.questionTitle.replace(/ /g, "_")}
                // autoComplete="username"
                // autoFocus
                value={this.state[question.questionTitle.replace(/ /g, "_")]}
                onChange={(e)=> this.handleChange(e,question)}
              />
            </div>
          )
          break;
        case 12:
          return(
            <div>
              <Typography variant="h10" color="textSecondary">
                {question.questionTitle}
              </Typography>

              <InputMask
                mask="999 999 9999"
                // value={homePhone}
                // onChange={this.handleChange}
                value={this.state[question.questionTitle.replace(/ /g, "_")]}
                onChange={(e)=> this.handleChange(e,question)}
                //className={this.props.classes.textField}
              >
                {() => <TextField
                  style={{marginTop: 10, marginBottom: 15}}
                  id={question.id}
                  // label='Home Phone'
                  name={question.questionTitle.replace(/ /g, "_")}
                  //className={this.props.classes.textField}
                  variant="outlined"
                  size="small"
                  required={!question.optional}
                  // margin="normal"
                  // required
                  fullWidth
                  //type="text"
                       />}
              </InputMask>
              {/* <TextField
                style={{marginTop: 10, marginBottom: 15}}
                variant="outlined"
                // type="tel"
                type={"number"}
                // margin="normal"
                size="small"
                required={!question.optional}
                fullWidth
                id={question.id}
                // label="Enter username"
                name={question.questionTitle.replace(/ /g, "_")}
                // autoComplete="username"
                // autoFocus
                value={this.state[question.questionTitle.replace(/ /g, "_")]}
                onChange={(e)=> this.handleChange(e,question)}
              /> */}
            </div>
          )
          break;
        case 14:
          return(
            <div>
              <Typography variant="h10" color="textSecondary">
                {question.questionTitle}
              </Typography>
              <Autocomplete
                style={{marginTop: 10, marginBottom: 15}}
                multiple
                id="tags-standard"
                required={!question.optional}
                onChange={(event, newValue) => {
                  this.setState(Object.assign(this.state, { [question.questionTitle.replace(/ /g, "_")]: newValue }));
                  // console.log('newValue: ',newValue);
                  // console.log('event: ',event);
                  // if (newValue != null) {
                  //   this.setState({
                  //     ...this.state,
                  //     communityCode: newValue,
                  //   });
                  // }
                }}
                // value={question.answerList}
                getOptionLabel={(option) => option}
                // defaultValue={[top100Films[13]]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Please select "
                    placeholder="Favorites"
                  />
                )}
              />
            </div>
          )
          break;

        default:

      }
    }

    onSubmit = async (e) => {
      e.preventDefault();
      const {formData, questions, linkId} = this.state;
      const recaptchaValue = this.recaptchaRef.current.getValue();
      let callApi = true;
      // console.log('captchaValue: ',recaptchaValue);

      questions.every((question, index) => {
        let val = '';

        if (question.questionTypeID === 4) {
          val = this.state.photo;
        } else if(question.questionTypeID === 12){
          let homePhone1 = this.state[question.questionTitle.replace(/ /g, "_")];
          // console.log('homephone1: ',homePhone1);
          if(homePhone1 !== undefined){
            homePhone1 = homePhone1.replace(/\s/g,'');
            homePhone1 = homePhone1.replace(/_/g,'');
          }
          // console.log('homephone1 optional',question.optional);
          // console.log('homephone1 title: ',question.questionTitle.replace(/ /g, "_"));
          // console.log('homephone1: ',homePhone1);
          if(homePhone1 !== undefined && homePhone1.length > 0 && homePhone1.length < 10){
            this.setState({message: 'Phone/Fax number should be of 10 digits', messageType: 'error', alertOpen: true});
            callApi = false;
            return false;
          } else {
            val = homePhone1;
            callApi = true;
          }
        }
        // else if(question.questionTypeID === 1){
        //
        // }
        else {
          val = this.state[question.questionTitle.replace(/ /g, "_")];
        }
        let postData = {
          "id":question.id,
          "answer":""+val,
          "typeId":question.questionTypeID,
          "orderNumber":question.orderNumber,
          "questionTitle":""+question.questionTitle
        }
        this.postedJson.push(postData);
          return true;
        })
        // console.log('postedJson: ',this.postedJson);
        if (callApi) {
          let data = {
            "formID": formData.id,
            "postedJSON": ""+JSON.stringify(this.postedJson),
            "token": ""+formData.usageToken,
            "linkID": linkId,
            "communityCode": ""+formData.communityCode,
            "reCaptchaToken": ""+recaptchaValue
          };
          // console.log('data: ',JSON.stringify(data));

          let response = await this.props.cpFormCall(data);
          // console.log('response: ',response);
          if (response.success) {
            this.setState({message: 'Success', messageType: 'success', alertOpen: true});
          }
        }
    }

    onChange = (value) => {
      // console.log("Captcha value:", value);
      if (value == null) {
        this.setState({disabled: true});
      } else {
        this.setState({disabled: false});
      }
    }

    showAlert = (message, severity) => {
      // console.log('showing alert');
      const { classes } = this.props;
      return(
        <div className={classes.fab} style={{position: 'fixed'}}>
          <Snackbar open={this.state.alertOpen} autoHideDuration={3000} onClose={this.handleClose}>
            <Alert
              variant="filled"
              severity={severity}
            >
              {message}
            </Alert>
          </Snackbar>
        </div>
      )
    }

    handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }

      this.setState({alertOpen: false});
    };

    render() {
      const { classes } = this.props;
      const {formData, questions, disabled, message, messageType} = this.state;
      return(
        <Container component="main">
          <AppBar position="fixed" className={classes.appBar} style={{backgroundColor: primaryColor}}>
            <Toolbar>
              {/* <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={this.handleDrawerToggle}
                className={classes.navIconHide}
                >
                <Menu />
              </IconButton> */}
              {/* <Typography variant="h6" color="inherit" noWrap>
                ABDi-GateAccess.NET
              </Typography> */}
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <img
                  style={{width: window.innerWidth * (20 / 100), resizeMode: 'contain', margin: 'auto', alignSelf: 'center'}}
                  src={GA3}
                  alt={'main bottom'}
                />
              </div>
            </Toolbar>
          </AppBar>
          {this.showAlert(message, messageType)}
          <div className={classes.paper}>
            <br/>
            <h2><b>Commercial Portal</b></h2>
            {formData && formData.documentHeaderLogoBase64 && (
              <img
                style={{width: window.innerWidth * (20 / 100), resizeMode: 'contain', margin: 'auto', marginLeft: 50}}
                src={
              `data:image/png;base64,${formData.documentHeaderLogoBase64}`
                }
                alt={'maindash'}
              />
            )}
            {formData && formData.documentHeaderHtml && (
              <div className={classes.htmlpart} dangerouslySetInnerHTML={{__html: formData.documentHeaderHtml}}/>
            )}

            <form onSubmit={this.onSubmit} >

              <div>
                { (questions) &&
                  questions.map((question, index) => {
                    return(
                      <div className={classes.htmlpart}>
                        {this.getQuestionUI(question, index)}
                      </div>
                    );
                  })
                }
              </div>
              <div className={classes.divider}></div>
              <ReCAPTCHA
                ref={this.recaptchaRef}
                className={classes.htmlpart}
                sitekey="6LfpE6AUAAAAAByr3csgE7z-5GFRDyqghKRNS1qX"
                onChange={this.onChange}
              />
              <Button
                disabled={disabled}
                style={{backgroundColor: materialColor}}
                type="submit"
                // fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Submit
              </Button>
            </form>

          </div>
          {this.Copyright()}
        </Container>
      );
    }

}

CPSubmitDocument.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CPSubmitDocument);
