import { connect } from 'react-redux';
// import _get from 'lodash/get';
import Documents from '../../screens/Documents';
import { getDocuments, getPdf } from './Documents.action';

const mapStateToProps = state => ({
    allDocuments: state.documents.allDocuments,
});

export default connect(
  mapStateToProps,
  {
    getDocuments,
    getPdf,
  },
)(Documents);
