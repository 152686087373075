import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Box, HStack, Icon, Spinner, IconButton, Fab, FlatList, Text, Pressable, VStack, Heading } from "native-base";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from "moment";
import {TravelInfoBanner} from '../assets/images';
import {materialColor, listMainTextColor, underlayColor, searchBarBorderColor, borderBottomColor, brandColor, headerColor, borderColor, deleteColor, lightGreen} from '../constants/colors';

const styles = {
  paper: {
    flex: 1,
    // padding: 5
  },
  loading: {
    position: 'absolute',
    left: 420,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    //backgroundColor: '#0000ff47',
    display:'flex'
  },
};

class TravelInfo extends React.Component {

  constructor() {
    super();

    this.state = {
      loading: false
    };
  }

  closeRow(rowMap, rowKey) {
    if (rowMap[rowKey]) {
      rowMap[rowKey].closeRow();
    }
  }

  deleteRow = async guestID => {
    this.setState({loading: true});
    const {deleteTravel, getAllTravel} = this.props;
    // console.log('@deleteRow');
    // console.log(guestID);
    await deleteTravel(guestID);
    await getAllTravel();
    this.setState({loading: false});
  };

  componentDidMount = async () => {
    this.setState({loading: true});
    const {getAllTravel} = this.props;
    await getAllTravel();
    this.setState({loading: false});
  };

  getValue(setting) {
    const {loginData} = this.props;
    if (loginData) {
      const appOptions = loginData.appOptions;
      for (let x of appOptions) {
        if (x.setting === setting) {
          return x.value;
        }
      }
    }
  }

  render() {
    let {travelData, classes} = this.props;
    let {loading} = this.state;
    return (
      <div className={classes.paper}>
        <img
          style={{width: window.innerWidth, resizeMode: 'contain', margin: 'auto', alignSelf: 'center', borderRadius: 10}}
          src={TravelInfoBanner}
          alt={'TravelInfoBanner'}
        />
        <div style={{backgroundColor: headerColor, padding: 20, marginTop: 5, borderRadius: 10, zIndex: 10, elevation: 10, marginBottom: 20}}>
          <HStack space={2} justifyContent="flex-end">
            <Pressable
              style={{borderRadius: 5, width: '20%', justifyContent: 'center', alignItems: 'center', background: 'linear-gradient(180deg, #0B7645 30%, #042B1C 90%)'}}
              onPress={() => {this.props.history.push({pathname: 'add-edit-travel', state: {data: null}});}}
              underlayColor={underlayColor}>
              <HStack space={3} justifyContent="space-between" alignItems="center" p="3">
                <Icon as={<FontAwesomeIcon icon={['fas', 'user-plus']} size="lg" color={"white"}/>}/>
                <Heading _dark={{
                  color: "white"
                }} color="white" fontWeight="medium" size="sm">
                  Add Travel Details
                </Heading>
              </HStack>
            </Pressable>
          </HStack>
          <VStack style={{borderWidth: 1, borderRadius: 5, borderColor: borderColor, backgroundColor: 'white', marginTop: 15}}>
            <HStack space={2} alignItems="center">
              <Text style={{
                  color: listMainTextColor,
                  fontSize: 16,
                  fontWeight: 'bold',
                  width: '6%',
                  padding: 7,
                  textTransform: 'capitalize',
                  borderRightColor: borderColor,
                  borderRightWidth:1,
                  alignSelf: 'center',
                  textAlign: 'center'
              }}>S.No.</Text>
              <Text
                style={{
                    color: listMainTextColor,
                    fontSize: 16,
                    fontWeight: 'bold',
                    padding: 7,
                    width: '30%',
                    textTransform: 'capitalize',
                    borderRightColor: borderColor,
                    borderRightWidth:1,
                    alignSelf: 'center',
                    // textAlign: 'center'
                }}>
                Notes
              </Text>
              <Text
                style={{
                    color: listMainTextColor,
                    fontSize: 16,
                    fontWeight: 'bold',
                    padding: 7,
                    width: '29%',
                    textTransform: 'capitalize',
                    borderRightColor: borderColor,
                    borderRightWidth:1,
                    alignSelf: 'center',
                    // textAlign: 'center'
                }}>
                Leave Date
              </Text>
              <Text
                style={{
                    color: listMainTextColor,
                    fontSize: 16,
                    fontWeight: 'bold',
                    padding: 7,
                    width: '29%',
                    textTransform: 'capitalize',
                    borderRightColor: borderColor,
                    borderRightWidth:1,
                    alignSelf: 'center',
                    // textAlign: 'center'
                }}>
                Return Date
              </Text>
              <Text
                style={{
                    color: listMainTextColor,
                    fontSize: 16,
                    fontWeight: 'bold',
                    padding: 7,
                    // width: '6%',
                    textTransform: 'capitalize',
                    // borderRightColor: borderColor,
                    // borderRightWidth:1,
                    alignSelf: 'center',
                    // textAlign: 'center'
                }}>
                Action
              </Text>
            </HStack>
            <FlatList
              style={{width:"100%", borderColor: borderColor, borderWidth: 1}}
              data={travelData}
              renderItem={({item,index}) => (
                <Pressable
                  style={{borderBottomColor: borderBottomColor, borderBottomWidth: 1}}
                  onPress={() => {this.props.history.push({pathname: 'add-edit-travel', state: {data: item}});}}
                  underlayColor={underlayColor}>
                  <Box flex="1">
                    <HStack space={2} alignItems="center">
                      <Text style={{
                          color: listMainTextColor,
                          fontSize: 16,
                          width: '6%',
                          padding: 7,
                          textTransform: 'capitalize',
                          borderRightColor: borderColor,
                          borderRightWidth:1,
                          alignSelf: 'center',
                          textAlign: 'center'
                      }}>{index+1}</Text>
                      <Text
                        style={{
                            color: listMainTextColor,
                            fontSize: 16,
                            padding: 7,
                            width: '30%',
                            textTransform: 'capitalize',
                            borderRightColor: borderColor,
                            borderRightWidth:1,
                            alignSelf: 'center',
                            // textAlign: 'center'
                        }}>
                        {item?.notes}
                      </Text>
                      <Text
                        style={{
                            color: listMainTextColor,
                            fontSize: 16,
                            padding: 7,
                            width: '29%',
                            textTransform: 'capitalize',
                            borderRightColor: borderColor,
                            borderRightWidth:1,
                            alignSelf: 'center',
                            // textAlign: 'center'
                        }}>
                        {moment(item.leave_date).format("M/D/YY")}
                      </Text>
                      <Text
                        style={{
                            color: listMainTextColor,
                            fontSize: 16,
                            padding: 7,
                            width: '29%',
                            textTransform: 'capitalize',
                            borderRightColor: borderColor,
                            borderRightWidth:1,
                            alignSelf: 'center',
                            // textAlign: 'center'
                        }}>
                        {moment(item.return_date).format("M/D/YY")}
                      </Text>
                      <VStack w='5.4%'>
                        <IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'trash']} size="lg" color={deleteColor}/>}/>} onPress={() => {this.deleteRow(item.vacationID)}} />
                      </VStack>
                    </HStack>
                  </Box>
                </Pressable>
              )}
            />
          </VStack>
          {this.getValue('VacationPageMessage') && (
            <VStack style={{marginTop: 10, backgroundColor: lightGreen, justifyContent: 'center', alignItems: 'center',marginHorizontal:10, borderRadius: 5}}>
              <Text style={{padding: 5, color: "#000000", textAlign:'center'}}>
                {this.getValue('VacationPageMessage')}
              </Text>
            </VStack>
          )}
        </div>
        {loading && (
          <div className={classes.loading}>
            <Spinner size="lg" />
          </div>
        )}
      </div>
    );
  }
}

TravelInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(TravelInfo);
