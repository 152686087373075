import {combineReducers} from 'redux';
// import storage from 'redux-persist/lib/storage';
import {reducer as reduxForm} from 'redux-form';
import appConst from '../redux/SplashScreen/SpalshScreen.reducer';
import loginData from '../redux/Login/Login.reducer';
import vendorLoginPref from '../redux/VendorLogin/VendorLoginData.reducer';
import vendorLoginData from '../redux/VendorLogin/VendorLogin.reducer';
import cpSubmitDocumentAPI from '../redux/CPSubmitDocument/CPSubmitDocument.reducer';
import loginPref from '../redux/Login/LoginData.reducer';
import manageGuestList from '../redux/ManageGuestList/ManageGuestList.reducer';
import addEditGuest from '../redux/AddEditGuest/AddEditGuest.reducer';
import addEditMultipleGuests from '../redux/AddEditMultipleGuests/AddEditMultipleGuests.reducer';
import contactInfo from '../redux/ContactInfo/ContactInfo.reducer';
import contactInfoB from '../redux/ContactInfoB/ContactInfoB.reducer';
import documents from '../redux/Documents/Documents.reducer';
import recentVisitors from '../redux/RecentVisitors/RecentVisitors.reducer';
import registeredVehicles from '../redux/RegisteredVehicles/RegisteredVehicles.reducer';
import communityInfo from '../redux/CommunityInfo/CommunityInfo.reducer';
import emergencyContact from '../redux/EmergencyContact/EmergencyContact.reducer';
import managePet from '../redux/ManagePets/ManagePet.reducer';
import travelInfo from '../redux/TravelInfo/TravelInfo.reducer';
import notificationSettings from '../redux/NotificationSettings/NotificationSettings.reducer';
import notificationSettingsB from '../redux/NotificationSettingsB/NotificationSettingsB.reducer';
import manageEvents from '../redux/WebAdminManageEvents/ManageEvents.reducer';
import manageGuest from '../redux/WebAdminManageGuests/ManageGuest.reducer';
import loginInfo from '../redux/MainMenu/MainMenu.reducer';
import authorizedVendors from '../redux/AuthorizedVendors/AuthorizedVendors.reducer';

const appReducer = combineReducers({
  reduxForm,
  appConst,
  loginData,
  manageGuestList,
  addEditGuest,
  addEditMultipleGuests,
  contactInfo,
  documents,
  communityInfo,
  recentVisitors,
  registeredVehicles,
  emergencyContact,
  managePet,
  notificationSettings,
  travelInfo,
  loginPref,
  vendorLoginPref,
  vendorLoginData,
  cpSubmitDocumentAPI,
  manageEvents,
  manageGuest,
  contactInfoB,
  notificationSettingsB,
  loginInfo,
  authorizedVendors,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
