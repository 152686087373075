import React from 'react';
import {
  // BrowserRouter as Router,
  Route,
  //useRouteMatch,
  Switch,
  // Redirect,
  //useLocation
} from "react-router-dom";
// import { withRouter } from "react-router";
import Layout from './Layout';
//import SplashScreen from './redux/SplashScreen/SplashScreen.container';
//import LoginScreen from './redux/Login/Login.container';
//import MainMenuScreen from './redux/MainMenu/MainMenu.container';
import ManageGuestList from './redux/ManageGuestList/ManageGuestList.container';
import ManageAuthorizedVendorsList from './redux/AuthorizedVendors/AuthorizedVendors.container';
import AddEditGuest from './redux/AddEditGuest/AddEditGuest.container';
import AddEditMultipleGuests from './redux/AddEditMultipleGuests/AddEditMultipleGuests.container';
import NotificationSettings from './redux/NotificationSettings/NotificationSettings.container';
import NotificationSettingsB from './redux/NotificationSettingsB/NotificationSettingsB.container';
import ContactInfo from './redux/ContactInfo/ContactInfo.container';
import ContactInfoB from './redux/ContactInfoB/ContactInfoB.container';
import ManagePets from './redux/ManagePets/ManagePet.container';
import AddEditPet  from './redux/ManagePets/AddEditPet.container';
import TravelInfo from './redux/TravelInfo/TravelInfo.container';
import AddEditTravel from './redux/TravelInfo/AddEditTravel.container';
import EmergencyContact from './redux/EmergencyContact/EmergencyContact.container';
import AddEditEmergencyContact from './redux/EmergencyContact/AddEditEmergencyContact.container';
import Document from './redux/Documents/Documents.container';
import RecentVisitors from './redux/RecentVisitors/RecentVisitors.container';
import RegisteredVehicles from './redux/RegisteredVehicles/RegisteredVehicles.container';
import RegisteredVehiclesDetails from './screens/RegisteredVehiclesDetails';
import CommunityInfo from './redux/CommunityInfo/CommunityInfo.container';
import RecentVisitorsDetails from './screens/RecentVisitorsDetails';
import ViewPdf from './redux/Documents/ViewPdf.container';
import LoginCredentialScreen from './redux/LoginCredentialScreen/LoginCredentialScreen.container';


export default class Navigator extends React.Component {

  //let { path, url } = useRouteMatch();

    render() {

        return (

          <Layout>
            <Switch>
              <Route exact path="/dashboard" component={CommunityInfo} />
              {/* <Route path="/login" component={LoginScreen} /> */}
              {/* <Route path="/dashboard" component={MainMenuScreen} /> */}
              {/* <Redirect from='/dashboard' to='/dashboard/manage-guest'/> */}
              <Route path="/dashboard/contact-info" component={ContactInfo} />
              <Route path="/dashboard/contact-info-details" component={ContactInfoB} />
              {/* <Route path="/dashboard/community-info" component={CommunityInfo} /> */}
              <Route path="/dashboard/documents" component={Document} />
              <Route path="/dashboard/view-pdf" component={ViewPdf} />
              <Route path="/dashboard/recent-visitors" component={RecentVisitors} />
              <Route path="/dashboard/recent-visitors-details" component={RecentVisitorsDetails} />
              <Route path="/dashboard/registered-vehicles" component={RegisteredVehicles} />
              <Route path="/dashboard/registered-vehicles-details" component={RegisteredVehiclesDetails} />
              <Route path="/dashboard/notification-settings" component={NotificationSettings} />
              <Route path="/dashboard/notification-settings-details" component={NotificationSettingsB} />
              <Route path="/dashboard/emergency-contact" component={EmergencyContact} />
              <Route path="/dashboard/add-edit-emergency-contact" component={AddEditEmergencyContact} />
              <Route path="/dashboard/manage-pets" component={ManagePets} />
              <Route path="/dashboard/add-edit-pet" component={AddEditPet} />
              <Route path="/dashboard/manage-guest" component={ManageGuestList} />
              <Route path="/dashboard/add-edit-guest" component={AddEditGuest} />
              <Route path="/dashboard/add-edit-multiple-guest" component={AddEditMultipleGuests} />
              <Route path="/dashboard/travel-info" component={TravelInfo} />
              <Route path="/dashboard/add-edit-travel" component={AddEditTravel} />
              <Route path="/dashboard/manage-authorized-vendors" component={ManageAuthorizedVendorsList} />
              <Route path="/dashboard/manage-login-credentials" component={LoginCredentialScreen} />
            </Switch>
          </Layout>

        );
    }
}

//export default withRouter(Navigator);
