import { connect } from 'react-redux';
import _get from 'lodash/get';
import ManageAuthorizedVendorsList from '../../screens/ManageAuthorizedVendorsList';

import { getAuthorizedVendors, putAuthorizedVendors } from './AuthorizedVendors.action';

const mapStateToProps = state => ({
  authorizedVendors: state.authorizedVendors.authorizedVendors,
});

export default connect(
  mapStateToProps,
  {
    getAuthorizedVendors,
    putAuthorizedVendors
  },
)(ManageAuthorizedVendorsList);
