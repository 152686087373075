import { createAction } from 'redux-actions';
import { get, put } from '../../api/api';

import {
    GET_AUTHORIZED_VENDORS_REQUEST,
    GET_AUTHORIZED_VENDORS_SUCCESS,
    GET_AUTHORIZED_VENDORS_FAILURE,
    PUT_AUTHORIZED_VENDORS_REQUEST,
    PUT_AUTHORIZED_VENDORS_SUCCESS,
    PUT_AUTHORIZED_VENDORS_FAILURE
} from './AuthorizedVendors.action.constant'

const getAuthorizedVendorsRequest = createAction(GET_AUTHORIZED_VENDORS_REQUEST);
const getAuthorizedVendorsSuccess = createAction(GET_AUTHORIZED_VENDORS_SUCCESS);
const getAuthorizedVendorsFailure = createAction(GET_AUTHORIZED_VENDORS_FAILURE);

const putAuthorizedVendorsRequest = createAction(PUT_AUTHORIZED_VENDORS_REQUEST);
const putAuthorizedVendorsSuccess = createAction(PUT_AUTHORIZED_VENDORS_SUCCESS);
const putAuthorizedVendorsFailure = createAction(PUT_AUTHORIZED_VENDORS_FAILURE);

export function getAuthorizedVendors() {
    return async (dispatch) => {
      dispatch(getAuthorizedVendorsRequest());
      const { error, response } = await get(
        `${'AuthorizedVendors/all'}`,
        {}
      );
      if (response) {
        if (response) {
          dispatch(getAuthorizedVendorsSuccess(response));
        } else {
          dispatch(getAuthorizedVendorsFailure(response));
        }
      }
      if (error) {
        dispatch(getAuthorizedVendorsFailure(error));
      }
    };
  }

  export function putAuthorizedVendors(data) {
      return async (dispatch) => {
        dispatch(putAuthorizedVendorsRequest());
        const { error, response } = await put(
          `${'AuthorizedVendors'}`,
          data
        );
        if (response) {
          if (response) {
            dispatch(putAuthorizedVendorsSuccess(response));
          } else {
            dispatch(putAuthorizedVendorsFailure(response));
          }
        }
        if (error) {
          dispatch(putAuthorizedVendorsFailure(error));
        }
      };
    }
