import ip, {chain} from 'icepick';
import {
  ADD_REQUEST,
  ADD_SUCCESS,
  ADD_FAILURE,
  // GET_GUEST_REQUEST,
  // GET_GUEST_SUCCESS,
  // GET_GUEST_FAILURE,
} from './AddEditMultipleGuests.action.constant';

const initialState = ip.freeze({
  addStatus: null,
  addError: null,
  addData: null,
  // getGuestStatus: null,
  // getGuestError: null,
  // getGuestData: null,
});

export default function editAPI(state = initialState, action) {
  switch (action.type) {

    // case GET_GUEST_REQUEST: {
    //   return chain(state)
    //     .setIn(['getGuestError'], null)
    //     .setIn(['getGuestStatus'], 'started')
    //     .setIn(['getGuestData'], [])
    //     .value();
    // }
    //
    // case GET_GUEST_SUCCESS: {
    //   const {data} = action.payload;
    //   return chain(state)
    //     .setIn(['getGuestError'], null)
    //     .setIn(['getGuestStatus'], 'finished')
    //     .setIn(['getGuestData'], data.result)
    //     .value();
    // }
    //
    // case GET_GUEST_FAILURE: {
    //   return chain(state)
    //     .setIn(['getGuestError'], 'error')
    //     .setIn(['getGuestStatus'], 'failled')
    //     .setIn(['getGuestData'], [])
    //     .value();
    // }

    case ADD_REQUEST: {
      return chain(state)
        .setIn(['addError'], null)
        .setIn(['addStatus'], 'started')
        .setIn(['addData'], [])
        .value();
    }

    case ADD_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['addError'], null)
        .setIn(['addStatus'], 'finished')
        .setIn(['addData'], data.result)
        .value();
    }

    case ADD_FAILURE: {
      return chain(state)
        .setIn(['addError'], 'error')
        .setIn(['addStatus'], 'failled')
        .setIn(['addData'], [])
        .value();
    }

    default:
      return state;
  }
}
