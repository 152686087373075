import axios from 'axios';
import querystring from 'querystring';
//import toaster from 'toasted-notes';
import apiConfig from './apiConfig';
const url = apiConfig.vendorBaseUrl;


export function post(endPoint, body) {
  // console.log('body: ',body);
  return (
    axios
      .post(`${url}${endPoint}`, body)
      .then((response) => {
        // console.log('response: ',response);
        return { error: null, response };
      })
      .catch((error) => {
        // console.log('error: ',error);
        //toaster.notify('You are Offline. Please check your connection.', {duration: 2000})
        if (error.response.status === 404)
        {
          //ignore because screen will handle the alert
        }
        else if (error.response.status === 400)
        {
          var myobj = JSON.parse(JSON.stringify(error.response));
          //console.log('myObj: ',myobj);

          alert(myobj.data.message);
        }
        else if (error.response.status === 401)
        {
          alert('Invalid Credentials, Please try again.')
        }
        else
        {
          // alert('You are Offline. Please check your connection')
        }
        if (error.response) {
          return { error: error.response };
        }
        // return { error: error };
      })
  );
}

export function put(endPoint, body) {
  return (
    axios
    .put(`${url}${endPoint}`, body)
    .then((response) => {
      // console.log('response: ',response);
      return { error: null, response };
    })
    .catch((error) => {
      // console.log("error: ",error)
      //toaster.notify('You are Offline. Please check your connection.', {duration: 2000})
      if (error.response.status === 404)
      {
        //ignore because screen will handle the alert
      }
      else if (error.response.status === 400)
      {
        var myobj = JSON.parse(JSON.stringify(error.response));
        //console.log('myObj: ',myobj);

        alert(myobj.data.message);
      }
      else if (error.response.status === 401)
      {
        alert('Invalid Credentials, Please try again.')
      }
      else
      {
        // alert('You are Offline. Please check your connection')
      }
      if (error.response) {
        return { error: error.response };
      }
      // return { error: error };
    })
  );
}

export function get(endPoint, body) {
  let getUrl = `${url}${endPoint}`;
  if (body) {
    const qs = querystring.stringify(body);
    if (qs) {
        getUrl += (getUrl.indexOf('?') >= 0 ? '&' : '?') + qs;
    }
  }
  // console.log('callingGet: '+getUrl);
      return axios
        .get(`${getUrl}`)//,{ headers: { "DarkMode": 1 }}
        .then((response) => {
          // console.log('response: ',response);
          return { error: null, response };
        })
        .catch((error) => {
          // console.log("error: ",error)
          if (error.response.status === 404)
          {
            //ignore because screen will handle the alert
          }
          else if (error.response.status === 400)
          {
            var myobj = JSON.parse(JSON.stringify(error.response));
            //console.log('myObj: ',myobj);

            alert(myobj.data.message);
          }
          else if (error.response.status === 401)
          {
            alert('Invalid Credentials, Please try again.')
          }
          else
          {
            // alert('You are Offline. Please check your connection')
          }
          if (error.response) {
            return { error: error.response, response: null };
          }
          // return { error, response: null };
        })
        .finally(function () {
          //console.log("finally: ")
        // always executed
      });
}

export function del(endPoint, body) {
  let getUrl = `${url}${endPoint}`;
  if (body) {
    const qs = querystring.stringify(body);
    if (qs) {
        getUrl += (getUrl.indexOf('?') >= 0 ? '&' : '?') + qs;
    }
  }
  //console.log('calling: '+getUrl);
  return axios
    .delete(`${getUrl}`)
    .then((response) => {
      return { error: null, response };
    })
    .catch((error) => {
      //toaster.notify('You are Offline. Please check your connection.', {duration: 2000})
      if (error.response) {
        return { error: error.response };
      }
      return { error: error };
    });
}

export function getVendorCommunityInfoApi(endPoint, body, headers) {
  let getUrl = `${url}${endPoint}`;
  if (body) {
    const qs = querystring.stringify(body);
    if (qs) {
        getUrl += (getUrl.indexOf('?') >= 0 ? '&' : '?') + qs;
    }
  }
  //console.log('callingGet: '+getUrl);

      //console.log('callingGet: '+getUrl);
      return axios
        .get(`${getUrl}`,{ headers: { "DarkMode": headers }})
        .catch((error) => {
          // console.log("error: ",error)
          if (error.response) {
            return { error: error.response, response: null };
          }
          return { error, response: null };
        })
        .then((response) => {
          // console.log('response: ',response);
          return { error: null, response };
        })
        .finally(function () {
          //console.log("finally: ")
        // always executed
      });
}

export function postBulk(endPoint, body) {

  // let getUrl = `${url}${endPoint}`;
  // if (body) {
    let abc = JSON.stringify(body);
    // console.log('postBulkBody: ',abc);
  //   if (qs) {
  //       getUrl += (getUrl.indexOf('?') >= 0 ? '&' : '?') + qs;
  //   }
  // }
      // console.log('callingGet: '+getUrl);
      return (
        axios
        .post(`${url}${endPoint}`, abc, {headers:{
          'Content-type': 'application/json',
        }})
        .then((response) => {
          // console.log('postBulkResponse: ',response);
          return { error: null, response };
        })
        .catch((error) => {
          // console.log('postBulkError: ',error);
          if (error.response.status === 404)
          {
            //ignore because screen will handle the alert
          }
          else if (error.response.status === 400)
          {
            var myobj = JSON.parse(JSON.stringify(error.response));
            //console.log('myObj: ',myobj);

            alert(myobj.data.message);
          }
          else if (error.response.status === 401)
          {
            alert('Invalid Credentials, Please try again.')
          }
          else
          {
            // alert('You are Offline. Please check your connection')
          }
          if (error.response) {
            return { error: error.response };
          }
          // return { error: error };
        })
      //   .finally(function () {
      //     console.log("postBulk: ")
      //   // always executed
      // })
    );
}

export function setAuthHeaders(auth) {
  // console.log('auth: ',auth);
  //axios.defaults.headers.common.Authorization = auth;
  axios.defaults.headers.common['Authorization'] = auth;
}
