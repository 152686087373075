import {createAction} from 'redux-actions';
import {post} from '../../api/api';

import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGIN_PREFERENCE,
} from './Login.action.constant';

const loginRequest = createAction(LOGIN_REQUEST);
const loginSuccess = createAction(LOGIN_SUCCESS);
const loginFailure = createAction(LOGIN_FAILURE);
const logOutRequest = createAction(LOGOUT_REQUEST);
const loginPreference = createAction(LOGIN_PREFERENCE);

export function loginCall(data) {
  return async dispatch => {
    dispatch(loginRequest());
    const {error, response} = await post(`${'ApiAuthorization'}`, data);
    if (response) {
      if (response.data.result) {
        dispatch(loginSuccess(response));
        return {
          success: true,
          data: response.data.result
        }
      } else {
        dispatch(loginFailure(response));
        return {
          success: false,
          data: response.data
        }
      }
    }
    if (error) {
      dispatch(loginFailure(error));
      return {
        success: false,
        data: error
      }
    }
  };
}

export function logOut() {
  return async dispatch => {
    dispatch(logOutRequest());
  }
}

export function saveLoginPreference(reMe, auLo, uData) {
  // console.log('@action: saveLoginPreference')
  return async dispatch => {
    dispatch(loginPreference([reMe, auLo, uData]));
  }
}
