export const GA3 = require('./ga3.png');
export const IPHONE_X_BLUE = require('./iPhone_X_Blue.png');
export const CHECK = require('./check.png');
export const SHAPE = require('./Shape.png');
export const YOURCOMMUNITY = require('./YourCommunity.png');
export const HOMELOGO = require('./HomeLogo.png');
export const BG1 = require('./bg.jpg');
export const PET1 = require('./pet1.png');
export const BG = require('./bg_login.png');
export const LOGO = require('./login_logo.png');
export const ABDILOGO = require('./abdi_logo.png');
export const GuestListIcon = require('./guestListIcon.png');
export const EventGuestIcon = require('./eventGuestIcon.png');
export const RecentVisitorsIcon = require('./recentVisitorsIcon.png');
export const NotificationSetting = require('./notificationSetting.png');
export const ContactInfoIcon = require('./contactInfoIcon.png');
export const ManagePetIcon = require('./managePetIcon.png');
export const EmergencyContactIcon = require('./emergencyContactIcon.png');
export const RegisteredVehicleIcon = require('./registeredVehicleIcon.png');
export const UpdateTravelIcon = require('./updateTravelIcon.png');
export const FastAccessIcon = require('./fastAccessIcon.png');
export const LoginCredentialsIcon = require('./loginCredentialsIcon.png');
export const LogoutIcon = require('./logoutIcon.png');
export const CommunityInfoNewsBanner = require('./CommunityInfoNewsBanner.png');
export const GuestListBanner = require('./GuestListBanner.png');
export const RecentVisitorBanner = require('./RecentVisitorBanner.png');
export const ManagePetsBanner = require('./ManagePetsBanner.png');
export const RegisteredVehiclesBanner = require('./RegisteredVehiclesBanner.png');
export const EmergencyContactsBanner = require('./EmergencyContactsBanner.png');
export const TravelInfoBanner = require('./TravelInfoBanner.png');
export const ContactInfoBanner = require('./ContactInfoBanner.png');
export const NotificationSettingsBanner = require('./NotificationSettingsBanner.png');
