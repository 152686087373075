import { connect } from 'react-redux';
// import _get from 'lodash/get';
import NotificationSettings from '../../screens/NotificationSettings';

import { getNotificationSetting, editNotificationSetting, deleteNotificationSetting } from './NotificationSettings.action';

const mapStateToProps = state => ({
  globalData: state.loginData.globalData,
  loginData: state.loginData.loginData,
});

export default connect(
  mapStateToProps,
  {
    getNotificationSetting,
    editNotificationSetting,
    deleteNotificationSetting,
  },
)(NotificationSettings);
