import ip, {chain} from 'icepick';
import {
    GET_RECENT_VISITORS_REQUEST,
    GET_RECENT_VISITORS_SUCCESS,
    GET_RECENT_VISITORS_FAILURE,
} from './RecentVisitors.action.constant'

const initialState = ip.freeze({
  getRecentVisitorsStatus: null,
  getRecentVisitorsError: null,
  recentVisitors: [],
});

export default function getRecentVisitors(state = initialState, action) {
  switch (action.type) {
    case GET_RECENT_VISITORS_REQUEST: {
      return chain(state)
        .setIn(['getRecentVisitorsError'], null)
        .setIn(['getRecentVisitorsStatus'], 'started')
        // .setIn(['recentVisitors'], [])
        .value();
    }

    case GET_RECENT_VISITORS_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['getRecentVisitorsError'], null)
        .setIn(['getRecentVisitorsStatus'], 'finished')
        .setIn(['recentVisitors'], data.employees)
        .value();
    }

    case GET_RECENT_VISITORS_FAILURE: {
      return chain(state)
        .setIn(['getRecentVisitorsError'], 'error')
        .setIn(['getRecentVisitorsStatus'], 'failled')
        // .setIn(['recentVisitors'], [])
        .value();
    }

    default:
      return state;
  }
}
