import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import {
  AppBar, Toolbar, IconButton, Hidden,
  Drawer, CssBaseline
} from '@material-ui/core';
import { GA3 } from '../assets/images';
import { withStyles } from '@material-ui/core/styles';
import { Menu } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import { compose } from 'recompose';
import WebAdmin from '../vendorscreens/WebAdmin';
import CommercialPortalUser from '../vendorscreens/CommercialPortalUser';
import MainMenuWebAdmin from '../redux/MainMenuWebAdmin/MainMenuWebAdmin.container';
import {primaryColor} from '../constants/colors';

const drawerWidth = 350

const styles = theme => ({
  root: {
    // flexGrow: 1,
    // zIndex: 1,
    // overflow: 'hidden',
    // position: 'relative',
    display: 'flex',
    width: '100%',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  navIconHide: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up('md')]: {
      position: 'relative',
    },
  },
  content: {
    //display: 'flex',
    flexGrow: 1,
    // backgroundColor: "#fff",
    //padding: theme.spacing(3),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
})

class LayoutVendorWebAdmin extends Component {
  state = {
    mobileOpen: false
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen })
  }

  Copyright = () => {
    return (
      <Typography
        // className={classes.copyright}
        style={{position: 'absolute', bottom: 0, left: 0, right: 0, backgroundColor: "#f5f5f5", padding: 3}}
        variant="body2"
        color="textSecondary"
        align="center"
      >
        {"© "}
        {" "}
        {" Applications by Design, INC. "}
        {new Date().getFullYear()}
      </Typography>
    );
  }

  render() {
    const { classes,
      location: { pathname },
      children } = this.props
    const { mobileOpen } = this.state

    const drawer = (
      <div>
        <Hidden smDown>
          <div className={classes.toolbar} />
        </Hidden>
        <MainMenuWebAdmin/>
      </div>
    )

    return <Fragment>
      <CssBaseline/>

      <div className={classes.root}>
        <AppBar position="absolute" className={classes.appBar} style={{backgroundColor: primaryColor}}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleDrawerToggle}
              className={classes.navIconHide}
            >
              <Menu />
            </IconButton>
            {/* <Typography variant="h6" color="inherit" noWrap>
              ABDi-GateAccess.NET
            </Typography> */}
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <img
                style={{width: window.innerWidth * (20 / 100), resizeMode: 'contain', margin: 'auto', alignSelf: 'center'}}
                src={GA3}
                alt={'main bottom'}
              />
            </div>
          </Toolbar>
        </AppBar>
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={this.handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            variant="permanent"
            open
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {(pathname === '/webadmin') && <WebAdmin/>}
          {(pathname === '/commercialportaluser') && <CommercialPortalUser/>}
          {children}
        </main>
        {this.Copyright()}
      </div>
    </Fragment>
  }
}
// export default withStyles(styles)(LayoutVendorWebAdmin);
export default compose(
  withRouter,
  withStyles(styles)
)(LayoutVendorWebAdmin)
