import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
// import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
// import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
// import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
// import FilterListIcon from '@material-ui/icons/FilterList';
import {materialColor} from '../constants/colors';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import AppBar from '@material-ui/core/AppBar';
import Slide from '@material-ui/core/Slide';
//import 'date-fns';
//import DateFnsUtils from '@date-io/date-fns';
import 'handsontable/dist/handsontable.full.css';
import { HotTable } from '@handsontable/react';
import Handsontable from 'handsontable';
import CircularProgress from '@material-ui/core/CircularProgress';


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'guestName', numeric: false, disablePadding: false, label: 'Guest Name' },
  { id: 'emailAddress', numeric: false, disablePadding: false, label: 'Email Address' },
  { id: 'cellPhone', numeric: true, disablePadding: false, label: 'Cell Phone #' },
  { id: 'passDelivered', numeric: false, disablePadding: false, label: 'Pass Delivered?' },
  { id: 'notes', numeric: false, disablePadding: false, label: 'Notes' },
  { id: "", numeric: true, disablePadding: false, label: 'Actions' },
];

function ManageGuestHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    // console.log('property: ',property);
    // console.log('event: ',event);
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

ManageGuestHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    // overflowX: 'auto',
    // height: 400
  },
  table: {
    minWidth: 650,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  appBar: {
    position: 'relative',
  },
  title: {
    // width: "20%"
  },
  checkbox: {
    marginTop: '5%'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  rootToolbar: {
    width: "100%",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  container: {
    maxHeight: "65vh",
    width: "72vw"
  },
  loading: {
    position: 'absolute',
    left: 420,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    //backgroundColor: '#0000ff47',
    display:'flex'
  },
  margin: {
    // margin: theme.spacing(1),
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class ManageGuest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order : 'asc',
      orderBy : 'guestName',
      selected : [],
      page : 0,
      dense : false,
      rowsPerPage: 5,
      loading: false,
      allData: [],
      open: false,
      openMultiple: false,
      isEmailChecked: true,
      isPhoneChecked: true,
      guestName: '',
      notes: '',
      roomID: null,
      passDelivered: null,
      codeNumber: null,
      emailAddress: '',
      cellPhone: '',
      cellPhoneError: false,
      guestNameError: false,
      locationError: false,
      roomError: false,
      emailAddressError: false,
      disabledText: false,
      eventID: null,
      locationName: '',
      roomName: '',
      isAgreeChecked: false,
      openReview: false,
      agreeText: '',
      hiddenColumnsArray: []
    };
    this.hotSettings = {
        // data: Handsontable.helper.createSpreadsheetData(1, 3),
        data: null,
        // dataSchema: {eventID: null, guestName: null, notes: null, emailAddress: null, cellPhone: null},
        dataSchema: {guestName: null, emailAddress: null, cellPhone: null},
        colHeaders: ['Guest Name', 'Email Address', 'Cell Phone Number'],
        columns: [
          {data: 'guestName'},
          {data: 'emailAddress'},
          {data: 'cellPhone'}
        ],
        fixedRowsTop: 0,
        startRows: 0,
        minSpareRows: 1,
        contextMenu: {
          items: {
            'copy': {},
            'cut': {},
            'separator1': Handsontable.plugins.ContextMenu.SEPARATOR,
            'row_above': {},
            'row_below': {},
            'separator2': Handsontable.plugins.ContextMenu.SEPARATOR,
            'remove_row': {}
          }
        },
        manualColumnResize: true,
        manualRowResize: true,
        // rowHeaders: true,
        //colHeaders: true
        // width: 570,
        // height: 220,
        // stretchH: 'all',
        // columnSorting: true,
        // hiddenColumns: {
        //   copyPasteEnabled: true,
        //   columns: [],
        //   indicators: false
        // }
      };
    this.tableErrors = [];
    this.hotTableComponent = React.createRef();
  }

  componentDidMount= async () => {
    this.getAllGuests();
    // console.log('events: ',this.props.allEvent);
    // console.log('eventsEdited: ',events);
    // console.log('loginData: ',this.props.vendorLoginData);
    // this.setState({loading: false});
  }

  getAllGuests = async() => {
    this.setState({loading: true});
    const {getAllGuest} = this.props;
    // console.log('pathname: ', this.props.match.params.id);
    await getAllGuest(this.props.match.params.id);
    // console.log('allData: ',this.props.allGuest);
    // this.setState({allData: this.props.allEvent});
    // let events = this.props.allEvent.map((item) => ({
    //   ...item,
    //   emailAddress: item.emailAddress,
    //   cellPhone: item.cellPhone,
    //   locationName:this.getLocation(item.passDelivered),
    //   notes:item.notes,
    // }))
    this.setState({allData: this.props.allGuest, loading: false, eventID: this.props.match.params.id});
  }

  // getLocation = (passDelivered) => {
  //   for (const item of this.props.vendorLoginData.fastAccessLocations) {
  //     if (item.passDelivered === passDelivered) {
  //       return item.description;
  //     }
  //   }
  // }
  //
  // getArea = (notes) => {
  //   for (const item of this.props.vendorLoginData.fastAccessRooms) {
  //     if (item.id === notes) {
  //       return item.description;
  //     }
  //   }
  // }

  goBack = () => {
    this.props.history.goBack();
  };

  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === 'asc';
    this.setState({order: (isAsc ? 'desc' : 'asc'), orderBy : property});
    // this.setState({orderBy : property});
    // console.log('property: ',property);
    // console.log('event: ',event);
  };

  handleSelectAllClick = (event) => {
    // if (event.target.checked) {
    //   const newSelecteds = this.state.allData.map((n) => n.guestName);
    //   this.setState({selected : newSelecteds});
    //   return;
    // }
    this.setState({selected : []});
  };

  handleClick = (event, name) => {
    const {selected} = this.state;
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({selected : newSelected});
  };

  handleChangePage = (event, newPage) => {
    this.setState({page : newPage});
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({rowsPerPage : parseInt(event.target.value, 10), page : 0});
    // this.setState({page : 0});
  };

  handleChangeDense = (event) => {
    this.setState({dense : event.target.checked});
  };

  isSelected = (name) => this.state.selected.indexOf(name) !== -1;

  emptyRows = () => {
    return this.state.rowsPerPage - Math.min(this.state.rowsPerPage, this.state.allData.length - this.state.page * this.state.rowsPerPage);
  }

  handleChange = (e) => {
    // console.log(e.target.name);
    // console.log(e.target.value);
    if ((e.target.name === 'cellPhone') && (e.target.value?.length === 10)) {
      this.setState({cellPhoneError: false, cellPhone: e.target.value});
      return;
    }
    this.setState(Object.assign(this.state, { [e.target.name]: e.target.value }))
  }

  validateBulk = async() => {
    const {eventID, isEmailChecked, isPhoneChecked} = this.state;

    // console.log('data: ',this.hotTableComponent.current.hotInstance.getSourceData());
    this.tableErrors = []
    let arr = JSON.stringify(this.hotTableComponent.current.hotInstance.getSourceData())
    let dataArr = JSON.parse(arr)
    // console.log(JSON.stringify(dataArr))
    // Delete all empty rows from DataArr
    // dataArr.forEach((row, index, obj) => {
    //   if (Object.entries(row).length === 0 && row.constructor === Object) {
    //     obj.splice(index, 1)
    //   }
    // })
    dataArr.pop();
    // console.log(JSON.stringify(dataArr))
    dataArr.forEach((row, index, obj) => {
      // if name is invalid
        if (row.guestName == null || row.guestName === '' || !('guestName' in row)) {
          this.tableErrors.push(`Row ${index + 1}, Guest Name is invalid`)
        }
        let emailIsNotEmpty = (row.emailAddress !== '') && (row.emailAddress !== null)
        // console.log('emailIsNotEmpty: ',emailIsNotEmpty);
        // console.log('row email: ',row.emailAddress);
        let emailExists = ('emailAddress' in row)
        // email checked
        if (isEmailChecked) {
          if (!emailIsNotEmpty || !emailExists) {
            this.tableErrors.push(`Row ${index + 1}, Email is required`)
          }
          if (emailIsNotEmpty && emailExists) { // && Object.entries(row).length > 0
            let re = /^\S+@\S+\.\S+$/
            if (!re.test(row.emailAddress)) {
              this.tableErrors.push(`Row ${index + 1}, Email is invalid`)
            }
          }
        } else {
          delete row.emailAddress
        }
        let phoneIsNotEmpty = (row.cellPhone !== '') && (row.cellPhone !== null)
        // console.log('phoneIsNotEmpty: ',phoneIsNotEmpty);
        let phoneExists = ('cellPhone' in row)
        // phone checked
        if (isPhoneChecked) {
          if (!phoneIsNotEmpty || !phoneExists) {
            this.tableErrors.push(`Row ${index + 1}, Phone is required`)
          }
          if (phoneIsNotEmpty && phoneExists) { // && Object.entries(row).length > 0
            let phone = row.cellPhone.replace(/\D/g, '')
            row.cellPhone = phone
            if (row.cellPhone.length !== 10) {
              this.tableErrors.push(`Row ${index + 1}, Phone is invalid`)
            }
            if(isNaN(row.cellPhone)){
              // Its not a number
              this.tableErrors.push(`Row ${index + 1}, Phone is invalid`)
            }
          }
        } else {
          delete row.cellPhone
        }

        // email checked AND phone checked, verify at least one exists in row
        // email obj is there
        if (isPhoneChecked && isEmailChecked) {
          if ((!emailIsNotEmpty || !emailExists) && (!phoneIsNotEmpty || !phoneExists)) {
            this.tableErrors.push(`Row ${index + 1}, Phone and/or Email is required`)
          }
        }
        if (!isPhoneChecked && !isEmailChecked) {
          this.tableErrors.push(`Select either Phone or Email checkbox or both`)
        }
      })
      // console.log(JSON.stringify(dataArr))
      // console.log('error: ',this.tableErrors);
      if (this.tableErrors.length === 0 && dataArr.length > 0) {
        let {addBulkGuestsCall} = this.props;
        let guestBulkData = dataArr.map((item) => ({
          ...item,
          "eventID": ""+eventID,
          "notes":'',
        }))
        // console.log(JSON.stringify(guestBulkData));
        this.setState({loading: true});
        let res = await addBulkGuestsCall(eventID, guestBulkData);
        this.setState({loading: false});
        // console.log("res: ",res)
        if (!res.success) {
          // alert(""+abcd.data.data.message);
          return;
        }
        this.handleCloseMultiple();
        this.getAllGuests();
      } else {
        if(dataArr.length === 0){
          alert("Please Enter some guest");
        } else {
          alert(this.tableErrors.toString());
        }
      }
  }

  validate = async() => {
    const {guestID, guestName, emailAddress, cellPhone, notes, eventID} = this.state;

    if (guestName == null || guestName.trim() === '')
    {
      this.setState({guestNameError: true});
      return;
    }
    if ((emailAddress == null || emailAddress === '') && (cellPhone == null || cellPhone === ''))
    {
      this.setState({ guestNameError: false});
      alert("Email or Cell phone is required")
      return;
    }
    // if (cellPhone == null || cellPhone === '')
    // {
    //   this.setState({cellPhoneError: true});
    //   return;
    // }
    var reEmail = /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
    if (emailAddress.length>0 && new RegExp(reEmail).test(emailAddress) === false) {
      this.setState({emailAddressError: true, guestNameError: false});
      return;
    }
    if(cellPhone.length > 0 && cellPhone.length < 10){
      this.setState({cellPhoneError: true, guestNameError: false, emailAddressError: false});
      return;
    }

    let num = cellPhone.replace(".", '');
    if(isNaN(num)){
      // Its not a number
      // this.setState({cellPhoneError: true, guestNameError: false, emailAddressError: false});
      alert("Phone number should be numbers only")
      return;
    }
    this.setState({loading: true});
    let {addGuestCall, editGuestCall} = this.props;
    let abcd = "";
    if (guestID != null) {
      let guestData = {"eventID": ""+eventID, "guestName": ""+guestName.trim(), "emailAddress": ""+emailAddress, "cellPhone": ""+cellPhone, "notes": ""+notes};
      abcd = await editGuestCall(guestID, guestData);
      // console.log('abcd:',abcd);
      this.setState({loading: false, guestNameError: false, emailAddressError: false, cellPhoneError: false});
      if (!abcd.success) {
        // alert(""+abcd.data.data.message);
        return;
      }
      this.handleClose();
      this.getAllGuests();
    } else {
      let guestData = {"eventID": ""+eventID, "guestName": ""+guestName.trim(), "emailAddress": ""+emailAddress, "cellPhone": ""+cellPhone, "notes": ""+notes};
      abcd = await addGuestCall(guestData);
      // console.log('abcd:',abcd);
      this.setState({loading: false, guestNameError: false, emailAddressError: false, cellPhoneError: false});
      if (!abcd.success) {
        // alert(""+abcd.data.data.message);
        return;
      }
      this.handleClose();
      this.getAllGuests();
      // console.log('abcd: ',abcd);
    }
  }

  openDialog = () => {
    this.setState({open: true});
  }

  handleOpen = () => {
    this.openDialog();
  };

  handleClose = () => {
    this.setState({open: false, guestID: null, guestName: '', emailAddress: '', cellPhone: '', notes: "", guestNameError: false, emailAddressError: false, cellPhoneError: false});
    // if(this.state.isNew){
    //   this.props.history.goBack();
    // }
  };

  handleOpenMultiple = () => {
    this.setState({openMultiple: true, hiddenColumnsArray: [], isEmailChecked: true, isPhoneChecked: true});
    // this.hotTableComponent.current.hotInstance.loadData([['new', 'data', ]]);
  }

  handleCloseMultiple = () => {
    this.setState({openMultiple: false, guestName: '', emailAddress: '', cellPhone: '', notes: ""});
    // if(this.state.isNew){
    //   this.props.history.goBack();
    // }
  };

  deleteGuest = async(guestID) => {
    let {deleteGuest} = this.props;
    this.setState({loading: true});
    await deleteGuest(guestID);
    this.getAllGuests();
  };

  editGuest = async(val) => {
    // let {getSpecificGuest} = this.props;
    // let guest = await getSpecificGuest(val.guestID);
    // console.log('guest: ', guest);
    // console.log('val: ', val);
    this.setState({guestID: val.guestID, guestName: val.guestName, emailAddress: val.emailAddress, cellPhone: val.cellPhone, open: true, notes: val.notes});
  };


  render(){
    const {classes} = this.props;
    const {selected, dense, order, orderBy, page, rowsPerPage, loading, allData, open, openMultiple, guestName, notes, guestNameError, cellPhoneError, emailAddressError, emailAddress, cellPhone, guestID, isPhoneChecked, isEmailChecked} = this.state;
    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>

          <Dialog aria-labelledby="form-dialog-title" disableBackdropClick disableEscapeKeyDown open={open} onClose={this.handleClose} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
              <Toolbar>
                {/* <IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                  <CloseIcon />
                </IconButton> */}
                <Typography variant="h3" className={classes.title}>
                  {(guestID != null) ? "Edit Guest" : "Add Guest"}
                </Typography>
              </Toolbar>
            </AppBar>

            <DialogContent>
              <TextField
                variant="outlined"
                margin="normal"
                error={guestNameError}
                // disabled={disabledText}
                // type="email"
                // style={{width: 400}}
                //className={classes.textStyle}
                required
                fullWidth
                id="guestName"
                label="Guest Name"
                name="guestName"
                inputProps={{
                  maxlength: 50
                }}
                helperText={guestNameError ? "Guest Name is required " + `${guestName.length}/${50}` : `${guestName.length}/${50}`}
                //autoComplete="username"
                // autoFocus
                value={guestName}
                onChange={this.handleChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                error={emailAddressError}
                // disabled={disabledText}
                type="email"
                // style={{width: 400}}
                //className={classes.textStyle}
                required
                fullWidth
                id="emailAddress"
                label="Email"
                name="emailAddress"
                // inputProps={{
                //   maxlength: 50
                // }}
                helperText={emailAddressError ? "Email is not valid" : ""}
                //autoComplete="username"
                // autoFocus
                value={emailAddress}
                onChange={this.handleChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                error={cellPhoneError}
                // disabled={disabledText}
                // type="email"
                // style={{width: 400}}
                //className={classes.textStyle}
                required
                fullWidth
                id="cellPhone"
                label="Cell Phone"
                name="cellPhone"
                inputProps={{
                  maxlength: 10
                }}
                helperText={cellPhoneError ? "Phone is required " + `${cellPhone.length}/${10}` : `${cellPhone.length}/${10}`}
                //autoComplete="username"
                // autoFocus
                value={cellPhone}
                onChange={this.handleChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                // error={guestNameError}
                // disabled={disabledText}
                // type="email"
                // style={{width: 400}}
                //className={classes.textStyle}
                // required
                fullWidth
                id="notes"
                label="Notes"
                name="notes"
                inputProps={{
                  maxlength: 50
                }}
                helperText={(notes != null) ? `${notes.length}/${50}` : "0/50"}
                //autoComplete="username"
                // autoFocus
                value={notes}
                onChange={this.handleChange}
              />

            </DialogContent>
            <DialogActions>
              <Button
                disabled={loading}
                style={{backgroundColor: materialColor, color: '#fff', marginTop: 25}}
                variant="contained"
                className={"tab active"}
                onClick={this.validate}
              >
                Save
              </Button>
              <Button
                style={{backgroundColor: materialColor, color: '#fff', marginTop: 25, marginLeft: 20}}
                variant="contained"
                className={"tab active"}
                onClick={this.handleClose}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog aria-labelledby="form-dialog-title" disableBackdropClick disableEscapeKeyDown open={openMultiple} onClose={this.handleCloseMultiple} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
              <Toolbar>
                {/* <IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                  <CloseIcon />
                </IconButton> */}
                <Typography variant="h3" className={classes.title}>
                  Add Multiple Guest
                </Typography>
              </Toolbar>
            </AppBar>

            <DialogContent>
              <FormControlLabel value="end" control={<Radio checked={true} color="primary" />} label="Copy and Paste from Spreadsheet" />
              <div className={classes.checkbox}>
                <FormControlLabel
                  control={
                    <Checkbox
                      //value="remember"
                      // disabled={disabledText}
                      color="primary"
                      checked={isEmailChecked}
                      onChange={(e) => {
                        var arr = this.state.hiddenColumnsArray;
                        if(e.target.checked){
                          arr = arr.filter(item => item !== 1)
                        }else{
                          arr.push(1);
                        }
                        this.setState({
                          isEmailChecked: !isEmailChecked, hiddenColumnsArray: arr
                        });

                      }} />
                  }
                  label="My Data has Email Addresses"
                />
              </div>
              <div className={classes.checkbox}>
                <FormControlLabel
                  control={
                    <Checkbox
                      //value="remember"
                      // disabled={disabledText}
                      color="primary"
                      checked={isPhoneChecked}
                      onChange={(e) => {
                        var arr = this.state.hiddenColumnsArray;
                        if(e.target.checked){
                          arr = arr.filter(item => item !== 2)
                        }else{
                          arr.push(2);
                        }
                        this.setState({
                          isPhoneChecked: !isPhoneChecked, hiddenColumnsArray: arr
                        });
                      }} />
                  }
                  label="My Data has Cell Phone Numbers"
                />
              </div>
              <HotTable
                licenseKey={'non-commercial-and-evaluation'}
                style={{marginTop: "5%"}}
                ref={this.hotTableComponent}
                id={"hot"}
                rowHeaders={true}
								// colHeaders={['Guest Name', 'Email Address', 'Cell Phone Number']}
                width={500}
                height={200}
                stretchH={'all'}
                columnSorting={true}
                settings={this.hotSettings}
                filters={true}
                hiddenColumns={{
									copyPasteEnabled: true,
									indicators: false,
									columns: this.state.hiddenColumnsArray
								}}
                // data={[]}
                afterChange={(changes, source) => console.log("changes: ",changes)}
              />
            </DialogContent>
            <DialogActions>
              <Button
                disabled={loading}
                style={{backgroundColor: materialColor, color: '#fff', marginTop: 25}}
                variant="contained"
                className={"tab active"}
                onClick={this.validateBulk}
              >
                Save
              </Button>
              <Button
                style={{backgroundColor: materialColor, color: '#fff', marginTop: 25, marginLeft: 20}}
                variant="contained"
                className={"tab active"}
                onClick={this.handleCloseMultiple}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>

          <Toolbar className={classes.rootToolbar}>
            <div style={{width: "100%", display: 'flex', marginTop: '20px', flexDirection: 'column'}}>
              <Button
                style={{backgroundColor: materialColor, color: '#fff', width: "170px"}}
                variant="contained"
                className={"tab active"}
                onClick={() => this.props.history.goBack()}
              >
                Back to Events
              </Button>
              <div style={{flexDirection: 'row', width: "100%", display: 'flex', justifyContent: 'space-between', marginTop: '20px'}}>

                <Typography className={classes.title} variant="h3">
                  Manage Event Guests
                </Typography>
                <div style={{flexDirection: 'row', width: "360px", display: 'flex', justifyContent: 'space-between'}}>
                  <Button
                    style={{backgroundColor: materialColor, color: '#fff', width: "130px"}}
                    variant="contained"
                    className={"tab active"}
                    onClick={this.handleOpen}
                  >
                    Add Guest
                  </Button>

                  <Button
                    style={{backgroundColor: materialColor, color: '#fff', width: '200px'}}
                    variant="contained"
                    className={"tab active"}
                    onClick={this.handleOpenMultiple}
                  >
                    Add Multiple Guests
                  </Button>
                </div>
              </div>
            </div>
          </Toolbar>
          <TableContainer className={classes.container}>
            <Table
              className={classes.table}
              stickyHeader
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
              aria-label="enhanced table"
            >
              <ManageGuestHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={allData.length}
              />
              <TableBody>
                {stableSort(allData, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    // const isItemSelected = this.isSelected(row.guestName);
                    // const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        style={{backgroundColor: (row.fastAccessEventNeedsAgreement) ? 'yellow' : 'white'}}
                        // onClick={(event) => handleClick(event, row.name)}
                        // role="checkbox"
                        // aria-checked={isItemSelected}
                        // tabIndex={-1}
                        key={row.guestID}
                        // selected={isItemSelected}
                      >
                        {/* <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </TableCell> */}
                        <TableCell>
                          {row.guestName}
                        </TableCell>
                        <TableCell align="right">{row.emailAddress}</TableCell>
                        <TableCell align="right">{row.cellPhone}</TableCell>
                        <TableCell align="right">{row.fastAccessSendResult}</TableCell>
                        <TableCell align="right">{row.notes}</TableCell>
                        {/* <TableCell align="right">{row.registeredGuests}</TableCell> */}
                        <TableCell align="right">
                          {/* <div style={{width: 250, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}> */}
                          <div style={{width: 50, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                            <IconButton edge="end" aria-label="delete" disabled={loading}>
                              <DeleteIcon
                                style={{fill: materialColor, fontSize: 15}}
                                onClick={() => this.deleteGuest(row.guestID)}
                              />
                            </IconButton>
                            <IconButton disabled={(row.fastAccessSendStatus === 1) ? true : false } edge="end" aria-label="edit">
                              <EditIcon
                                style={{fill: (row.fastAccessSendStatus === 1) ? "#cccccc" : materialColor, fontSize: 15}}
                                onClick={() => this.editGuest(row)}
                              />
                            </IconButton>

                          </div>

                        </TableCell>
                      </TableRow>
                    );
                  })}
                {this.emptyRows > 0 && (
                  <TableRow style={{ height: (dense ? 33 : 53) * this.emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, { value: -1, label: 'All' }]}
            component="div"
            count={allData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        </Paper>
        <FormControlLabel
          control={<Switch checked={dense} onChange={this.handleChangeDense} />}
          label="Dense padding"
        />
        {/* <Button
          style={{backgroundColor: materialColor, color: '#fff', width: "170px"}}
          variant="contained"
          className={"tab active"}
          onClick={() => this.props.history.goBack()}
          >
          Back to Events
        </Button> */}
        {loading && (
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        )}
      </div>
    );
  }

}

  ManageGuest.propTypes = {
    classes: PropTypes.object.isRequired,
  };

  export default compose( withRouter, withStyles(useStyles))(ManageGuest);
