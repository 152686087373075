import React, { Component, Fragment } from 'react'
//import { Link, withRouter } from 'react-router-dom'
import {
  AppBar, Toolbar, IconButton, Hidden,
  Drawer, CssBaseline, Typography
} from '@material-ui/core';
import { GA3, ABDILOGO } from '../assets/images';
import { withStyles } from '@material-ui/core/styles';
// import { Menu } from '@material-ui/icons';
//import { compose } from 'recompose';
import MainMenuScreen from '../redux/MainMenu/MainMenu.container';
import {appBarColor, headerColor, brandColor} from '../constants/colors';

const drawerWidth = 420

const styles = theme => ({
  // root: {
  //   flexGrow: 1,
  //   zIndex: 1,
  //   overflow: 'hidden',
  //   position: 'relative',
  //   display: 'flex',
  //   width: '100%',
  // },
  // appBar: {
  //   zIndex: theme.zIndex.drawer + 1,
  // },
  // navIconHide: {
  //   [theme.breakpoints.up('md')]: {
  //     display: 'none',
  //   },
  // },
  // toolbar: theme.mixins.toolbar,
  // drawerPaper: {
  //   width: drawerWidth,
  //   [theme.breakpoints.up('md')]: {
  //     position: 'relative',
  //   },
  // },
  // content: {
  //   //display: 'flex',
  //   flexGrow: 1,
  //   backgroundColor: "#fff",
  //   //padding: theme.spacing(3),
  // },
  // nested: {
  //   paddingLeft: theme.spacing(4),
  // },

  root: {
      display: 'flex'
    },
    appBar: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: '#ffffff',
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      // backgroundColor: theme.palette.background.default,
      backgroundColor: '#ffffff',
      padding: theme.spacing(3),
    },
    bColor: {
      background: 'linear-gradient(45deg, #0B7645 30%, #042B1C 90%)',
      overflow: 'auto',
      maxHeight: 830,
    },
    appBarFooter: {
      top: 'auto',
      bottom: 0,
      // background: theme.palette.background.paper,
      background: 'linear-gradient(45deg, #0B7645 30%, #042B1C 90%)',
      zIndex: theme.zIndex.drawer + 1,
    },
  toolbarFooter: {
    minHeight: 36,
    display: 'flex',
    justifyContent: 'center',
  },
  text: {
    color:"#58595B"
  },

})

class Layout extends Component {
  // state = {
  //   mobileOpen: false
  // }

  // handleDrawerToggle = () => {
  //   this.setState({ mobileOpen: !this.state.mobileOpen })
  // }

  render() {
    const { classes,
      // location: { pathname },
      children } = this.props;
    // const { mobileOpen } = this.state;
    // console.log('pathname: ', pathname);
    // const drawer = (
    //   <div>
    //     <Hidden smDown>
    //       <div className={classes.toolbar} />
    //     </Hidden>
    //     <MainMenuScreen/>
    //     {/*<MenuList>
    //       <MenuItem component={Link} to="/contact-info" selected={'/contact-info' === pathname}>
    //         Home
    //       </MenuItem>
    //        <MenuItem component={Link} to="/writers" selected={'/writers' === pathname}>
    //         Writers
    //       </MenuItem>
    //       <MenuList>
    //         {writers.map(({ id, name }) => {
    //       const to = `/writers/${id}`
    //
    //       return <MenuItem
    //       to={to}
    //       key={id}
    //       className={classes.nested}
    //       component={Link}
    //       selected={to === pathname}
    //       >
    //       {name}
    //       </MenuItem>
    //         })}
    //       </MenuList>
    //     </MenuList>*/}
    //   </div>
    // )

    return (
      <div className={classes.root}>
        <CssBaseline/>
        <AppBar position="fixed" className={classes.appBar} style={{backgroundColor: headerColor}}>
          <Toolbar>
            {/* <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleDrawerToggle}
              className={classes.navIconHide}
              >
              <Menu />
            </IconButton> */}
            <Typography variant="h5" noWrap className={classes.text}>
              ABDi-GateAccess.NET
            </Typography>

            {/* <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <img
                style={{width: window.innerWidth * (20 / 100), resizeMode: 'contain', margin: 'auto', alignSelf: 'center'}}
                src={LOGO}
                alt={'main bottom'}
              />
            </div> */}

          </Toolbar>
        </AppBar>
        {/* <Hidden mdUp>
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={this.handleDrawerToggle}
            classes={{
          paper: classes.drawerPaper,
            }}
            ModalProps={{
          keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden> */}
        {/* <Hidden smDown implementation="css"> */}
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="left"
        >
          <div className={classes.toolbar} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: brandColor}}>
            <img
              style={{width: window.innerWidth * (20 / 100), resizeMode: 'contain', margin: 'auto', alignSelf: 'center'}}
              src={ABDILOGO}
              alt={'main bottom'}
            />
          </div>
          <div className={classes.bColor}>
            <MainMenuScreen/>
          </div>
          {/* {drawer} */}
        </Drawer>
        {/* </Hidden> */}
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {children}
        </main>
        <AppBar position="fixed" className={classes.appBarFooter}>
          <Toolbar className={classes.toolbarFooter}>
            <Typography variant="body2" color="white">
              © {new Date().getFullYear()} ABDI GateAccess.Net. All rights reserved.
            </Typography>
          </Toolbar>
        </AppBar>
      </div>
    )
  }
}
export default withStyles(styles)(Layout);
// export default compose(
//   withRouter,
//   withStyles(styles)
// )(Layout)
