export const ALL_EMERGENCY_CONTACT = 'ALL_EMERGENCY_CONTACT';

export const GET_ALL_EMERGENCY_CONTACT_REQUEST = 'GET_ALL_EMERGENCY_CONTACT_REQUEST';
export const GET_ALL_EMERGENCY_CONTACT_SUCCESS = 'GET_ALL_EMERGENCY_CONTACT_SUCCESS';
export const GET_ALL_EMERGENCY_CONTACT_FAILURE = 'GET_ALL_EMERGENCY_CONTACT_FAILURE';

export const DELETE_EMERGENCY_CONTACT_REQUEST = 'DELETE_EMERGENCY_CONTACT_REQUEST';
export const DELETE_EMERGENCY_CONTACT_SUCCESS = 'DELETE_EMERGENCY_CONTACT_SUCCESS';
export const DELETE_EMERGENCY_CONTACT_FAILURE = 'DELETE_EMERGENCY_CONTACT_FAILURE';

export const ADD_EMERGENCY_CONTACT_REQUEST = 'ADD_EMERGENCY_CONTACT_REQUEST';
export const ADD_EMERGENCY_CONTACT_SUCCESS = 'ADD_EMERGENCY_CONTACT_SUCCESS';
export const ADD_EMERGENCY_CONTACT_FAILURE = 'ADD_EMERGENCY_CONTACT_FAILURE';

export const EDIT_EMERGENCY_CONTACT_REQUEST = 'EDIT_EMERGENCY_CONTACT_REQUEST';
export const EDIT_EMERGENCY_CONTACT_SUCCESS = 'EDIT_EMERGENCY_CONTACT_SUCCESS';
export const EDIT_EMERGENCY_CONTACT_FAILURE = 'EDIT_EMERGENCY_CONTACT_FAILURE';
