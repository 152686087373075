import ip, {chain} from 'icepick';
import {
    GET_REGISTERED_VEHICLES_REQUEST,
    GET_REGISTERED_VEHICLES_SUCCESS,
    GET_REGISTERED_VEHICLES_FAILURE,
} from './RegisteredVehicles.action.constant'

const initialState = ip.freeze({
  getRegisteredVehiclesStatus: null,
  getRegisteredVehiclesError: null,
  registeredVehicles: [],
});

export default function getRegisteredVehicles(state = initialState, action) {
  switch (action.type) {
    case GET_REGISTERED_VEHICLES_REQUEST: {
      return chain(state)
        .setIn(['getRegisteredVehiclesError'], null)
        .setIn(['getRegisteredVehiclesStatus'], 'started')
        // .setIn(['registeredVehicles'], [])
        .value();
    }

    case GET_REGISTERED_VEHICLES_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['getRegisteredVehiclesError'], null)
        .setIn(['getRegisteredVehiclesStatus'], 'finished')
        .setIn(['registeredVehicles'], data.result)
        .value();
    }

    case GET_REGISTERED_VEHICLES_FAILURE: {
      return chain(state)
        .setIn(['getRegisteredVehiclesError'], 'error')
        .setIn(['getRegisteredVehiclesStatus'], 'failled')
        // .setIn(['registeredVehicles'], [])
        .value();
    }

    default:
      return state;
  }
}
