import {createAction} from 'redux-actions';
import {get, del, post, put} from '../../api/api';

import {
  GET_ALL_EMERGENCY_CONTACT_REQUEST,
  GET_ALL_EMERGENCY_CONTACT_SUCCESS,
  GET_ALL_EMERGENCY_CONTACT_FAILURE,

  DELETE_EMERGENCY_CONTACT_REQUEST,
  DELETE_EMERGENCY_CONTACT_SUCCESS,
  DELETE_EMERGENCY_CONTACT_FAILURE,

  EDIT_EMERGENCY_CONTACT_REQUEST,
  EDIT_EMERGENCY_CONTACT_SUCCESS,
  EDIT_EMERGENCY_CONTACT_FAILURE,

  ADD_EMERGENCY_CONTACT_REQUEST,
  ADD_EMERGENCY_CONTACT_SUCCESS,
  ADD_EMERGENCY_CONTACT_FAILURE,
} from './EmergencyContact.action.constant';

const getAllEmergencyRequest = createAction(GET_ALL_EMERGENCY_CONTACT_REQUEST);
const getAllEmergencySuccess = createAction(GET_ALL_EMERGENCY_CONTACT_SUCCESS);
const getAllEmergencyFailure = createAction(GET_ALL_EMERGENCY_CONTACT_FAILURE);

const deleteEmergencyRequest = createAction(DELETE_EMERGENCY_CONTACT_REQUEST);
const deleteEmergencySuccess = createAction(DELETE_EMERGENCY_CONTACT_SUCCESS);
const deleteEmergencyFailure = createAction(DELETE_EMERGENCY_CONTACT_FAILURE);

const addEmergencyRequest = createAction(ADD_EMERGENCY_CONTACT_REQUEST);
const addEmergencySuccess = createAction(ADD_EMERGENCY_CONTACT_SUCCESS);
const addEmergencyFailure = createAction(ADD_EMERGENCY_CONTACT_FAILURE);

const editEmergencyRequest = createAction(EDIT_EMERGENCY_CONTACT_REQUEST);
const editEmergencySuccess = createAction(EDIT_EMERGENCY_CONTACT_SUCCESS);
const editEmergencyFailure = createAction(EDIT_EMERGENCY_CONTACT_FAILURE);

export function getAllEmergencyContacts() {
  return async dispatch => {
    dispatch(getAllEmergencyRequest());
    const {error, response} = await get(`${'EmergencyContact/all'}`, {});
    if (response) {
      if (response) {
        dispatch(getAllEmergencySuccess(response));
      } else {
        dispatch(getAllEmergencyFailure(response));
      }
    }
    if (error) {
      dispatch(getAllEmergencyFailure(error));
    }
  };
}

export function deleteEmergencyContact(gid) {
  return async dispatch => {
    dispatch(deleteEmergencyRequest());
    const {error, response} = await del(`${'EmergencyContact/'}${gid}`, {});
    if (response) {
      if (response) {
        dispatch(deleteEmergencySuccess(response));
      } else {
        dispatch(deleteEmergencyFailure(response));
      }
    }
    if (error) {
      dispatch(deleteEmergencyFailure(error));
    }
  };
}

export function editEmergencyCall(data) {
  return async dispatch => {
    dispatch(editEmergencyRequest());
    const {error, response} = await put(`${'EmergencyContact'}`, data);
    if (response) {
      if (response.data.result) {
        dispatch(editEmergencySuccess(response));
        return {
          success: true,
          data: response.data.result,
        };
      } else {
        dispatch(editEmergencyFailure(response));
        return {
          success: false,
          data: response.data,
        };
      }
    }
    if (error) {
      dispatch(editEmergencyFailure(error));
      return {
        success: false,
        data: error,
      };
    }
  };
}

export function addEmergencyCall(data) {
  return async dispatch => {
    dispatch(addEmergencyRequest());
    const {error, response} = await post(`${'EmergencyContact'}`, data);
    if (response) {
      // console.log('@addEmergencyCall response: ')
      // console.log(response)
      if (response.data && response.data.newContactID) {
        dispatch(addEmergencySuccess(response));
        return {
          success: true,
          data: response.data,
        };
      } else {
        dispatch(addEmergencyFailure(response));
        return {
          success: false,
          data: response.data,
        };
      }
    }
    if (error) {
      dispatch(addEmergencyFailure(error));
      return {
        success: false,
        data: error.data || error,
      };
    }
  };
}
