import { connect } from 'react-redux';
// import _get from 'lodash/get';
import Login from '../../screens/Login';

import { loginCall, saveLoginPreference } from './Login.action'

const mapStateToProps = state => ({
  communityCodes: state.appConst.comunityCodes,
  loginData: state.loginData,
  loginPref: state.loginPref,
});

export default connect(
  mapStateToProps,
  {
    loginCall,
    saveLoginPreference,
  },
)(Login);
