import { connect } from 'react-redux';
// import _get from 'lodash/get';
import AddEditGuest from '../../screens/AddEditGuest';

import { editGuestCall, addGuestCall, getSpecificGuest, getGuestQr, sendGuestQr } from './AddEditGuest.action'
import { getAllGuest } from '../ManageGuestList/ManageGuestList.action';

const mapStateToProps = state => ({
  loginData: state.loginData.loginData,
});

export default connect(
  mapStateToProps,
  {
    editGuestCall,
    addGuestCall,
    getAllGuest,
    getSpecificGuest,
    getGuestQr,
    sendGuestQr,
  },
)(AddEditGuest);
