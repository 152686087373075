import ip, {chain} from 'icepick';
import {
  GET_ALL_EMERGENCY_CONTACT_REQUEST,
  GET_ALL_EMERGENCY_CONTACT_SUCCESS,
  GET_ALL_EMERGENCY_CONTACT_FAILURE,
  DELETE_EMERGENCY_CONTACT_REQUEST,
  DELETE_EMERGENCY_CONTACT_SUCCESS,
  DELETE_EMERGENCY_CONTACT_FAILURE,
  ADD_EMERGENCY_CONTACT_REQUEST,
  ADD_EMERGENCY_CONTACT_SUCCESS,
  ADD_EMERGENCY_CONTACT_FAILURE,
  EDIT_EMERGENCY_CONTACT_REQUEST,
  EDIT_EMERGENCY_CONTACT_SUCCESS,
  EDIT_EMERGENCY_CONTACT_FAILURE,
} from './EmergencyContact.action.constant';

const initialState = ip.freeze({
  getAllEmergencyStatus: null,
  getAllEmergencyError: null,
  allEmergency: null,
  deleteEmergencyStatus: null,
  deleteEmergencyError: null,
  deleteEmergency: null,
  addEmergencyStatus: null,
  addEmergencyError: null,
  addEmergency: null,
  editEmergencyStatus: null,
  editEmergencyError: null,
  editEmergency: null,
});

export default function getAllGuest(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_EMERGENCY_CONTACT_REQUEST: {
      return chain(state)
        .setIn(['getAllEmergencyError'], null)
        .setIn(['getAllEmergencyStatus'], 'started')
        .setIn(['allEmergency'], [])
        .value();
    }

    case GET_ALL_EMERGENCY_CONTACT_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['getAllEmergencyError'], null)
        .setIn(['getAllEmergencyStatus'], 'finished')
        .setIn(['allEmergency'], data.employees)
        .value();
    }

    case GET_ALL_EMERGENCY_CONTACT_FAILURE: {
      return chain(state)
        .setIn(['getAllEmergencyError'], 'error')
        .setIn(['getAllEmergencyStatus'], 'failled')
        .setIn(['allEmergency'], [])
        .value();
    }

    case DELETE_EMERGENCY_CONTACT_REQUEST: {
      return chain(state)
        .setIn(['deleteEmergencyError'], null)
        .setIn(['deleteEmergencyStatus'], 'started')
        .setIn(['deleteEmergency'], [])
        .value();
    }

    case DELETE_EMERGENCY_CONTACT_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['deleteEmergencyError'], null)
        .setIn(['deleteEmergencyStatus'], 'finished')
        .setIn(['deleteEmergency'], data.employees)
        .value();
    }

    case DELETE_EMERGENCY_CONTACT_FAILURE: {
      return chain(state)
        .setIn(['deleteEmergencyError'], 'error')
        .setIn(['deleteEmergencyStatus'], 'failled')
        .setIn(['deleteEmergency'], [])
        .value();
    }

    case ADD_EMERGENCY_CONTACT_REQUEST: {
      return chain(state)
        .setIn(['addEmergencyError'], null)
        .setIn(['addEmergencyStatus'], 'started')
        .setIn(['addEmergency'], [])
        .value();
    }

    case ADD_EMERGENCY_CONTACT_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['addEmergencyError'], null)
        .setIn(['addEmergencyStatus'], 'finished')
        .setIn(['addEmergency'], data)
        .value();
    }

    case ADD_EMERGENCY_CONTACT_FAILURE: {
      return chain(state)
        .setIn(['addEmergencyError'], 'error')
        .setIn(['addEmergencyStatus'], 'failled')
        .setIn(['addEmergency'], [])
        .value();
    }

    case EDIT_EMERGENCY_CONTACT_REQUEST: {
      return chain(state)
        .setIn(['editEmergencyError'], null)
        .setIn(['editEmergencyStatus'], 'started')
        .setIn(['editEmergency'], [])
        .value();
    }

    case EDIT_EMERGENCY_CONTACT_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['editEmergencyError'], null)
        .setIn(['editEmergencyStatus'], 'finished')
        .setIn(['editEmergency'], data)
        .value();
    }

    case EDIT_EMERGENCY_CONTACT_FAILURE: {
      return chain(state)
        .setIn(['editEmergencyError'], 'error')
        .setIn(['editEmergencyStatus'], 'failled')
        .setIn(['editEmergency'], [])
        .value();
    }

    default:
      return state;
  }
}
