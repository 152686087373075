export const ALL_PETS = 'ALL_PETS';

export const GET_ALL_PET_REQUEST = 'GET_ALL_PET_REQUEST';
export const GET_ALL_PET_SUCCESS = 'GET_ALL_PET_SUCCESS';
export const GET_ALL_PET_FAILURE = 'GET_ALL_PET_FAILURE';

export const DELETE_PET_REQUEST = 'DELETE_PET_REQUEST';
export const DELETE_PET_SUCCESS = 'DELETE_PET_SUCCESS';
export const DELETE_PET_FAILURE = 'DELETE_PET_FAILURE';

export const ADD_PET_REQUEST = 'ADD_PET_REQUEST';
export const ADD_PET_SUCCESS = 'ADD_PET_SUCCESS';
export const ADD_PET_FAILURE = 'ADD_PET_FAILURE';

export const EDIT_PET_REQUEST = 'EDIT_PET_REQUEST';
export const EDIT_PET_SUCCESS = 'EDIT_PET_SUCCESS';
export const EDIT_PET_FAILURE = 'EDIT_PET_FAILURE';