import { connect } from 'react-redux';
// import _get from 'lodash/get';
import RecentVisitors from '../../screens/RecentVisitors';

import { getRecentVisitors } from './RecentVisitors.action';

const mapStateToProps = state => ({
  recentVisitors: state.recentVisitors.recentVisitors,
});

export default connect(
  mapStateToProps,
  {
    getRecentVisitors,
  },
)(RecentVisitors);
