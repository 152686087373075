import ip, {chain} from 'icepick';
import {
  GET_ALL_TRAVEL_REQUEST,
  GET_ALL_TRAVEL_SUCCESS,
  GET_ALL_TRAVEL_FAILURE,
  DELETE_TRAVEL_REQUEST,
  DELETE_TRAVEL_SUCCESS,
  DELETE_TRAVEL_FAILURE,
  EDIT_TRAVEL_REQUEST,
  EDIT_TRAVEL_SUCCESS,
  EDIT_TRAVEL_FAILURE,
  ADD_TRAVEL_REQUEST,
  ADD_TRAVEL_SUCCESS,
  ADD_TRAVEL_FAILURE,
} from './TravelInfo.action.constant';

const initialState = ip.freeze({
  getAllTravelStatus: null,
  getAllTravelError: null,
  allTravel: null,
  deleteTravelStatus: null,
  deleteTravelError: null,
  deleteTravel: null,

  addTravelStatus: null,
  addTravelError: null,
  addTravel: null,

  editTravelStatus: null,
  editTravelError: null,
  editTravel: null,
});

export default function getAllTravel(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_TRAVEL_REQUEST: {
      return chain(state)
        .setIn(['getAllTravelError'], null)
        .setIn(['getAllTravelStatus'], 'started')
        .setIn(['allTravel'], [])
        .value();
    }

    case GET_ALL_TRAVEL_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['getAllTravelError'], null)
        .setIn(['getAllTravelStatus'], 'finished')
        .setIn(['allTravel'], data.employees)
        .value();
    }

    case GET_ALL_TRAVEL_FAILURE: {
      return chain(state)
        .setIn(['getAllTravelError'], 'error')
        .setIn(['getAllTravelStatus'], 'failled')
        .setIn(['allTravel'], [])
        .value();
    }

    case DELETE_TRAVEL_REQUEST: {
      return chain(state)
        .setIn(['deleteTravelError'], null)
        .setIn(['deleteTravelStatus'], 'started')
        .setIn(['deleteTravel'], [])
        .value();
    }

    case DELETE_TRAVEL_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['deleteTravelError'], null)
        .setIn(['deleteTravelStatus'], 'finished')
        .setIn(['deleteTravel'], data.employees)
        .value();
    }

    case DELETE_TRAVEL_FAILURE: {
      return chain(state)
        .setIn(['deleteTravelError'], 'error')
        .setIn(['deleteTravelStatus'], 'failled')
        .setIn(['deleteTravel'], [])
        .value();
    }

    case EDIT_TRAVEL_REQUEST: {
      return chain(state)
        .setIn(['editTravelError'], null)
        .setIn(['editTravelStatus'], 'started')
        .setIn(['editTravel'], [])
        .value();
    }

    case EDIT_TRAVEL_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['editTravelError'], null)
        .setIn(['editTravelStatus'], 'finished')
        .setIn(['editTravel'], data)
        .value();
    }

    case EDIT_TRAVEL_FAILURE: {
      return chain(state)
        .setIn(['editTravelError'], 'error')
        .setIn(['editTravelStatus'], 'failled')
        .setIn(['editTravel'], [])
        .value();
    }

    case ADD_TRAVEL_REQUEST: {
      return chain(state)
        .setIn(['addTravelError'], null)
        .setIn(['addTravelStatus'], 'started')
        .setIn(['addTravel'], [])
        .value();
    }

    case ADD_TRAVEL_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['addTravelError'], null)
        .setIn(['addTravelStatus'], 'finished')
        .setIn(['addTravel'], data)
        .value();
    }

    case ADD_TRAVEL_FAILURE: {
      return chain(state)
        .setIn(['addTravelError'], 'error')
        .setIn(['addTravelStatus'], 'failled')
        .setIn(['addTravel'], [])
        .value();
    }

    default:
      return state;
  }
}
