import { connect } from 'react-redux';
// import _get from 'lodash/get';
import ManageGuest from '../../vendorscreens/ManageGuest';

import { getAllGuest, deleteGuest, addGuestCall, editGuestCall, getSpecificGuest, addBulkGuestsCall } from './ManageGuest.action';

const mapStateToProps = state => ({
  vendorLoginData: state.vendorLoginData.vendorLoginData,
  allGuest: state.manageGuest.allGuest
});

export default connect(
  mapStateToProps,
  {
    getAllGuest,
    addGuestCall,
    editGuestCall,
    deleteGuest,
    getSpecificGuest,
    addBulkGuestsCall
  },
)(ManageGuest);
