import { connect } from 'react-redux';
// import _get from 'lodash/get';
import ContactInfo from '../../screens/ContactInfo';

import { getContactInfo, editContactInfo } from './ContactInfo.action';

const mapStateToProps = state => ({
  contactInfo: state.contactInfo.contactInfo,
  globalData: state.loginData.globalData,
  loginData: state.loginData.loginData,
});

export default connect(
  mapStateToProps,
  {
    getContactInfo,
    editContactInfo,
  },
)(ContactInfo);
