export const EDIT_REQUEST = 'EDIT_REQUEST';
export const EDIT_SUCCESS = 'EDIT_SUCCESS';
export const EDIT_FAILURE = 'EDIT_FAILURE';

export const ADD_REQUEST = 'ADD_REQUEST';
export const ADD_SUCCESS = 'ADD_SUCCESS';
export const ADD_FAILURE = 'ADD_FAILURE';

export const GET_GUEST_REQUEST = 'GET_GUEST_REQUEST';
export const GET_GUEST_SUCCESS = 'GET_GUEST_SUCCESS';
export const GET_GUEST_FAILURE = 'GET_GUEST_FAILURE';

export const GET_QR_REQUEST = 'GET_QR_REQUEST';
export const GET_QR_SUCCESS = 'GET_QR_SUCCESS';
export const GET_QR_FAILURE = 'GET_QR_FAILURE';
