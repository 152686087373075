import {createAction} from 'redux-actions';
import {post, get} from '../../api/vendorApi';

import {
  VENDOR_LOGIN_REQUEST,
  VENDOR_LOGIN_SUCCESS,
  VENDOR_LOGIN_FAILURE,
  VENDOR_LOGOUT_REQUEST,
  VENDOR_LOGIN_PREFERENCE,
  GET_VENDOR_COMMUNITY_CODES_REQUEST,
  GET_VENDOR_COMMUNITY_CODES_SUCCESS,
  GET_VENDOR_COMMUNITY_CODES_FAILURE
} from './VendorLogin.action.constant';

const getVendorCommunityCodesRequest = createAction(GET_VENDOR_COMMUNITY_CODES_REQUEST);
const getVendorCommunityCodesSuccess = createAction(GET_VENDOR_COMMUNITY_CODES_SUCCESS);
const getVendorCommunityCodesFailure = createAction(GET_VENDOR_COMMUNITY_CODES_FAILURE);

const vendorLoginRequest = createAction(VENDOR_LOGIN_REQUEST);
const vendorLoginSuccess = createAction(VENDOR_LOGIN_SUCCESS);
const vendorLoginFailure = createAction(VENDOR_LOGIN_FAILURE);
const vendorLogOutRequest = createAction(VENDOR_LOGOUT_REQUEST);
const vendorLoginPreference = createAction(VENDOR_LOGIN_PREFERENCE);

export function vendorLoginCall(data) {
  return async dispatch => {
    dispatch(vendorLoginRequest());
    const {error, response} = await post(`${'Public/Login'}`, data);
    if (response) {
      if (response.data.result) {
        dispatch(vendorLoginSuccess(response));
        return {
          success: true,
          data: response.data.result
        }
      } else {
        dispatch(vendorLoginFailure(response));
        return {
          success: false,
          data: response.data
        }
      }
    }
    if (error) {
      dispatch(vendorLoginFailure(error));
      return {
        success: false,
        data: error
      }
    }
  };
}

export function vendorLogOut() {
  return async dispatch => {
    dispatch(vendorLogOutRequest());
  }
}

export function saveVendorLoginPreference(reMe, auLo, uData) {
  // console.log('@action: saveVendorLoginPreference')
  return async dispatch => {
    dispatch(vendorLoginPreference([reMe, auLo, uData]));
  }
}

export function getVendorCommunityCodes() {
    return async (dispatch) => {
      dispatch(getVendorCommunityCodesRequest());
      const { error, response } = await get(
        `${'Public/ReturnCommunityCodes'}`,
        {}
      );
      if (response) {
        // console.log('communityCode: ',response);
        if (response.data) {
          dispatch(getVendorCommunityCodesSuccess(response));
        } else {
          dispatch(getVendorCommunityCodesFailure(response));
        }
      }
      if (error) {
        dispatch(getVendorCommunityCodesFailure(error));
      }
      return { error, response };
    };
  }
