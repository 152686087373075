import React from 'react';
import {
  // Route,
  Switch,
} from "react-router-dom";
import LayoutVendorWebAdmin from './LayoutVendorWebAdmin';
// import WebAdmin from './vendorscreens/WebAdmin';

export default class Navigator extends React.Component {

    render() {
        return (
          <LayoutVendorWebAdmin>
            <Switch>
              {/* <Route path="/webadmin" component={WebAdmin} /> */}
            </Switch>
          </LayoutVendorWebAdmin>
        );
    }
}
