import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  //Link,
  Switch,
  //Redirect,
  //useLocation
} from "react-router-dom";

import SplashScreen from './redux/SplashScreen/SplashScreen.container';
import LoginScreen from './redux/Login/Login.container';
import VendorLoginScreen from './redux/VendorLogin/VendorLogin.container';
import Navigator from './Navigator';
import CPSubmitDocument from './redux/CPSubmitDocument/CPSubmitDocument.container';
import VendorCommercialPortalUserNavigator from './VendorCommercialPortalUserNavigator';
import VendorWebAdminNavigator from './VendorWebAdminNavigator';

//const browserHistory = useRouterHistory(createHistory)({basename: '/'});

export default class MainContainer extends React.Component {


    render() {
        return (
          <Router basename={'/beta'}
            //   history={browserHistory}
          >
            <Switch>
              <Route path="/vendorlogin" component={VendorLoginScreen} />
              <Route path="/webadmin" component={VendorWebAdminNavigator} />
              <Route path="/commercialportaluser" component={VendorCommercialPortalUserNavigator} />
              <Route exact path="/" component={SplashScreen} />
              <Route path="/login" component={LoginScreen} />
              <Route path="/dashboard" component={Navigator} />
              <Route path="/CPSubmitDocument" component={CPSubmitDocument} />
              <Route path="/ErrorPage" component={() => (<div><h3>404 page not found</h3></div>)} />
            </Switch>
          </Router>
        );
    }
}
