import {connect} from 'react-redux';
// import _get from 'lodash/get';
import AddEditTravel from '../../screens/AddEditTravel';

import {getAllTravel, addTravelCall, editTravelCall} from './TravelInfo.action';

const mapStateToProps = state => ({
  travelData: state.travelInfo.allTravel,
});

export default connect(
  mapStateToProps,
  {
    getAllTravel,
    editTravelCall,
    addTravelCall,
  },
)(AddEditTravel);
