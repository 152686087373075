import { createAction } from 'redux-actions';
import { getCommunityInfoApi } from '../../api/api';

import {
    GET_COMMUNITY_INFO_REQUEST,
    GET_COMMUNITY_INFO_SUCCESS,
    GET_COMMUNITY_INFO_FAILURE,
} from './CommunityInfo.action.constant'

const getCommunityInfoRequest = createAction(GET_COMMUNITY_INFO_REQUEST);
const getCommunityInfoSuccess = createAction(GET_COMMUNITY_INFO_SUCCESS);
const getCommunityInfoFailure = createAction(GET_COMMUNITY_INFO_FAILURE);

export function getCommunityInfo(headers) {
    return async (dispatch) => {
      dispatch(getCommunityInfoRequest());
      const { error, response } = await getCommunityInfoApi(
        `${'CommunityInfo'}`,
        {}, headers
      );
      if (response) {
        if (response) {
          dispatch(getCommunityInfoSuccess(response));
        } else {
          dispatch(getCommunityInfoFailure(response));
        }
      }
      if (error) {
        dispatch(getCommunityInfoFailure(error));
      }
    };
  }
