import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import reducer from '../reducer';

const persistConfig = {
  key: 'root',
  storage
};

// eslint-disable-next-line no-unused-vars
const localStorageMiddleware = ({ getState }) => next => (action) => {
  const result = next(action);
  return result;
};

const persistedReducer = persistReducer(persistConfig, reducer);

const store = ((initialState = {}) => {
  const middleware = [thunk, localStorageMiddleware];

  // eslint-disable-next-line no-underscore-dangle
  const composeEnhancers = global.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const enhancer = composeEnhancers(applyMiddleware(...middleware));
  const appStore = createStore(persistedReducer, initialState, enhancer);
  const persistor = persistStore(appStore);
  // persistor.purge();

  return { appStore, persistor };
})();

export default store;