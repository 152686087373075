import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {setAuthHeaders} from '../api/vendorApi';
import {Link} from "react-router-dom";

const styles = {
  root: {
    width: '100%',
    maxWidth: 320,
    // backgroundColor: theme.palette.background.paper,
  },
};

class MainMenuWebAdmin extends React.Component {

  componentDidMount() {
    const {vendorLoginData} = this.props;
    // console.log('vendorLoginData: '+ JSON.stringify(vendorLoginData.userSettings[0].header));
    if (vendorLoginData) {
      setAuthHeaders(vendorLoginData.userSettings[0].header);
    } else {
      localStorage.removeItem('@AbdiStore:vendorloginTime');
      this.props.history.push({pathname: '/vendorlogin', state:{logout: true}});
    }
  }

  logMeOut = async (e) => {
    // console.log('logout',e);
    const { vendorLogOut } = this.props;
    await vendorLogOut();
    // console.log('logout');
    localStorage.removeItem('@AbdiStore:vendorloginTime');
    this.props.history.push({pathname: '/vendorlogin', state:{logout: true}});
  }

  render() {
    const { classes, vendorLoginData } = this.props;
    const siteSections = (vendorLoginData != null) ? vendorLoginData.siteSections : null;

    return (
      <div className={classes.root}>
        <List component="nav">
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '5%'}}>
            {(vendorLoginData != null) && (vendorLoginData.table != null) && (
              <img
                style={{width: window.innerWidth * (13.5 / 100), resizeMode: 'contain', margin: 'auto', alignSelf: 'center'}}
                src={
                `data:image/png;base64,${vendorLoginData.table[0].binaryCommunityImage}`
                }
                alt={'webadmindash'}
              />
            )}
          </div>
          <Divider />
          {siteSections != null && siteSections.map((item, i) => {
            // console.log('item: ',item);
            return (
              <>
                <ListItem key={item.sectionName} divider={item.isLine==='true'} button={(item.sectionLabel === 'Logoff Now') ? true : false} onClick={event => { if(item.sectionLabel === 'Logoff Now') {this.logMeOut(event)}}}>
                  <ListItemIcon style={{margin:0, padding: 0}}>
                    <FontAwesomeIcon icon={['fas', `${item.sectionIconName}`]} size="lg" />
                  </ListItemIcon>
                  <ListItemText style={{margin:0, padding: 0}} primary={item.sectionLabel} />
                  {(item.children != null) && ( true ? <ExpandLess /> : <ExpandMore />)}
                </ListItem>
                {item.children != null && item.children.map((child, index) => {
                  return (
                    <Collapse in={true} timeout="auto" unmountOnExit>
                      <List>
                        <ListItem
                          key={child.sectionName}
                          // selected={'/webadmin/webadmini' === pathname}
                          style={{margin:0, paddingLeft: 30, paddingRight: 0, paddingTop: 0, paddingBottom: (child.isLine==='true') ? 10 : 0}}
                          button
                          divider={child.isLine==='true'}
                          component={Link}
                          to={"/webadmin"+child.sectionName}
                          // onClick={event => {console.log('path:',pathname); this.props.history.push({pathname: '/webadmin'+child.sectionName, state:{logout: true}})}}//<Redirect to={'webadmin' + child.sectionName} />}
                        >
                          <ListItemIcon style={{margin:0, padding: 0}}>
                            <FontAwesomeIcon icon={['fas', `${child.sectionIconName}`]} />
                          </ListItemIcon>
                          <ListItemText style={{margin:0, padding: 0}} primary={child.sectionLabel} />
                        </ListItem>
                      </List>
                    </Collapse>
                  )
                })}
              </>
            )
          })
          }
        </List>
      </div>
    );
  }
}

MainMenuWebAdmin.propTypes = {
  classes: PropTypes.object.isRequired,
};
//export default withStyles(styles)(MainMenuWebAdmin);
export default compose( withRouter, withStyles(styles))(MainMenuWebAdmin);
