import React from 'react';
import PDFViewer from 'pdf-viewer-reactjs';
import { VStack, Flex, Heading } from "native-base";

export default class ViewPdf extends React.Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }


  render() {
    const {getPdf} = this.props;
    return (
      <Flex justifyContent="center" alignItems="center">

        <VStack w="95%" mt="5" justifyContent="center" alignItems='center'>
          <Heading _dark={{
            color: "warmGray.200"
          }} color="coolGray.600" fontWeight="medium" size="md">
            {getPdf && getPdf.length && getPdf[0].fileName
              ? getPdf[0].fileName
              : 'Loading...'}
          </Heading>

          {getPdf && getPdf.length && getPdf[0].fileBinary && (
            <PDFViewer
              // source={{
              //   uri: `data:application/pdf;base64,${getPdf[0].fileBinary}`,
              // }}
              document={{
              base64: `${getPdf[0].fileBinary}`,
              }}
            />
          )}
        </VStack>
      </Flex>
    );
  }
}
