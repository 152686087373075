import { connect } from 'react-redux';
// import _get from 'lodash/get';
import ManagePets from '../../screens/ManagePets';

import { getAllPet, deletePet } from './ManagePet.action';

const mapStateToProps = state => ({
  loginData: state.loginData.loginData,
  allPets: state.managePet.allPet,
});

export default connect(
  mapStateToProps,
  {
    getAllPet,
    deletePet,
  },
)(ManagePets);
