export const ALL_EVENTS = 'ALL_EVENTS';

export const GET_ALL_EVENT_REQUEST = 'GET_ALL_EVENT_REQUEST';
export const GET_ALL_EVENT_SUCCESS = 'GET_ALL_EVENT_SUCCESS';
export const GET_ALL_EVENT_FAILURE = 'GET_ALL_EVENT_FAILURE';

export const DELETE_EVENT_REQUEST = 'DELETE_EVENT_REQUEST';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_FAILURE = 'DELETE_EVENT_FAILURE';

export const ADD_EVENT_REQUEST = 'ADD_EVENT_REQUEST';
export const ADD_EVENT_SUCCESS = 'ADD_EVENT_SUCCESS';
export const ADD_EVENT_FAILURE = 'ADD_EVENT_FAILURE';

export const EDIT_EVENT_REQUEST = 'EDIT_EVENT_REQUEST';
export const EDIT_EVENT_SUCCESS = 'EDIT_EVENT_SUCCESS';
export const EDIT_EVENT_FAILURE = 'EDIT_EVENT_FAILURE';

export const GET_EVENT_REQUEST = 'GET_EVENT_REQUEST';
export const GET_EVENT_SUCCESS = 'GET_EVENT_SUCCESS';
export const GET_EVENT_FAILURE = 'GET_EVENT_FAILURE';

export const GET_AGREEMENT_REQUEST = 'GET_AGREEMENT_REQUEST';
export const GET_AGREEMENT_SUCCESS = 'GET_AGREEMENT_SUCCESS';
export const GET_AGREEMENT_FAILURE = 'GET_AGREEMENT_FAILURE';

export const FINALIZE_AGREEMENT_REQUEST = 'FINALIZE_AGREEMENT_REQUEST';
export const FINALIZE_AGREEMENT_SUCCESS = 'FINALIZE_AGREEMENT_SUCCESS';
export const FINALIZE_AGREEMENT_FAILURE = 'FINALIZE_AGREEMENT_FAILURE';
