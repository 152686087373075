import {createAction} from 'redux-actions';
import {put, post, get} from '../../api/vendorApi';

import {
  LOAD_REQUEST,
  LOAD_SUCCESS,
  LOAD_FAILURE,
  FORM_REQUEST,
  FORM_SUCCESS,
  FORM_FAILURE,
} from './CPSubmitDocument.action.constant';

const loadRequest = createAction(LOAD_REQUEST);
const loadSuccess = createAction(LOAD_SUCCESS);
const loadFailure = createAction(LOAD_FAILURE);

const formRequest = createAction(FORM_REQUEST);
const formSuccess = createAction(FORM_SUCCESS);
const formFailure = createAction(FORM_FAILURE);


export function cpLoadCall(token, linkId) {
  return async dispatch => {
    dispatch(loadRequest());
    // const {error, response} = await get(`${'CommercialPortal/Index/Load?token='}${token}${`&linkid=`}${linkId}`, {});
    const {error, response} = await get(`${'CommercialPortal/Index/Load?token='}${token}`, {});
    if (response) {
      // console.log('res: ',response);
      if (response.data) {
        dispatch(loadSuccess(response));
        return {
          success: true,
          data: response.data,
        };
      } else {
        dispatch(loadFailure(response));
        return {
          success: true,
          data: response.data,
        };
      }
    }
    if (error) {
      dispatch(loadFailure(error));
      return {
        success: false,
        data: error,
      };
    }
  };
}

export function cpFormCall(data) {
  return async dispatch => {
    dispatch(formRequest());
    const {error, response} = await post(`${'CommercialPortal/Index/Form'}`, data);
    if (response) {
      if (response.data.result) {
        dispatch(formSuccess(response));
        return {
          success: true,
          data: response.data.result,
        };
      } else {
        dispatch(formFailure(response));
        return {
          success: true,
          data: response.data,
        };
      }
    }
    if (error) {
      dispatch(formFailure(error));
      return {
        success: false,
        data: error,
      };
    }
  };
}
