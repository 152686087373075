export const GET_CONTACT_INFOB_REQUEST = 'GET_CONTACT_INFOB_REQUEST';
export const GET_CONTACT_INFOB_SUCCESS = 'GET_CONTACT_INFOB_SUCCESS';
export const GET_CONTACT_INFOB_FAILURE = 'GET_CONTACT_INFOB_FAILURE';

export const EDIT_CONTACT_INFOB_REQUEST = 'EDIT_CONTACT_INFOBB_REQUEST';
export const EDIT_CONTACT_INFOB_SUCCESS = 'EDIT_CONTACT_INFOB_SUCCESS';
export const EDIT_CONTACT_INFOB_FAILURE = 'EDIT_CONTACT_INFOB_FAILURE';

export const GET_CONTACT_INFOB_PHONES_REQUEST = 'GET_CONTACT_INFOB_PHONES_REQUEST';
export const GET_CONTACT_INFOB_PHONES_SUCCESS = 'GET_CONTACT_INFOB_PHONES_SUCCESS';
export const GET_CONTACT_INFOB_PHONES_FAILURE = 'GET_CONTACT_INFOB_PHONES_FAILURE';
export const ADD_CONTACT_INFOB_PHONES_REQUEST = 'ADD_CONTACT_INFOB_PHONES_REQUEST';
export const ADD_CONTACT_INFOB_PHONES_SUCCESS = 'ADD_CONTACT_INFOB_PHONES_SUCCESS';
export const ADD_CONTACT_INFOB_PHONES_FAILURE = 'ADD_CONTACT_INFOB_PHONES_FAILURE';
export const PUT_CONTACT_INFOB_PHONES_REQUEST = 'PUT_CONTACT_INFOB_PHONES_REQUEST';
export const PUT_CONTACT_INFOB_PHONES_SUCCESS = 'PUT_CONTACT_INFOB_PHONES_SUCCESS';
export const PUT_CONTACT_INFOB_PHONES_FAILURE = 'PUT_CONTACT_INFOB_PHONES_FAILURE';
export const DEL_CONTACT_INFOB_PHONES_REQUEST = 'DEL_CONTACT_INFOB_PHONES_REQUEST';
export const DEL_CONTACT_INFOB_PHONES_SUCCESS = 'DEL_CONTACT_INFOB_PHONES_SUCCESS';
export const DEL_CONTACT_INFOB_PHONES_FAILURE = 'DEL_CONTACT_INFOB_PHONES_FAILURE';
