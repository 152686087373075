import ip, {chain} from 'icepick';
import {
    GET_NOTIFICATION_SETTINGB_REQUEST,
    GET_NOTIFICATION_SETTINGB_SUCCESS,
    GET_NOTIFICATION_SETTINGB_FAILURE,
    EDIT_NOTIFICATION_SETTINGB_REQUEST,
    EDIT_NOTIFICATION_SETTINGB_SUCCESS,
    EDIT_NOTIFICATION_SETTINGB_FAILURE,
  } from './NotificationSettingsB.action.constant';

const initialState = ip.freeze({
  getNotificationSettingBStatus: null,
  getNotificationSettingBError: null,
  notificationSettingB: null,
  editNotificationSettingBStatus: null,
  editNotificationSettingBError: null,
  editNotificationSettingB: null,
});

export default function getNotificationSettingB(state = initialState, action) {
  switch (action.type) {
    case GET_NOTIFICATION_SETTINGB_REQUEST: {
      return chain(state)
        .setIn(['getNotificationSettingBError'], null)
        .setIn(['getNotificationSettingBStatus'], 'started')
        .setIn(['notificationSettingB'], [])
        .value();
    }

    case GET_NOTIFICATION_SETTINGB_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['getNotificationSettingBError'], null)
        .setIn(['getNotificationSettingBStatus'], 'finished')
        .setIn(['notificationSettingB'], data)
        .value();
    }

    case GET_NOTIFICATION_SETTINGB_FAILURE: {
      return chain(state)
        .setIn(['getNotificationSettingBError'], 'error')
        .setIn(['getNotificationSettingBStatus'], 'failled')
        .setIn(['notificationSettingB'], [])
        .value();
    }

    case EDIT_NOTIFICATION_SETTINGB_REQUEST: {
      return chain(state)
        .setIn(['editNotificationSettingBError'], null)
        .setIn(['editNotificationSettingBStatus'], 'started')
        .setIn(['editNotificationSettingB'], [])
        .value();
    }

    case EDIT_NOTIFICATION_SETTINGB_SUCCESS: {
      const {data} = action.payload;
      return chain(state)
        .setIn(['editNotificationSettingBError'], null)
        .setIn(['editNotificationSettingBStatus'], 'finished')
        .setIn(['editNotificationSettingB'], data)
        .value();
    }

    case EDIT_NOTIFICATION_SETTINGB_FAILURE: {
      return chain(state)
        .setIn(['editNotificationSettingBError'], 'error')
        .setIn(['editNotificationSettingBStatus'], 'failled')
        .setIn(['editNotificationSettingB'], [])
        .value();
    }

    default:
      return state;
  }
}
